import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {UserService} from './user.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MvpService {

    constructor(private http: HttpClient,
                private user: UserService) {
    }

    getConfig(url: string): Observable<MvpConfig[]> {
        const headers = this.getDefaultHeaders(this.getToken());
        return this.http.get(`${url}/config.json`, {headers}).pipe(
            map((res: any) => {
                if (res && res.tiles) {
                    return res.tiles;
                } else {
                    return null;
                }
            })
        );
    }

    /**
     * Get default headers
     * @param accessToken
     */
    private getDefaultHeaders(accessToken: string = '') {
        const headers = new HttpHeaders({
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });

        if (typeof accessToken != 'undefined' && accessToken !== null && accessToken.length > 0) {
            const header_token = new HttpHeaders({'Authorization': 'Bearer ' + accessToken});
            return header_token;
        }

        return headers;
    }

    /** * Get current Token
     */
    private getToken(): string {
        // console.log('getToken() - token from service', this._userService.getActiveUserAccessToken());
        return this.user.getActiveUserAccessToken();
    }
}

export interface MvpConfig {
    id: string;
    group: string;
    title: string;
    description: string;
    base_url: string;
    dashboardConfiguration: {
        position: number,
        size: string,
        forceAdd: boolean
        version: number
    };
    colors: {
        primary: string
        secondary: string,
        text: string
    };
}
