import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Globals} from '../../../services/globals.service';
import {MockDataService} from '../../../services/mock-data.service';
import {ElectricityService} from '../../../services/electricity.service';
import {ApplicationService} from '../../../services/application.service';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';

@Component({
    selector: 'app-finance-tile',
    templateUrl: './finance-tile.component.html',
    styleUrls: ['./finance-tile.component.scss'],
    providers: [Globals]
})

export class FinanceTileComponent implements OnInit {
    private readonly type: TILE_TYPE = TILE_TYPE.FINANCE;

    finance: any = {
        trend: 0,
        amount: 0,
        percent: 0
    };

    dataAvailable = false;

    lottieConfig = {
        path: 'assets/anim/finance.json',
        renderer: 'svg',
        autoplay: true,
        autoplaySegments: false,
        loop: true,
        name: 'Smart Meter'
    };

    private animation = null;

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockData: MockDataService,
                private electricity: ElectricityService,
                private application: ApplicationService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService) {
    }


    ngOnInit() {
        this.getFinanceData();
    }


    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }

    animationCreated(animation: any): void {
        this.animation = animation;
    }

    private getFinanceData(): void {
        const s = this.electricity.getBillPrediction().subscribe(
            (res: any) => {
                if (res && res.sum_of_prepayments > 12) {
                    this.dataAvailable = true;
                    this.extractFinanceData(res);
                }
                this.dataAvailable = false;
                s.unsubscribe();
            },
            (error) => {
                console.log('Error:', error);
            }
        );
    }

    private extractFinanceData(data: any): void {
        const estimated_cost = data.estimated_cost_billing_period;
        const sum_prepayments = data.sum_of_prepayments;

        const difference = estimated_cost - sum_prepayments;
        const percentage = 100 * (difference) / sum_prepayments;

        this.dataAvailable = sum_prepayments > 12 && (percentage > -50 && percentage < 50);
        this.finance.percent = Math.round(percentage);

        if (percentage > 10 && difference > 10) {
            this.finance.amount = Math.ceil(difference / 10) * 10;
            this.finance.trend = 1;
        } else if (percentage < -10 && difference < -10) {
            this.finance.amount = Math.abs(Math.floor(difference / 10) * 10);
            this.finance.trend = -1;
        } else {
            this.finance.trend = 0;
            this.finance.amount = 0;
        }
        return;
    }

    private detailEntered() {
        if (!(this._globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this._globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    /*
     * ANALYTICS
     * =============================================================================================
     */
    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Finance',
            }
        });
    }

    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Finance-Details'
            }
        });
    }
}
