import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-tab-selector',
    templateUrl: './tab-selector.component.html',
    styleUrls: ['./tab-selector.component.scss']
})
export class TabSelectorComponent implements OnInit {

    @Input() options: string [] = [];
    @Input() itemPrefix = '';
    @Output() elementSelected = new EventEmitter();

    selected = null;

    constructor() {
    }

    ngOnInit() {
        if (this.options.length > 1) {
            this.selected = this.options[0];
        }
    }

    onElementClicked(el): void {
        this.selected = el;
        this.elementSelected.emit(el);
    }

}
