import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BaseComponent} from '../../classes/base-component';
import {OptInService} from '../../services/opt-in.service';
import {ImprintService} from '../../services/imprint.service';

@Component({
    selector: 'iona-app',
    templateUrl: './imprint.component.html',
    viewProviders: []
})

export class ImprintComponent extends BaseComponent implements OnInit, OnDestroy {

    @ViewChild('imprintContainer', {static: true})
    private imprintContainer: ElementRef<HTMLElement>;

    constructor(private title: Title,
                private optInService: OptInService,
                private imprintService: ImprintService) {
        super();
    }

    ngOnInit() {
        this.title.setTitle('Impressum | E.ON Smart Control');
        this.optInService.checkStatus();

        this.imprintService.getImprint().subscribe(
            (fileContents) => {
                this.imprintContainer.nativeElement.innerHTML = fileContents;
            }
        )
    }

    ngOnDestroy() {
    }
}
