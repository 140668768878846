import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AnimationItem} from 'lottie-web';
import {ToastrService} from 'ngx-toastr';
import {MfaService} from '../../services/mfa.service';
import {PopoverRef} from '../popover/popover-ref';
import {Popover} from '../popover/popover.service';
import {
    BinaryConfirmPopoverComponent
} from '../binary-confirm-popover/binary-confirm-popover.component';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-mfa-login-popover',
    templateUrl: './mfa-login-popover.component.html',
    styleUrls: ['./mfa-login-popover.component.scss']
})
export class MfaLoginPopoverComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('inputField', {static: true}) codeInputField: ElementRef<HTMLInputElement>;

    form = new FormGroup({
        code: new FormControl('', [Validators.required])
    });
    loadingAnimation = {
        path: 'assets/anim/loading-spinner.json',
        autoplay: true,
        name: 'Loading Spinner',
        loop: true,
        renderer: 'canvas'
    };
    anim: AnimationItem;

    isLoading = false;
    submitDisabled = true;

    private lastKeyCode = null;
    private shiftDown = false;
    private controlDown = false;
    private commandDown = false;
    private optionDown = false;

    private mfaCodePostSub: Subscription = null;

    constructor(public popoverRef: PopoverRef,
                private mfaService: MfaService,
                private toastr: ToastrService,
                private popover: Popover,
    ) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
        this.form.valueChanges.subscribe((changes) => {
            this.submitDisabled = changes.code.length < 1;
        });

        this.mfaCodePostSub = this.mfaService.onPostMFACode.pipe(
            tap(() => {
                this.isLoading = false;
                this.form.get('code').enable();
            }),
        ).subscribe(
            (result: { success: boolean, data: any }) => {
                if (result.success) {
                    const data = {success: true, data: result.data};
                    this.popoverRef.close(data);
                } else {
                    if (result.data.status === 401) {
                        if (result.data.error.error === 'invalid_grant') {
                            this.openCodeFailedPopover();
                        }
                    } else if (result.data.status === 400) {
                        console.log('Error:', result.data);
                    }
                }
            },
            (error) => {
                console.log('Error:', error);
                this.isLoading = false;
                this.form.get('code').enable();
            }
        );
    }

    ngOnDestroy(): void {
        if (this.mfaCodePostSub) {
            this.mfaCodePostSub.unsubscribe();
            this.mfaCodePostSub = null;
        }
    }

    ngAfterViewInit() {
        this.codeInputField.nativeElement.focus();
    }

    submitCode(): void {
        const code = this.form.value.code;
        this.isLoading = true;
        this.form.get('code').disable();
        this.mfaService.postMFATokenStandalone(code);
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    onKeyUp(event): void {
        switch (event.keyCode) {
            case 16:
                this.shiftDown = false;
                break;
            case 91:
            case 93:
                this.commandDown = false;
                break;
            case 17:
                this.controlDown = false;
                break;
            case 18:
                this.optionDown = false;
                break;
        }
    }

    onKeyDown(event): boolean {
        switch (event.keyCode) {
            case 16:
                this.shiftDown = true;
                break;
            case 91:
            case 93:
                this.commandDown = true;
                break;
            case 17:
                this.controlDown = true;
                break;
            case 18:
                this.optionDown = true;
                break;
        }

        const allowedCodes = [16, 8, 46, 37, 39, 91, 93, 13];
        const currentCode = event.keyCode;
        let inputAcceptable = false;
        if (currentCode >= 48 && currentCode <= 57) {
            inputAcceptable = true;
        }
        if (currentCode >= 96 && currentCode <= 105) {
            inputAcceptable = true;
        }
        if (this.commandDown || this.controlDown) {
            if (currentCode === 67) { // copy
                inputAcceptable = true;
            } else if (currentCode === 86) {// paste
                inputAcceptable = true;
            } else if (currentCode === 65) { // select all
                inputAcceptable = true;
            }
        }
        // allow further keys
        if (allowedCodes.find(e => e === currentCode)) {
            inputAcceptable = true;
        }
        this.lastKeyCode = currentCode;
        return inputAcceptable;
    }

    private openCodeFailedPopover(): void {
        const s = this.popover.open({
            content: BinaryConfirmPopoverComponent,
            hasBackdrop: true,
            data: {
                title: '2-Faktor Code ungültig',
                text: 'Der eingegebene Code ist ungültig. Bitte versuchen Sie es erneut.',
                positive: 'Okay'
            }
        }).afterClosed$.subscribe(
            () => null,
            () => null,
            () => s.unsubscribe()
        );
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            if (this.popoverRef.data.nullableBackdrop) {
                this.close(null);
            } else {
                this.close(false);
            }
        });
    }

}
