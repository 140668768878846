export enum AccountsTexts {
    DESCRIPTION = 'Hier können Sie alles rund um Ihren Vertrag schnell und einfach online ändern',
    ADJUST_CONTRACT_DATA = 'Vertragsdaten anpassen',
    ADJUST_PAYMENT = 'Abschlag anpassen',
    VIEW_BILL = 'Rechnung einsehen',
    ERNA_DESCRIPTION = 'Auf unserer Homepage finden Sie alle Informationen zum Messstellenbetrieb und…',
    ERNA_PROVIDER = 'zu uns als Ihren Messstellenbetreiber',
    ERNA_METER = 'zu Ihrem Stromzähler',
    ERNA_TIPS = 'zu Energiespartipps',
    ERNA_MUCHMORE = '…vieles mehr!'
}
