import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {MeterService} from '../../../services/meter.service';
import {InitializationService} from '../../../services/initialization.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {MeterReadingService} from '../../../services/meter-reading.service';
import {UserService} from '../../../services/user.service';
import {of, throwError} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {padNumber} from '../../../shared/utility';
import {MeterTileTexts} from '../../../shared/texts/tiles/meter-tile.texts';

@Component({
    selector: 'app-meter-tile',
    templateUrl: './meter-tile.component.html',
    styleUrls: ['./meter-tile.component.scss'],
    providers: [Globals]
})

export class MeterTileComponent extends BaseComponent implements OnInit {
    private readonly type: TILE_TYPE = TILE_TYPE.METER;
    readonly TEXTS = MeterTileTexts;

    @Output() tileClicked = new EventEmitter<string>();

    connection_quality = 0;

    meterStats = {
        kwh: '000000',
        id: null,
    };
    meterStatus = 'disconnected';
    meterConnected = false;

    showMeterReadingsBadge = false;

    constructor(private globals: Globals,
                private meter: MeterService,
                private initialization: InitializationService,
                private analytics: TrackAnalyticsService,
                private tiles: TileService,
                private meterReadings: MeterReadingService,
                private userService: UserService) {
        super();
    }

    ngOnInit() {
        this.getMeterInfoFromInit();
        this.initMeterStatusUpdate();
        this.initMeterValueUpdate();
        this.initializeMeterReadingFeature();
    }


    /**
     * Handles detail page open
     */
    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    /**
     * Determine the meters status message
     */
    determineMeterStatusMessage(): string {
        switch (this.meterStatus) {
            case 'connected':
                return this.TEXTS.LABEL_CONNECTED;
            default:
                return this.TEXTS.LABEL_DISCONNECTED;
        }
    }


    /**
     * Retrieve the meter info from initialization call
     */
    private getMeterInfoFromInit() {
        const s = this.initialization.get().pipe(
            mergeMap((res) => {
                try {
                    return of(res.profile);
                } catch (error) {
                    return throwError(error);
                }
            })
        ).subscribe(
            (res: any) => {
                try {
                    const status = res.meter_status_electricity;
                    if (status === 0) {
                        this.meterStatus = 'connected';
                    } else if (status === 1) {
                        this.meterStatus = 'disconnected';
                    } else {
                        this.meterStatus = 'pending';
                    }
                    this.meterConnected = res.meter_status_electricity === 0;
                    this.meterStats.id = this.formatSerialNumber(res.meter_serial_number);
                } catch (error) {
                }
            },
            (error) => null,
            () => s.unsubscribe()
        );
    }


    /**
     * Initialize continuous meter status update
     */
    private initMeterStatusUpdate(): void {
        this.meter.startLiveUpdate();
        const meterS = this.meter.onMeterStatus.subscribe(
            (res: any) => {
                try {
                    this.connection_quality = res.meter_txrssi;
                } catch (error) {
                    this.connection_quality = 0;
                }
            }
        );
        this.addSub(meterS);
    }


    /**
     * Initialize continuous current meter value update
     */
    private initMeterValueUpdate(): void {
        this.meter.getCurrentMeterValue().subscribe((res) => {
            const value = Math.floor(res / 1000);
            this.meterStats.kwh = padNumber(value, 6);
        });
    }


    /**
     * Format the meter serial number
     * @param value value to be formatted
     */
    private formatSerialNumber(value: string) {
        if (value === null || value === undefined || value.length < 1) {
            return value;
        }
        let return_str = '';
        for (let i = 0; i < value.length; i++) {
            if (i === 1 || i === 4 || i === 6 || i === 10) {
                return_str += ' ';
            }
            return_str += value.charAt(i);
        }
        return return_str;
    }


    /**
     * Request whether the user has meter reading transmission feature
     */
    private initializeMeterReadingFeature(): void {
        if (!this.userService.isMMEWMSBUser()) {
            return;
        }
        const s = this.meterReadings.getMeterReadings().subscribe(
            (res) => {
                try {
                    if (res.length > 0) {
                        this.showMeterReadingsBadge = true;
                    }
                } catch (error) {
                }
            }
        );
    }


    /*
     * ANALYTICS
     * =============================================================================================
     */
    private detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Meter'
            }
        });
    }


    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Meter-Details'
            }
        });
    }
}
