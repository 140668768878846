import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subscription, throwError, timer} from 'rxjs';
import {constants} from '../shared/constants/constants';
import {catchError, flatMap, mergeMap} from 'rxjs/operators';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';
import {ProfileService} from './profile.service';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {Popover} from '../popovers/popover/popover.service';
import * as moment from 'moment';
import {VisibilityService} from './visibility.service';
import {
    NilmDevicePopoverComponent
} from '../popovers/nilm-device-popover/nilm-device-popover.component';

@Injectable({
    providedIn: 'root'
})
export class NilmService extends BaseService {

    private readonly updateInterval = 300000;
    private readonly relevantNilmDevices = [{
        key: 'timeBasedAppliances',
        elements: ['dishWasher', 'washingMachine', 'dryer', 'oven']
    }];

    private readonly deviceIdentifierMapping = {
        washingMachine: 'A.11',
        dishWasher: 'A.10',
        dryer: 'A.12',
        oven: 'A.04'
    };

    private joinedNilmProfileSub: Subscription = null;
    private visibilitySub: Subscription = null;
    private nilmData = null;
    private lastNilmStatusUpdateTimestamp = null;

    private _tempSaveNilmDirectly = true;
    private hasOpenNilmDeviceOverlay = false;

    onNewNilmStatusUpdate = new BehaviorSubject<boolean | null>(null);


    /**
     * Check whether the passed appliances is complete according to the defined rules
     * @param applianceElement
     */
    static applianceIsComplete(applianceElement: any): boolean {
        return applianceElement.profileComplete ||
            applianceElement.profileComplete === false &&
            applianceElement.profileAdded === true;
    }


    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService,
                private profile: ProfileService,
                private userService: UserService,
                private popover: Popover,
                private visibility: VisibilityService) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.joinedNilmProfileSub) {
            this.joinedNilmProfileSub.unsubscribe();
            this.joinedNilmProfileSub = null;
        }
        if (this.visibilitySub) {
            this.visibilitySub.unsubscribe();
            this.visibilitySub = null;
        }
    }


    /**
     * Return current NILM status data
     */
    getNilmStatusData(): Observable<any> {
        if (this.nilmData) {
            return of(this.nilmData);
        }
        return this.requestCurrentNilmStatus();
    }


    /**
     * Returns whether the users current profile seems complete
     * @param categories
     */
    isProfileComplete(categories: string[]): boolean {
        const result = [];
        for (const category of categories) {
            result.push(this.nilmCategoryIsComplete(category));
        }
        return result.every(element => element === true);
    }


    /**
     * Start NILM status update for the users current profile
     * Runs in background and populates the findings to an exposed BehaviorSubject
     */
    startNilmStatusUpdateForCurrentProfile(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        if (this.joinedNilmProfileSub) {
            return;
        }
        this.joinedNilmProfileSub = timer(0, this.updateInterval).pipe(
            mergeMap(() => this.getCombinedProfileNilmResponse()),
            catchError(error => of(null))
        ).subscribe({
            next: (mergedResponse) => {
                this.handleCombinedProfileStatusResponse(mergedResponse);
            },
            error: (error) => {
                this.onNewNilmStatusUpdate.next(false);
            }
        });
        this.initializeVisibilityChanges();
    }

    private handleCombinedProfileStatusResponse(mergedResponse): void {
        if (mergedResponse === null) {
            this.onNewNilmStatusUpdate.next(false);
            return;
        }
        this.nilmData = mergedResponse.nilm;
        this.lastNilmStatusUpdateTimestamp = moment().unix();
        this.onNewNilmStatusUpdate.next(true);
        this.determineNewDevicesAdded(mergedResponse);
    }


    /**
     * Returns if the specified category is complete in the current NILM-Status response
     * @param category - category key
     */
    nilmCategoryIsComplete(category: string): boolean {
        if (!this.nilmData) {
            console.log('NILM-Service: no data!');
            return true;
        }

        category = category.toLowerCase();
        let profiles = [];
        if (category === 'refrigeration') {
            profiles = [
                this.nilmData.nonTimeBasedAppliances.refrigeration
            ];
        } else if (category === 'entertainment') {
            profiles = [
                this.nilmData.nonTimeBasedAppliances.entertainment
            ];
        } else if (category === 'cooking') {
            profiles = [
                this.nilmData.timeBasedAppliances.oven
            ];
        } else if (category === 'laundry') {
            profiles = [
                this.nilmData.timeBasedAppliances.washingMachine,
                this.nilmData.timeBasedAppliances.dryer,
                this.nilmData.timeBasedAppliances.dishWasher,
            ];
        } else {
            return true;
        }

        if (profiles.every(element =>
            element !== false && element !== undefined && element !== null)) {
            const results = [];
            for (const element of profiles) {
                results.push(NilmService.applianceIsComplete(element));
            }
            return results.every(el => el !== false && el !== undefined && el !== null);
        }

        return false;
    }


    /**
     * Filters the device model count from a NILM-data response
     * @param data - NILM data response
     * @param deviceMap - device map
     */
    private filterNilmDeviceModelCount(data: any, deviceMap: any): any {
        const result = {};
        for (const category of deviceMap) {
            const responseCategory = data[category.key];
            if (responseCategory === null || responseCategory === undefined) {
                continue;
            }
            for (const device of category.elements) {
                const value = responseCategory[device].models;
                if (value === null || value === undefined) {
                    continue;
                }
                if (!result[category.key]) {
                    result[category.key] = {};
                }
                result[category.key][device] = data[category.key][device];
            }
        }
        return result;
    }


    /**
     * Determines whether there were new device added according to the NILM service
     * @param combinedResponse - combined response of NILM-Status and profile data
     */
    private determineNewDevicesAdded(combinedResponse: { profile: any, nilm: any }): void {
        const currentProfileData = combinedResponse.profile;
        const currentNilmData = combinedResponse.nilm;

        // get stored nilm data - focus on the amounts
        const storedNilmData = this.userService.getActiveUserNilmStatus();
        if (!storedNilmData) {
            this.userService.updateActiveUserNilmStatus(combinedResponse.nilm);
            return;
        }

        // filter stored & new data for **only** the amounts
        const storedDeviceAmounts = this.filterNilmDeviceModelCount(
            storedNilmData, this.relevantNilmDevices);
        const newDeviceAmounts = this.filterNilmDeviceModelCount(
            currentNilmData, this.relevantNilmDevices);

        // filter appliance amounts from profile
        const profileAmounts = this.filterNilmDeviceAmountsFromProfile(currentProfileData);

        // console.log(newDeviceAmounts);
        for (const category of Object.keys(newDeviceAmounts)) {
            // if the old stored object does not contain the category skip ahead
            if (!storedDeviceAmounts.hasOwnProperty(category)) {
                continue;
            }

            for (const appliance of Object.keys(newDeviceAmounts[category])) {
                if (storedDeviceAmounts[category][appliance].models !== 0) {
                    continue;
                }

                const currentApplianceNewData = newDeviceAmounts[category][appliance];
                if (currentApplianceNewData.models !== 0 &&
                    !currentApplianceNewData.profileComplete) {
                    let amount = profileAmounts[appliance];
                    if (!amount) {
                        amount = 0;
                    }
                    if (!this.hasOpenNilmDeviceOverlay) {
                        this.openNilmDeviceOverlayWithConfig({amount, appliance});
                        if (this._tempSaveNilmDirectly) {
                            this.userService.updateActiveUserNilmStatusForAppliance(
                                appliance, newDeviceAmounts[category][appliance]
                            );
                        }
                    }
                }
            }

        }
    }


    /**
     * Open device configuration overlay with a defined config
     * @param config - contains the current appliance amoutn defined in the profile & the appliance
     */
    private openNilmDeviceOverlayWithConfig(config: { amount: number, appliance: string }): void {
        this.popover.open({
            content: NilmDevicePopoverComponent,
            hasBackdrop: true,
            data: config
        }).afterClosed$.subscribe((result: any) => {
            this.hasOpenNilmDeviceOverlay = false;
            if (!result.data) {
                return;
            }
            const resultData = result.data;
            this.userService.updateActiveUserNilmStatusForAppliance(
                resultData.appliance, resultData.amount
            );
            const change = {Appliance: {}};
            change.Appliance[resultData.applianceId] = resultData.amount;
            this.profile.setAttributes(change).subscribe(
                (res) => {
                }
            );
        });
        this.hasOpenNilmDeviceOverlay = true;
    }


    /**
     * Filter device amounts form user profile according to the available devices
     * @param profileData
     */
    private filterNilmDeviceAmountsFromProfile(profileData: any) {
        const profileMappedToNilm = {};
        for (const applianceTranslated of Object.keys(this.deviceIdentifierMapping)) {
            const applianceIdentifier = this.deviceIdentifierMapping[applianceTranslated];
            try {
                profileMappedToNilm[applianceTranslated] =
                    profileData.Appliances[applianceIdentifier];
            } catch (e) {
                profileMappedToNilm[applianceTranslated] = null;
            }
        }
        return profileMappedToNilm;
    }


    /**
     * get the combined nilm & household profile response
     */
    private getCombinedProfileNilmResponse():
        Observable<{ profile: Observable<any>, nilm: Observable<any> }> {
        const $profileRequest = this.profile.getAttributes();
        const $nilmStatusRequest = this.requestCurrentNilmStatus();
        return forkJoin({profile: $profileRequest, nilm: $nilmStatusRequest});
    }


    /**
     * Initialize change of visibility NILM-Status requests
     */
    private initializeVisibilityChanges(): void {
        if (!this.visibilitySub) {
            return;
        }
        this.visibilitySub = this.visibility.onVisible.pipe(
            mergeMap(() => this.getCombinedProfileNilmResponse())
        ).subscribe({
            next: (mergedResponse) => {
                this.handleCombinedProfileStatusResponse(mergedResponse);
            },
            error: (error) => {
                this.onNewNilmStatusUpdate.next(false);
            }
        });
    }


    /**
     * Get the NILM status
     */
    private requestCurrentNilmStatus(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.nilm.status;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.nilmStatus}.json`;
        }
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            flatMap((res: { status: string, data: any }) => of(this.mapDefault(res))),
            flatMap((mapped) =>
                mapped ? of(mapped) : throwError({msg: 'Error after mapping response'})
            ),
            catchError((error: any) => this.handleError(error))
        );
    }
}
