import {Injectable} from '@angular/core';
import {ApplicationService} from './application.service';
import {TrackAnalyticsService} from './track-analytics.service';
import {GoogleTagManagerService} from 'angular-google-tag-manager';

@Injectable({
    providedIn: 'root'
})
export class GTMWrapperService {
    private disabled = true;

    constructor(private application: ApplicationService,
                private trackAnalytics: TrackAnalyticsService,
                private gtm: GoogleTagManagerService) {
        this.disabled = this.application.isDemoMode() && !trackAnalytics.getTrackingState();
    }

    trackEvent(item: any, overrideTrackingEnabled = false) {
        if (!overrideTrackingEnabled) {
            if (this.disabled) {
                return;
            }
        }
        this.gtm.pushTag(item);
    }
}
