import {Component, OnInit} from '@angular/core';
import {Changelog} from './changelog.model';
import {PopoverRef} from '../popover/popover-ref';

@Component({
    selector: 'app-changelog',
    templateUrl: './changelog.component.html',
    styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit {
    changelog: Changelog = {versions: []};

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
    }

    close(): void {
        this.popoverRef.close(null);
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close();
        });
        this.changelog.versions = this.popoverRef.data;
    }

}
