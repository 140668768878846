import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

    private readonly iconBasePath = 'assets/img/icons/';

    @Input() state = false;
    @Input() icon = null;
    @Input() app = 'iona';

    @Output() stateChanged = new EventEmitter<boolean>();

    @ViewChild('toggleContainer', {static: true}) toggleContainer: ElementRef<HTMLDivElement>;
    @ViewChild('toggleKnobIcon', {static: true}) toggleKnobIcon: ElementRef<HTMLElement>;

    constructor(private renderer: Renderer2) {
    }

    ngOnInit() {
        this.setKnobIcon();
    }

    onToggle(): void {
        this.state = !this.state;
        this.stateChanged.emit(this.state);
        this.updateToggleState();
        this.setKnobIcon();
    }

    private updateToggleState(): void {
        if (this.state) {
            this.renderer.setStyle(
                this.toggleContainer.nativeElement,
                'flex-direction', 'row-reverse'
            );
        } else {
            this.renderer.setStyle(
                this.toggleContainer.nativeElement,
                'flex-direction', 'row'
            );
        }
    }

    private setKnobIcon(): void {
        let iconUrl = `url(${this.iconBasePath}${this.icon}-activated.webp)`;
        if (!this.state) {
            iconUrl = `url(${this.iconBasePath}${this.icon}-deactivated.webp)`;
        }
        this.renderer.setStyle(
            this.toggleKnobIcon.nativeElement,
            'background-image', iconUrl
        );
    }
}
