import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {Tariff} from '../../classes/user';
import * as moment from 'moment';

@Component({
    selector: 'app-tariff-data',
    templateUrl: './tariff-data-entry.component.html',
    styleUrls: ['./tariff-data-entry.component.scss']
})
export class TariffDataEntryComponent implements OnInit {

    moment = moment;

    data: Tariff = {
        name: null,
        dateStart: null,
        basePrice: null,
        workPrice: null
    };

    confirmDisabled = true;

    minDate: string = null;
    maxDate: string = null;

    private dateFormat = 'YYYY-MM-DD';

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
    }

    onFormChange(): void {
        for (const key of Object.keys(this.data)) {
            if (key === 'dateEnd') {
                continue;
            }
            const el = this.data[key];
            this.confirmDisabled = (el === null || el === undefined) || el === '';
        }
        /**
         * todo integrate validity check here
         * if the currently set dates are valid
         */
    }

    /**
     * Submit
     */
    submit(): void {
        this.data.dateStart = moment(this.data.dateStart).toDate();
        this.close({form: this.data});
    }

    /**
     * OnClose
     * @param data
     * @private
     */
    close(data: any): void {
        this.popoverRef.close(data);
    }

    /**
     * Initialize passed data for the component
     * @private
     */
    private initializePopoverData(): void {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(null);
        });
        const data = this.popoverRef.data;
        if (data.tariffs.length > 0) {
            const start = data.tariffs[data.tariffs.length - 1].dateStart;
            this.minDate = moment(start).add(1, 'day').format(this.dateFormat);
        } else {
            this.minDate = '2018-01-01';
        }
        this.maxDate = moment().format(this.dateFormat);
    }
}
