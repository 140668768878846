import {Injectable, OnDestroy} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {iif, interval, Observable, of, Subscription, throwError} from 'rxjs';
import {InitializationService} from './initialization.service';
import {catchError, delay, mergeMap, retry, take,} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MeterReadingService extends BaseService implements OnDestroy {

    private readonly METER_READINGS_BASE_URL = environment.urls.wmsb;
    private meterReadingSub: Subscription = null;

    private _cachedMeterReadings: Array<any> = null;

    private callRunning = false;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private initialization: InitializationService) {
        super(http, auth, user);
    }

    ngOnDestroy() {
        if (this.meterReadingSub) {
            this.meterReadingSub.unsubscribe();
            this.meterReadingSub = null;
        }
    }

    getMeterReadings(): Observable<any> {
        if (this._cachedMeterReadings) {
            return of(this._cachedMeterReadings);
        }
        if (this.callRunning) {
            return of(null);
        }
        this.callRunning = true;
        return interval(1000).pipe(
            take(10),
            delay(1000),
            mergeMap(() => of(this.initialization.meterId)),
            mergeMap(serial => iif(
                () => !serial,
                throwError(null),
                this.requestMeterReadings(serial))
            ),
            retry(30),
            catchError((error) => error({error}))
        );
    }

    sendNewMeterValue(value: number): Observable<any> {
        const url = `${this.METER_READINGS_BASE_URL}/meter-readings/reading`;
        const headers = this.getDefaultHeaders(this.auth.getToken());
        const body = {
            UtilitiesDeviceID: this.initialization.meterId,
            ID: null,
            MeterReadingTypeCode: 'Z3',
            MeterReadingResultValue: value
        };
        try {
            body.ID = this._cachedMeterReadings.last().ID;
        } catch (e) {
        }
        return this.http.post(url, body, {headers});
    }

    private requestMeterReadings(serial): Observable<any> {
        let url = this.METER_READINGS_BASE_URL;
        url += `/meter-readings/request?UtilitiesDeviceID=${serial}`;
        const headers = this.getDefaultHeaders(this.auth.getToken());
        return this.http.get(url, {headers}).pipe(
            mergeMap((res: { message: string, result: any }) => {
                try {
                    this._cachedMeterReadings = res.result;
                    return of(res.result);
                } catch (error) {
                    return throwError({msg: 'response invalid', error});
                }
            }),
            catchError(() => of(null))
        );
    }
}
