import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {ApplicationService} from './application.service';

@Injectable({
    providedIn: 'root'
})
export class InitializationService extends BaseService {

    private _meterId: string = null;
    private updateRate = 10000;

    private timerSub: Subscription = null;

    public get meterId(): string {
        return this._meterId;
    }

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private router: Router,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
    }

    get(useFakeData = false): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.initialization;
        if (useFakeData) {
            url = this.ACCOUNT_REWRITE_BASE_URL + constants.api.routes.initialization;
        }
        if (this.application.isDemoMode()) {
            url = 'assets/data/demo/' + constants.demo.files.initialization + '.json';
        }
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            tap(response => console.log(response)),
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            map((res) => {
                this._meterId = 'profile' in res ? res.profile.meter_serial_number : null;
                return res;
            }),
            catchError((error_response) => {
                if ('error' in error_response) {
                    if ('error' in error_response.error) {
                        if ('code' in error_response.error.error) {
                            if (error_response.error.error.code === 125) {
                                this.router.navigate(['/registrieren'], {queryParams: {jumpToOnboarding: true}});
                            }
                        }
                    }
                }

                if ('_body' in error_response) {
                    let jsonError;

                    try {
                        jsonError = JSON.parse(error_response._body);
                    } catch (e) {
                        return throwError(e);
                    }

                    if ('error' in jsonError) {
                        if ('code' in jsonError.error && jsonError.error.code === 125) {
                            // user not yet onboarded -> Zum Onboarding springen
                            this.router.navigate(['/registrieren'], {queryParams: {jumpToOnboarding: true}});
                        }
                    }
                }

                return throwError(error_response);
            })
        );
    }

    getMeterConnectionInfo(): Observable<string> {
        return this.get().pipe(
            mergeMap((response: any) => {
                if (!('profile' in response)) {
                    return throwError(null);
                }
                if (!('meter_status_electricity' in response.profile)) {
                    return throwError(null);
                }
                return of(response.profile.meter_status_electricity);
            }),
            map((value) => {
                if (value === 0) {
                    return 'connected';
                } else if (value === 1) {
                    return 'disconnected';
                } else {
                    return 'pending';
                }
            })
        );
    }
}
