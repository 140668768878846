import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {GTMWrapperService} from '../../services/gtmwrapper.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-change-email',
    templateUrl: './change-email.component.html',
    styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit, AfterViewInit {

    submitDisabled = true;

    formGroup = new FormGroup({
        email: new FormControl(null, [Validators.required]),
        email2: new FormControl(null, [Validators.required])
    });

    constructor(public popoverRef: PopoverRef,
                private gtm: GTMWrapperService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
        this.formGroup.valueChanges.subscribe((values) => {
            this.checkEmailsMatching();
        });
    }

    ngAfterViewInit() {
        this.trackViewAppearedEvent();
    }


    close(value = false): void {
        this.popoverRef.close(value);
    }


    submitChange(): void {
        const value = this.formGroup.value.email;
        this.popoverRef.close({success: true, value});
    }


    private checkEmailsMatching(): void {
        const first = this.formGroup.value.email;
        const second = this.formGroup.value.email2;
        if (first === '' || second === '') {
            this.submitDisabled = true;
            return;
        }
        if (first === second) {
            this.submitDisabled = false;
            return;
        }
        this.submitDisabled = true;
        return;
    }


    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(false);
        });
    }


    /// ============================================================================================
    /// GTM STUFF
    /// ============================================================================================
    private trackViewAppearedEvent(): void {
        this.gtm.trackEvent({
            event: 'start',
            eventCategory: 'interaction',
            eventAction: 'start',
            journeyId: 'contact data',
            toolId: 'contact data',
            elementId: 'contact data',
            stepId: 'edit email address'
        });
    }

}
