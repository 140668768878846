import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
    selector: '[appMatchWidth]'
})
export class MatchWidthDirective implements OnInit, AfterViewInit {

    @Input() appMatchWidth = 1.0;

    private height: number;

    constructor(private elementRef: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngAfterViewInit(): void {
        this.initialize();
    }

    private initialize(): void {
        this.height = this.elementRef.nativeElement.offsetHeight;
        this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${this.height * this.appMatchWidth}px`);
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.initialize();
    }


}
