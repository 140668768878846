import {Component, OnInit, Renderer2} from '@angular/core';
import {ComponentPortal, Portal} from '@angular/cdk/portal';
import {PhaseLiveComponent} from './phase-live/phase-live.component';
import {PhaseHistoryComponent} from './phase-history/phase-history.component';
import {BaseComponent} from '../../../classes/base-component';
import {ApiService} from '../../../services/api.service';
import {Globals} from '../../../services/globals.service';
import {MeterService} from '../../../services/meter.service';
import {InstantaneousService} from '../../../services/instantaneous.service';
import {ApplicationService} from '../../../services/application.service';
import {MockDataService} from '../../../services/mock-data.service';
import * as moment from 'moment';
import {UserService} from '../../../services/user.service';
import {PhaseCheckerService} from '../../../services/phase-checker.service';

@Component({
    selector: 'app-phase-checker-detail',
    templateUrl: './phase-checker-detail.component.html',
    styleUrls: ['./phase-checker-detail.component.scss'],
    providers: [Globals]
})
export class PhaseCheckerDetailComponent extends BaseComponent implements OnInit {

    connectionAlert = false;
    dataAvailable = false;
    dataError = false;

    phaseWarning = false;
    phaseOverPercentage = false;
    phaseOverThreshold = false;
    warningCollapsed = true;

    powerThreshold = 7000;

    liveMode = false;

    selectedPortal: Portal<any>;
    livePortal: ComponentPortal<PhaseLiveComponent>;
    historyPortal: ComponentPortal<PhaseHistoryComponent>;

    currentDataset = null;

    private currentComponentRef = null;

    constructor(private apiService: ApiService,
                private globals: Globals,
                private meterService: MeterService,
                private instantaneousService: InstantaneousService,
                private application: ApplicationService,
                private mockData: MockDataService,
                private userService: UserService,
                private phaseCheckerService: PhaseCheckerService) {
        super();
    }

    ngOnInit() {
        if (!this.application.isDemoMode()) {
            const thresh = this.userService.getPhaseThreshold();
            if (thresh) {
                this.powerThreshold = thresh;
            }
        }

        this.initPortals();

        const s = this.phaseCheckerService.thresholdChange.subscribe(
            (res) => {
                this.phaseWarning = false;
                this.phaseOverThreshold = false;
                this.phaseOverPercentage = false;
                this.powerThreshold = res;
                this.calculatePhaseStates(this.currentDataset);
            }
        );

        this.addSub(s);
        if (this.application.isDemoMode()) {
            return this.initMockContents();
        }
        this.initTileContent();


    }

    private initTileContent(): void {
        const meterSub = this.meterService.onMeterStatus.subscribe(
            (res) => {
                if ('lora_mode' in res) {
                    this.connectionAlert = !(res.lora_mode === 0 || res.lora_mode === 7 || res.lora_mode === 8);
                }
            }
        );
        this.addSub(meterSub);

        const now = moment();
        const end = moment(now).toDate();
        const start = moment(now).subtract(30, 'days').toDate();

        const iSub = this.instantaneousService.getPhase(start, end).subscribe(
            (res) => {
                if (res) {
                    this.currentDataset = res;
                    this.calculatePhaseStates(res);
                    this.dataAvailable = true;
                    this.updatePortals(this.currentComponentRef.instance);
                } else {
                    this.dataError = true;
                    this.dataAvailable = true;
                }

            },
            (error) => {
                this.dataError = true;
            }
        );
        this.addSub(iSub);
    }

    private initMockContents(): void {
        const mSub = this.mockData.getMeterStatus().subscribe(
            (res_big) => {
                const res = res_big.data;
                this.connectionAlert = !(res.lora_mode === 0 || res.lora_mode === 7 || res.lora_mode === 8);
            }
        );
        this.addSub(mSub);

        const iSub = this.mockData.getPhases().subscribe(
            (res) => {
                if (res) {
                    if ('L1' in res && 'L2' in res && 'L3' in res) {
                        this.currentDataset = res;
                        this.calculatePhaseStates(res);
                        this.dataAvailable = true;
                        this.updatePortals(this.currentComponentRef.instance);
                    } else {
                        this.dataError = true;
                        this.dataAvailable = true;
                    }
                } else {
                    this.dataError = true;
                    this.dataAvailable = true;
                }
            }
        );
        this.addSub(iSub);
    }

    switchMode(mode: number): void {
        if (mode === 0) {
            this.liveMode = true;
            this.selectedPortal = this.livePortal;
        } else if (mode === 1) {
            this.liveMode = false;
            this.selectedPortal = this.historyPortal;
        }
    }

    onComponentAttached(ref): void {
        this.currentComponentRef = ref;
        this.updatePortals(ref.instance);

        if (this.application.isDemoMode()) {
            return this.initMockContents();
        }
        this.initTileContent();
    }

    updatePortals(instance: any): void {
        if (instance instanceof PhaseLiveComponent) {
            const i = instance as PhaseLiveComponent;
            i.initTileContent(this.currentDataset);
        }

        if (instance instanceof PhaseHistoryComponent) {
            const i = instance as PhaseHistoryComponent;
        }
    }

    toggleWarning(): void {
        this.warningCollapsed = !this.warningCollapsed;
    }

    private initPortals(): void {
        this.livePortal = new ComponentPortal(PhaseLiveComponent);
        this.historyPortal = new ComponentPortal(PhaseHistoryComponent);
        this.switchMode(0);
    }

    private calculatePhaseStates(res): void {
        const phase1 = res.L1;
        const phase2 = res.L2;
        const phase3 = res.L3;

        const phases = [phase1, phase2, phase3];
        const above_threshold = phases.filter((el) => el.power_max > this.powerThreshold);
        if (above_threshold.length > 0) {
            this.phaseWarning = true;
            this.phaseOverThreshold = true;
        }
        const max_sum = phase1.power_max + phase2.power_max + phase3.power_max;

        const mapped = phases.map((element) => {
            return Math.round(element.power_max / max_sum * 100);
        }).sort((a, b) => a > b ? -1 : 1);
        if (mapped[0] >= 70) {
            this.phaseWarning = true;
            this.phaseOverPercentage = true;
        }
    }

}
