import 'hammerjs';
import './array-extension';

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {applyPolyfills, defineCustomElements} from '@eon-ui/eon-ui-components/loader';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

applyPolyfills().then(() => {
    defineCustomElements(window); // this registers the E.ON UI components in the browser
});
