import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {LottieAnimationViewModule} from 'ng-lottie';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {AppRoutingModule, routedComponents} from './app-routing.module';

import {ChartModule} from 'angular-highcharts';

import {TextMaskModule} from 'angular2-text-mask';
import {ToastrModule} from 'ngx-toastr';

import {Angulartics2Module} from 'angulartics2';
import {Angulartics2GoogleAnalytics} from 'angulartics2/ga';

import {LiveTileComponent} from './tiles/live/live-tile/live-tile.component';
import {LiveDetailsComponent} from './tiles/live/live-details/live-details.component';
import {
    AppliancesTileComponent
} from './tiles/appliances/appliances-tile/appliances-tile.component';
import {
    AppliancesDetailsComponent
} from './tiles/appliances/appliances-detail/appliances-detail.component';
import {TodayTileComponent} from './tiles/today/today-tile/today-tile.component';
import {TodayDetailsComponent} from './tiles/today/today-details/today-details.component';
import {CompareTileComponent} from './tiles/compare/comparison-tile/comparison-tile.component';
import {
    ComparisonDetailsComponent
} from './tiles/compare/comparison-details/comparison-details.component';
import {MeterTileComponent} from './tiles/meter/meter-tile/meter-tile.component';
import {MeterDetailsComponent} from './tiles/meter/meter-details/meter-details.component';
import {FinanceTileComponent} from './tiles/finance/finance-tile/finance-tile.component';
import {FinanceDetailsComponent} from './tiles/finance/finance-details/finance-details.component';
import {
    ConsumptionAlertTileComponent
} from './tiles/consumption-alert/consumption-alert-tile/consumption-alert-tile.component';
import {
    ConsumptionAlertDetailsComponent
} from './tiles/consumption-alert/consumption-alert-details/consumption-alert-detail.component';
import {ApiService} from './services/api.service';
import {MockDataService} from './services/mock-data.service';

import {InlineSVGModule} from 'ng-inline-svg';
import {HttpClientModule} from '@angular/common/http';
import {
    ConnectionIndicatorComponent
} from './components/connection-indicator/connection-indicator.component';
import {HeightControlDirective} from './directives/height-control.directive';
import {OverlayComponent} from './overlay/overlay.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {OverlayService} from './overlay/overlay.service';
import {UserService} from './services/user.service';
import {
    PowerCheckerTileComponent
} from './tiles/powerchecker/powerchecker-tile/powerchecker-tile.component';
import {
    PowerCheckerDetailsComponent
} from './tiles/powerchecker/powerchecker-details/powerchecker-details.component';
import {PowerSwitchComponent} from './tiles/powerchecker/power-switch/power-switch.component';
import {MovableDirective} from './directives/movable.directive';
import {NguCarouselModule} from '@ngu/carousel';
import {NgxHmCarouselModule} from 'ngx-hm-carousel';
import {PowercheckerStateService} from './services/powerchecker-state.service';
import {
    RemainingTimeIndicatorComponent
} from './components/remaining-time-indicator/remaining-time-indicator.component';
import player from 'lottie-web';
import {MvpTileComponent} from './tiles/mvp/mvp-tile/mvp-tile.component';
import {MvpDetailsComponent} from './tiles/mvp/mvp-details/mvp-details.component';
import {
    PhaseCheckerTileComponent
} from './tiles/phase-checker/phase-checker-tile/phase-checker-tile.component';
import {
    PhaseCheckerDetailComponent
} from './tiles/phase-checker/phase-checker-detail/phase-checker-detail.component';
import {
    PhaseHistoryComponent
} from './tiles/phase-checker/phase-checker-detail/phase-history/phase-history.component';
import {
    PhaseLiveComponent
} from './tiles/phase-checker/phase-checker-detail/phase-live/phase-live.component';
import {PortalModule} from '@angular/cdk/portal';
import {PhaseLiveChartComponent} from './charts/phase-live-chart/phase-live-chart.component';
import {TariffDataEntryComponent} from './popovers/tariff-data-entry/tariff-data-entry.component';
import {PopoverComponent} from './popovers/popover/popover.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {TariffHistoryComponent} from './popovers/tariff-history/tariff-history.component';
import {ChangelogComponent} from './popovers/changelog/changelog.component';
import {LiveChartComponent} from './charts/live-chart/live-chart.component';
import {MenuComponent} from './components/menu/menu.component';
import {EonSquareButtonComponent} from './components/eon-square-button/eon-square-button.component';
import {AddTileComponent} from './popovers/add-tile/add-tile.component';
import {SortTilesComponent} from './popovers/sort-tiles/sort-tiles.component';
import {
    BinaryConfirmPopoverComponent
} from './popovers/binary-confirm-popover/binary-confirm-popover.component';
import {MatchHeightDirective} from './directives/match-height.directive';
import {MatchWidthDirective} from './directives/match-width.directive';
import {HalfCircleDirective} from './directives/half-circle.directive';
import {
    ConsumptionAlertChartComponent
} from './charts/consumption-alert-chart/consumption-alert-chart.component';
import {MatchParentHeightDirective} from './directives/match-parent-height.directive';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {ChangeEmailComponent} from './popovers/change-email/change-email.component';
import {ResetPasswordComponent} from './popovers/reset-password/reset-password.component';
import {CountSelectorComponent} from './components/count-selector/count-selector.component';
import {TabSelectorComponent} from './components/tab-selector/tab-selector.component';
import {InfoPopoverComponent} from './popovers/info-popover/info-popover.component';
import {
    EnergySaverControlComponent
} from './popovers/energy-saver-control/energy-saver-control.component';
import {EonSliderComponent} from './components/eon-slider/eon-slider.component';
import {
    EnergySaverBannerComponent
} from './components/energy-saver-banner/energy-saver-banner.component';
import {BannerComponent} from './components/banner/banner.component';
import {
    ValueEntryPopoverComponent
} from './popovers/value-entry-component/value-entry-popover.component';
import {UpdatePopoverComponent} from './popovers/update-popover/update-popover.component';
import {SortablejsModule} from 'ngx-sortablejs';
import {OptInPopoverComponent} from './popovers/opt-in-popover/opt-in-popover.component';
import {BatteryLoadComponent} from './components/battery-load/battery-load.component';
import {
    EonUiButtonCustomComponent
} from './components/eon-ui-button-custom/eon-ui-button-custom.component';
import {
    EonUiIconButtonCustomComponent
} from './components/eon-ui-icon-button-custom/eon-ui-icon-button-custom.component';
import {MfaCodePopoverComponent} from './popovers/mfa-code-popover/mfa-code-popover.component';
import {MfaLoginPopoverComponent} from './popovers/mfa-login-popover/mfa-login-popover.component';
import {
    MfaCodeEntryPopoverComponent
} from './popovers/mfa-code-entry-popover/mfa-code-entry-popover.component';
import {
    DatePickerButtonComponent
} from './components/date-picker-button/date-picker-button.component';
import {HelpComponent} from './help/help.component';
import {ToggleComponent} from './components/toggle/toggle.component';
import {ApplianceChartComponent} from './charts/appliance-chart/appliance-chart.component';
import {
    NilmDevicePopoverComponent
} from './popovers/nilm-device-popover/nilm-device-popover.component';

declare var require: any;

/**
 * Globale Highchart-Options
 */
const Highcharts = require('highcharts');
Highcharts.setOptions({
    global: {
        timezoneOffset: new Date().getTimezoneOffset()
    },
    lang: {
        thousandsSep: '.',
        decimalPoint: ',',
        loading: 'Auswertung wird geladen...',
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        shortWeekdays: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam']
    }
});

export function playerFactory() {
    return player;
}

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ChartModule,
        TextMaskModule,
        SortablejsModule.forRoot({
            animation: 150
        }),
        ToastrModule.forRoot({
            timeOut: 3000,
            maxOpened: 2,
            positionClass: 'toast-bottom-right'
        }),
        Angulartics2Module.forRoot([Angulartics2GoogleAnalytics], {
            ga: {
                anonymizeIp: true
            }
        }),
        LottieAnimationViewModule.forRoot(),
        HttpClientModule,
        InlineSVGModule.forRoot({baseUrl: 'assets/img/icons/'}),
        FlexLayoutModule,
        NguCarouselModule,
        NgxHmCarouselModule,
        // LottieModule.forRoot({player: playerFactory}),
        PortalModule,
        OverlayModule,
        AngularSvgIconModule.forRoot(),
        ReactiveFormsModule
    ],
    declarations: [
        AppComponent,
        routedComponents,
        LiveTileComponent,
        LiveDetailsComponent,
        AppliancesTileComponent,
        AppliancesDetailsComponent,
        TodayTileComponent,
        TodayDetailsComponent,
        CompareTileComponent,
        ComparisonDetailsComponent,
        MeterTileComponent,
        MeterDetailsComponent,
        FinanceTileComponent,
        FinanceDetailsComponent,
        ConsumptionAlertTileComponent,
        ConsumptionAlertDetailsComponent,
        ConnectionIndicatorComponent,
        HeightControlDirective,
        OverlayComponent,
        PowerCheckerTileComponent,
        PowerCheckerDetailsComponent,
        PowerSwitchComponent,
        MovableDirective,
        RemainingTimeIndicatorComponent,
        MvpTileComponent,
        MvpDetailsComponent,
        PhaseCheckerTileComponent,
        PhaseCheckerDetailComponent,
        PhaseHistoryComponent,
        PhaseLiveComponent,
        PhaseLiveChartComponent,
        TariffDataEntryComponent,
        PopoverComponent,
        TariffHistoryComponent,
        ChangelogComponent,
        LiveChartComponent,
        MenuComponent,
        EonSquareButtonComponent,
        AddTileComponent,
        SortTilesComponent,
        BinaryConfirmPopoverComponent,
        MatchHeightDirective,
        MatchWidthDirective,
        HalfCircleDirective,
        ConsumptionAlertChartComponent,
        MatchParentHeightDirective,
        ChangeEmailComponent,
        ResetPasswordComponent,
        CountSelectorComponent,
        TabSelectorComponent,
        InfoPopoverComponent,
        EnergySaverControlComponent,
        EonSliderComponent,
        EnergySaverBannerComponent,
        BannerComponent,
        ValueEntryPopoverComponent,
        UpdatePopoverComponent,
        OptInPopoverComponent,
        BatteryLoadComponent,
        EonUiButtonCustomComponent,
        EonUiIconButtonCustomComponent,
        MfaCodePopoverComponent,
        MfaCodeEntryPopoverComponent,
        MfaLoginPopoverComponent,
        DatePickerButtonComponent,
        HelpComponent,
        NilmDevicePopoverComponent,
        ToggleComponent,
        ApplianceChartComponent
    ],
    providers: [
        ApiService,
        MockDataService,
        OverlayService,
        UserService,
        PowercheckerStateService,
        {
            provide: 'googleTagManagerId', useValue: 'GTM-NDBX2F4'
        }
    ],
    entryComponents: [
        PhaseLiveComponent,
        PhaseHistoryComponent,
        PopoverComponent,
        TariffDataEntryComponent,
        TariffHistoryComponent,
        ChangelogComponent,
        AddTileComponent,
        SortTilesComponent,
        BinaryConfirmPopoverComponent,
        LiveDetailsComponent,
        MeterDetailsComponent,
        FinanceDetailsComponent,
        ConsumptionAlertDetailsComponent,
        AppliancesDetailsComponent,
        TodayDetailsComponent,
        ComparisonDetailsComponent,
        ChangeEmailComponent,
        ResetPasswordComponent,
        PowerCheckerDetailsComponent,
        EnergySaverControlComponent,
        MvpDetailsComponent,
        ValueEntryPopoverComponent,
        UpdatePopoverComponent,
        OptInPopoverComponent,
        MfaCodePopoverComponent,
        MfaCodeEntryPopoverComponent,
        MfaLoginPopoverComponent,
        NilmDevicePopoverComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
