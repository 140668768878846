import {Component, OnInit} from '@angular/core';
import {InstantaneousService} from '../../../../services/instantaneous.service';
import {ApplicationService} from '../../../../services/application.service';
import {BaseComponent} from '../../../../classes/base-component';
import {MeterService} from '../../../../services/meter.service';
import {UserService} from '../../../../services/user.service';
import {PhaseCheckerService} from '../../../../services/phase-checker.service';

@Component({
    selector: 'app-phase-live',
    templateUrl: './phase-live.component.html',
    styleUrls: ['./phase-live.component.scss']
})
export class PhaseLiveComponent extends BaseComponent implements OnInit {

    dataAvailable = false;
    dataError = false;

    currentDataset = null;

    phaseThresholdOptions = [];
    phaseThreshold = 7000;
    phaseWarning = false;

    state: 'warning' | 'allgood' | 'nodata' = 'nodata';

    editMode = false;

    constructor(private instantaneousService: InstantaneousService,
                private meterService: MeterService,
                private application: ApplicationService,
                private userService: UserService,
                private phaseCheckerService: PhaseCheckerService) {
        super();

        for (let i = 1; i < 6; ++i) {
            this.phaseThresholdOptions.push(i * 2000 - 1000);
        }
    }

    ngOnInit() {
        if (this.application.isDemoMode()) {
            return;
        }
        this.initPhaseThreshold();
    }

    processThresholdChanges(): void {
        if (this.editMode) {
            if (!this.application.isDemoMode()) {
                this.userService.setPhaseThreshold(this.phaseThreshold);
            }
            this.phaseCheckerService.thresholdChange.next(this.phaseThreshold);
        }
        this.editMode = !this.editMode;
    }

    initTileContent(data: any[]): void {
        if (data) {
            if ('L1' in data && 'L2' in data && 'L3' in data) {
                this.currentDataset = data;
                this.dataAvailable = true;
                this.state = 'allgood';
                this.determinePhaseWarning(data);
            } else {
                this.dataError = true;
                this.dataAvailable = true;
                this.state = 'nodata';
            }
        }
    }

    private determinePhaseWarning(res: any): any {
        const phase1 = res.L1;
        const phase2 = res.L2;
        const phase3 = res.L3;

        const phases = [phase1, phase2, phase3];
        const max_sum = phase1.power_max + phase2.power_max + phase3.power_max;
        const percentages = phases.map((value, index) =>
            Math.round(value.power_max / max_sum * 100)
        ).sort((a, b) => a > b ? -1 : 1);
        if (percentages[0] > 70) {
            this.state = 'warning';
            this.phaseWarning = true;
        }
    }

    private initPhaseThreshold(): void {
        if (!this.application.isDemoMode()) {
            const thresh = this.userService.getPhaseThreshold();
            if (thresh) {
                this.phaseThreshold = thresh;
            }
        }
    }
}
