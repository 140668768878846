import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {constants} from '../../shared/constants/constants';
import {UserService} from '../../services/user.service';
import {MeterService} from '../../services/meter.service';
import {ApplicationService} from '../../services/application.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {RegistrationService} from '../../services/registration.service';
import {InitializationService} from '../../services/initialization.service';
import {map, mergeMap} from 'rxjs/operators';
import {OpticalReaderService} from '../../services/optical-reader.service';
import {SmartBridgeService} from '../../services/smart-bridge.service';
import {Popover} from '../../popovers/popover/popover.service';
import {
    EnergySaverControlComponent
} from '../../popovers/energy-saver-control/energy-saver-control.component';
import {ActivatedRoute} from '@angular/router';
import {TrackAnalyticsService} from '../../services/track-analytics.service';
import {PinEntryPopoverConfig} from '../../popovers/static.popover.config';
import {iif, Observable, of, throwError} from 'rxjs';
import {OptInService} from '../../services/opt-in.service';
import {StorageAttributes} from '../../shared/constants/storage-attributes.constants';

@Component({
    selector: 'iona-app',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent extends BaseComponent implements OnInit, OnDestroy {

    config: any = {
        tracking: true,
        optIn: false
    };

    // connection qualities
    wifiConnectionQuality = 0;
    wifiConnected = 0;
    lanConnected = false;

    meterConnectionQuality = 0;
    meter_status = 'disconnected';
    pincode = null;

    deviceIsPlug = false;

    isEDGUser = false;
    batteryStates = [1, 2, 3, 4, 5];
    currentBatteryState = 0;
    currentEnergySaverThreshold = 1;
    pinEntryUnknownOrOptical = false;


    private initalChange = false;

    @ViewChild('trackingCheckbox', {static: true}) trackingCheckbox: ElementRef;
    private trackingCheckboxObserver: MutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === 'attributes') {
                if (!this.initalChange) {
                    this.initalChange = true;
                } else {
                    const checked = mutation.target['checked'];
                    this.setTracking(checked);
                }
            }
        });
    });

    @ViewChild('optInCheckbox', {static: true}) optInCheckboxElement: ElementRef;
    private optInCheckboxObserver: MutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === 'attributes') {
                if (!this.initalChange) {
                    this.initalChange = true;
                } else {
                    const checked = mutation.target['checked'];
                    this.setOptIn(checked);
                }
            }
        });
    });

    constructor(public application: ApplicationService,
                private pageTitle: Title,
                private notifificationService: ToastrService,
                private userService: UserService,
                private meterService: MeterService,
                private versionService: VersionService,
                private registrationService: RegistrationService,
                private initializationService: InitializationService,
                private analyticsService: TrackAnalyticsService,
                private opticalReader: OpticalReaderService,
                private smartBridge: SmartBridgeService,
                private popover: Popover,
                private route: ActivatedRoute,
                private optInService: OptInService) {
        super();
    }

    ngOnInit() {
        this.initComponent();
    }

    ngOnDestroy() {
    }


    setTracking(value) {
        this.config.tracking = value;
    }

    setOptIn(value) {
        this.config.optIn = value;
    }

    /**
     * Saves tracking and opt in settings made
     */
    saveSettings() {
        this.analyticsService.changeTrackingState(this.config.tracking);
        this.registrationService.optInDevice(this.config.optIn).subscribe(
            res => null
        );
        this.registrationService.getOptInStatus().subscribe(res => {
            if (!res) {
                localStorage.setItem(StorageAttributes.OPT_IN_REQUIRED, '0');
                this.optInService.checkStatus();
            }
        });
        this.notifificationService.success('Ihre Einstellungen wurden erfolgreich gespeichert!');
    }

    determineMeterStatusMessage(): string {
        switch (this.meter_status) {
            case 'connected':
                return 'verbunden';
            default:
                return 'nicht verbunden';
        }
    }

    onEnergySaverOpen(): void {
        this.popover.open({
            content: EnergySaverControlComponent,
            data: {threshold: this.currentEnergySaverThreshold},
            hasBackdrop: true,
            placement: 'center center'
        }).afterClosed$.subscribe((res) => {
        });
    }

    onPinEntryOpen(): void {
        const config = PinEntryPopoverConfig;
        delete config.data.hasSkip;
        if (this.pincode) {
            config.data.text +=
                ` Ihre aktuelle Zähler-Pin lautet: ${this.pincode}.`;
        }
        this.popover.open(config).afterClosed$.pipe(
            mergeMap((value: any) => iif(
                () => value.data,
                this.meterService.putOpticalReaderPin(value.data),
                of(false)
            ))
        ).subscribe(res => {
        });
    }

    /**
     * Base component initialization
     */
    private initComponent(): void {
        this.pageTitle.setTitle('Einstellungen | E.ON Smart Control');
        this.setupOptInHandling();

        this.deviceIsPlug = this.userService.getUserDevice() === constants.application.devices.plug;

        this.isEDGUser = this.userService.isEDGUser();

        this.addSub(this.initializationService.getMeterConnectionInfo()
            .subscribe(status => this.meter_status = status));

        this.setupMeterStatusHandling();

        if (this.isEDGUser) {
            this.getOpticalReaderBatteryStatus();
        }

        this.listenForRouteChanges();

        // eon ui stuff
        this.trackingCheckboxObserver.observe(
            this.trackingCheckbox.nativeElement,
            {attributeFilter: ['checked']});
        this.config.tracking = this.analyticsService.getTrackingState();

        this.optInCheckboxObserver.observe(
            this.optInCheckboxElement.nativeElement,
            {attributeFilter: ['checked']});
    }


    private setupMeterStatusHandling(): void {
        // extract wifi connection quality
        this.meterService.startLiveUpdate();
        this.meterService.getStatus().pipe(
            mergeMap(response => this.extractMeterWifiConnection(response))
        ).subscribe(
            result => this.handleMeterWifiValue(result),
            error => null,
            () => null
        );
        this.addSub(this.meterService.onMeterStatus.pipe(
            mergeMap(response => this.extractMeterWifiConnection(response))
        ).subscribe(
            result => this.handleMeterWifiValue(result),
            error => null,
            () => null
        ));

        if (!this.isEDGUser) {
            return;
        }

        this.addSub(this.registrationService.getOnline().pipe(
            mergeMap((res) => {
                if (!this.isEDGUser) {
                    return this.meterService.getStatus().pipe(
                        mergeMap(meterResponse =>
                            of({meterReaderResponse: null, meterResponse}))
                    );
                }
                return this.opticalReader.getOpticalReaderStatus(true);
            }),
            map((results: any) => {
                this.meterConnectionQuality =
                    ('meter_txrssi' in results) ? results.meter_txrssi : 0;
                try {
                    this.pinEntryUnknownOrOptical =
                        results.electricity.smartreader.pin_entry_mode === 'optical' ||
                        results.electricity.smartreader.pin_entry_mode === 'unknown';
                } catch (e) {
                    return null;
                }
                return null;
            })).subscribe((res) => null));
    }

    /**
     * Fetch optical reader battery status
     */
    private getOpticalReaderBatteryStatus(): void {
        this.opticalReader.getOpticalReaderStatus().subscribe((res) => {
            this.currentBatteryState = res.battery_status;
            this.meterConnectionQuality = res.connection_quality;
            this.pincode = res.pincode;
            this.pincode = 1234;
        });
    }

    /**
     * Listen for changes on the current component route
     *  if the field 'open' container 'energy-saver' directly open the energy saver configuration overlay
     */
    private listenForRouteChanges(): void {
        this.route.params.subscribe((params) => {
            if (!('open' in params)) {
                return;
            }
            if (params.open === 'energy-saver') {
                this.onEnergySaverOpen();
            }
        });
    }

    private setupOptInHandling(): void {
        this.optInService.checkStatus();

        const s = this.optInService.onDialogSettingsChange.subscribe(result =>
            this.config.optIn = result
        );
        this.addSub(s);

        this.registrationService.getOptInStatus().subscribe(
            res => this.config.optIn = res);
    }

    private extractMeterWifiConnection(response): Observable<number> {
        try {
            return of(response['wlan_rssi']);
        } catch (e) {
            return throwError(e);
        }
    }

    private handleMeterWifiValue(value: number): void {
        this.wifiConnectionQuality = value;
        this.wifiConnected = this.wifiConnectionQuality;
        if (this.wifiConnectionQuality === 0) {
            this.lanConnected = true;
        }
    }
}
