import {Component, OnInit} from '@angular/core';
import {Tariff} from '../../classes/user';
import {PopoverRef} from '../popover/popover-ref';
import * as moment from 'moment';

@Component({
    selector: 'app-tariff-history',
    templateUrl: './tariff-history.component.html',
    styleUrls: ['./tariff-history.component.scss']
})
export class TariffHistoryComponent implements OnInit {

    moment = moment;

    provider = '';
    tariffs: Tariff[] = [];

    dateDisplayFormat = 'DD.MM.YYYY';

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.setupBackdrop();
            const data = this.popoverRef.data;
            this.tariffs = data.tariffs;
            this.provider = data.provider;
        }
    }

    ngOnInit() {
    }

    determineStartDate(tariff): string {
        return moment(tariff.dateStart).format(this.dateDisplayFormat);
    }

    determineEndDate(tarif: Tariff, i: number): string {
        const formerTariff = this.tariffs[i + 1];
        if (formerTariff) {
            return moment(formerTariff.dateStart).format(this.dateDisplayFormat);
        }
        return moment().format(this.dateDisplayFormat);
    }

    close(data: any): void {
        this.popoverRef.close(data);
    }

    private setupBackdrop(): void {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(null);
        });
    }
}
