import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {constants} from '../shared/constants/constants';
import {catchError, mergeMap} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {of, throwError} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SmartBridgeService extends BaseService {

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService
    ) {
        super(http, auth, user);
    }


    getBatterySaverThreshold(): Observable<any> {
        return this.http.get(
            this.API_BASE_URL + constants.api.routes.smartbridge.reportMode.settings,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            mergeMap((res: any) => {
                if ('load_curve_threshold' in res.data) {
                    const thresholdRequested = res.data.load_curve_threshold_requested;
                    const threshold = res.data.load_curve_threshold;
                    if (thresholdRequested === null && threshold != null) {
                        return of(threshold);
                    } else if (thresholdRequested === null && threshold === null) {
                        return of(50);
                    } else {
                        return of(thresholdRequested);
                    }
                } else {
                    return throwError(null);
                }

            }),
            catchError((error) => {
                console.log('Error:', error);
                return of(error);
            })
        );
    }

    setBatterySaverThreshold(value): Observable<any> {
        return this.http.put(
            this.API_BASE_URL + constants.api.routes.smartbridge.reportMode.settings,
            {
                lc_threshold: value
            },
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            catchError((error) => {
                console.log('Error:', error);
                return of(null);
            })
        );
    }

}
