import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {NilmOverlayConfig, NilmOverlayResult} from './overlay.component';

@Injectable({
    providedIn: 'root'
})
export class OverlayService {

    public onShow: Subject<boolean> = new Subject();
    public onInit: Subject<NilmOverlayConfig> = new Subject();
    public onConfirm: Subject<any> = new Subject<NilmOverlayResult>();
    public closeOverlaySub: Subject<any> = new Subject<any>();

    constructor() {
    }

    public initialize(config: NilmOverlayConfig): void {
        this.onInit.next(config);
    }

    public showOverlay(show: boolean): void {
        this.onShow.next(show);
    }

    public closeOverlay(): void {
        this.closeOverlaySub.next();
    }

}
