import {OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

export class BaseComponent implements OnDestroy {

    protected subscriptions: Subscription[] = [];

    constructor() {
    }

    ngOnDestroy(): void {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    addSub(s: Subscription): void {
        this.subscriptions.push(s);
    }
}
