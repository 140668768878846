import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApplicationService} from '../../services/application.service';
import {BaseComponent} from '../../classes/base-component';
import {OptInService} from '../../services/opt-in.service';

@Component({
    selector: 'iona-app',
    templateUrl: './account.component.html',
    viewProviders: []
})

export class AccountComponent extends BaseComponent implements OnInit, OnDestroy {

    constructor(public application: ApplicationService,
                private optInService: OptInService) {
        super();
    }

    ngOnInit() {
        this.optInService.checkStatus();
    }

    ngOnDestroy() {
    }

    openLink() {
        window.open('https://www.eon.de/de/meineon/start.html');
    }

}
