import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {UserService} from './user.service';
import {MvpConfig} from './mvp.service';
import {Popover} from '../popovers/popover/popover.service';
import {map} from 'rxjs/operators';
import {RemoveTilePopoverConfig} from '../popovers/static.popover.config';
import {LiveDetailsComponent} from '../tiles/live/live-details/live-details.component';
import {MeterDetailsComponent} from '../tiles/meter/meter-details/meter-details.component';
import {FinanceDetailsComponent} from '../tiles/finance/finance-details/finance-details.component';
import {
    ConsumptionAlertDetailsComponent
} from '../tiles/consumption-alert/consumption-alert-details/consumption-alert-detail.component';
import {
    AppliancesDetailsComponent
} from '../tiles/appliances/appliances-detail/appliances-detail.component';
import {TodayDetailsComponent} from '../tiles/today/today-details/today-details.component';
import {
    ComparisonDetailsComponent
} from '../tiles/compare/comparison-details/comparison-details.component';
import {
    PowerCheckerDetailsComponent
} from '../tiles/powerchecker/powerchecker-details/powerchecker-details.component';
import {MvpDetailsComponent} from '../tiles/mvp/mvp-details/mvp-details.component';

@Injectable({
    providedIn: 'root'
})
export class TileService implements OnDestroy {
    currentTiles: TileDef[] = [];

    selectionChanged = new Subject<TileDef[]>();

    private _powerCheckerTilePosition = 8;

    constructor(private _userService: UserService,
                private popover: Popover) {
        this.init();
    }

    ngOnDestroy() {
        this.clearTiles();
    }

    updateTileList(newList: TileDef[]) {
        this.currentTiles = newList;
        this._userService.setActiveUserTiles(this.currentTiles);
        this.selectionChanged.next(this.currentTiles);
    }

    openDetailView(type: TILE_TYPE, data = null): void {
        let component = null;
        switch (type) {
            case TILE_TYPE.LIVE:
                component = LiveDetailsComponent;
                break;
            case TILE_TYPE.METER:
                component = MeterDetailsComponent;
                break;
            case TILE_TYPE.FINANCE:
                component = FinanceDetailsComponent;
                break;
            case TILE_TYPE.CONSUMPTION_ALERT:
                component = ConsumptionAlertDetailsComponent;
                break;
            case TILE_TYPE.APPLIANCES:
                component = AppliancesDetailsComponent;
                break;
            case TILE_TYPE.TODAY:
                component = TodayDetailsComponent;
                break;
            case TILE_TYPE.COMPARISON:
                component = ComparisonDetailsComponent;
                break;
            case TILE_TYPE.POWER_CHECKER:
                component = PowerCheckerDetailsComponent;
                break;
            case TILE_TYPE.MVP:
                component = MvpDetailsComponent;
                break;
        }

        if (!component) {
            console.info('no valid component type');
            return;
        }
        const config = {
            content: component,
            data,
            hasBackdrop: true,
        };
        const s = this.popover.open(config).afterClosed$.subscribe(() => s.unsubscribe());
    }

    toggleTile(tile: TileDef) {
        if (tile.selected) {
            this.currentTiles.forEach((it: TileDef) => {
                if (it.type === tile.type) {
                    it.selected = !it.selected;
                }
            });
            return;
        }

        const old_idx = this.currentTiles.findIndex((it) => it.type === tile.type);
        this.currentTiles.splice(old_idx, 1);

        tile.selected = true;
        this.currentTiles.push(tile);
        this.updateTileList(this.currentTiles);
    }

    toggleMVPTile(id: string): void {
        const idx = this.currentTiles.findIndex(it => it.id === id);
        if (idx === -1) {
            return;
        }
        this.currentTiles[idx].selected = !this.currentTiles[idx].selected;
        const tile = this.currentTiles[idx];
        this.currentTiles.splice(idx, 1);
        this.currentTiles.push(tile);
        this.updateTileList(this.currentTiles);
    }

    tileAvailable(type: TILE_TYPE) {
        const found = this.currentTiles.find((el) => el.type === type);
        return !!found;
    }

    tilesAvailable(): boolean {
        for (const tile of this.currentTiles) {
            if (tile.selected === false) {
                return true;
            }
        }
        return false;
    }


    setSelected(value: boolean, tile: TILE_TYPE, fromTile = false) {
        if (fromTile && !value) {
            const s = this.popover.open(RemoveTilePopoverConfig).afterClosed$.pipe(
                map(res => res.data)
            ).subscribe((res) => {
                if (res) {
                    this.setTileSelectedState(value, tile);
                }
                s.unsubscribe();
            });
            return;
        }
        this.setTileSelectedState(value, tile);
    }

    enableTileType(tileType: TILE_TYPE): void {
        if (tileType === TILE_TYPE.POWER_CHECKER) {
            this.currentTiles.splice(this._powerCheckerTilePosition, 0, PowerCheckerTile);
        }
    }

    disableTileType(tileType: TILE_TYPE): void {
        const idx = this.currentTiles.findIndex((it) => it.type === tileType);
        if (idx > -1) {
            this.currentTiles.splice(idx, 1);
            this.updateTileList(this.currentTiles);
            return;
        }
        // console.log(`Tile with type ${tileType} already disabled`);
    }

    mvpTileIsCurrentlyActive(id: string): boolean {
        try {
            return this.currentTiles.find(el => el.id === id).selected;
        } catch (error) {
            return false;
        }
    }

    addMVPTile(config: MvpConfig): void {
        const tile = this.createNewMVPTile(config);
        const currentTileIdx = this.currentTiles.findIndex(el => el.id === config.id);
        if (currentTileIdx >= 0) {
            this.currentTiles.splice(currentTileIdx, 1, tile);
        } else {
            this.currentTiles.splice(config.dashboardConfiguration.position, 0, tile);
        }
        this.updateTileList(this.currentTiles);
    }

    removeMVPTile(config: MvpConfig): boolean {
        const idx = this.currentTiles.findIndex(el => el.id === config.id);
        if (idx >= 0) {
            this.currentTiles.splice(idx, 1);
            this.updateTileList(this.currentTiles);
            return true;
        }
        return false;
    }

    disableMVPTile(id: string): void {
        const tileIdx = this.currentTiles.findIndex(t => {
            if (t.type === TILE_TYPE.MVP) {
                return t.id === id;
            }
        });
        if (tileIdx >= 0) {
            this.currentTiles[tileIdx].selected = false;
        }
        this.updateTileList(this.currentTiles);
    }

    updateMVPTileConfig(config: MvpConfig): void {
        const idx = this.currentTiles.findIndex(el => el.id === config.id);
        if (idx >= 1) {
            this.currentTiles[idx].mvpConfig = config;
        } else {
            return;
        }

        this.updateTileList(this.currentTiles);
    }

    updateMVPTilePosition(config: MvpConfig): void {
        const idx = this.currentTiles.findIndex(el => el.id === config.id);
        if (idx >= 0) {
            const tile = this.currentTiles[idx];
            this.currentTiles.splice(idx, 1);
            this.currentTiles.splice(config.dashboardConfiguration.position, 0, tile);
        }
        this.updateTileList(this.currentTiles);
    }


    getCurrentTiles(): TileDef[] {
        return this.currentTiles;
    }

    clearTiles(): void {
        this.currentTiles = [];
        this.updateTileList(this.currentTiles);
    }

    init(): void {
        const tiles = this._userService.getActiveUserTiles();
        if (tiles) {
            this.currentTiles = tiles;
        } else {
            this.currentTiles = defaultTilesTyped;
            this._userService.setActiveUserTiles(this.currentTiles);
            this._userService.setConsumptionAlertAttribute(false);
        }
        this.selectionChanged.next(this.currentTiles);
    }

    removeLegacyMVPTiles(): void {
        let i = 0;
        for (const tile of this.currentTiles) {
            if (tile.type === TILE_TYPE.MVP) {
                if (!tile.mvpConfig) {
                    this.currentTiles.splice(i, 1);
                }
            }
            ++i;
        }
        this.updateTileList(this.currentTiles);
    }

    private setTileSelectedState(value: boolean, tile: TILE_TYPE): void {
        this.currentTiles.forEach((it) => {
            if (it.type === tile) {
                it.selected = value;
            }
        });
        this.updateTileList(this.currentTiles);
    }

    private createNewMVPTile(config: MvpConfig): TileDef {
        const thumbnail = `${config.base_url}/${config.id}/res/tiles/l.png`;
        return {
            type: TILE_TYPE.MVP,
            id: config.id,
            title: config.title,
            info: config.description,
            thumbnail,
            selected: config.dashboardConfiguration.forceAdd,
            addIndex: this.currentTiles.length - 1,
            mvpConfig: config,
            detailColor: config.colors.primary
        };
    }
}

export enum TILE_TYPE {
    LIVE = 'LIVE',
    TODAY = 'TODAY',
    APPLIANCES = 'APPLIANCES',
    COMPARISON = 'COMPARISON',
    METER = 'METER',
    FINANCE = 'FINANCE',
    CONSUMPTION_ALERT = 'CONSUMPTION_ALERT',
    PHASE_CHECKER = 'PHASE_CHECKER',
    POWER_CHECKER = 'POWER_CHECKER',
    MVP = 'MVP',
}

export interface TileDef {
    type: TILE_TYPE;
    id?: string;
    title: string;
    info: string;
    thumbnail: string;
    selected: boolean;
    addIndex: number;
    detailColor?: string;
    mvpConfig?: MvpConfig;
}

export const defaultTilesTyped: TileDef[] = [
    {
        type: TILE_TYPE.LIVE,
        title: 'Live',
        info: 'Anzeige Ihres Stromverbrauchs in Echtzeit',
        thumbnail: '/assets/img/thumbs/live.webp',
        selected: true,
        addIndex: 0,
    },
    {
        type: TILE_TYPE.TODAY,
        title: 'Heute',
        info: 'Ihr heutiger Stromverbrauch und die entstandenen Kosten im Überblick',
        thumbnail: '/assets/img/thumbs/today.webp',
        selected: true,
        addIndex: 1,
    },
    {
        type: TILE_TYPE.APPLIANCES,
        title: 'Geräte',
        info: 'Anzeige des Verbrauchs einzelner Geräte in Ihrem Haushalt und der dabei entstandenen Kosten',
        thumbnail: '/assets/img/thumbs/appliances.webp',
        selected: true,
        addIndex: 2,
    },
    {
        type: TILE_TYPE.COMPARISON,
        title: 'Vergleich',
        info: 'Verbrauchsstatistik und Analysen',
        thumbnail: '/assets/img/thumbs/comparison.webp',
        selected: true,
        addIndex: 3,
    },
    {
        type: TILE_TYPE.METER,
        title: 'Zählerstand',
        info: 'Anzeige Ihres Zählerstands',
        thumbnail: '/assets/img/thumbs/meter.webp',
        selected: true,
        addIndex: 4,
    },
    {
        type: TILE_TYPE.FINANCE,
        title: 'Abschlags-Check',
        info: 'Gutschrift- oder Nachzahlungsprognose',
        thumbnail: '/assets/img/thumbs/finance.webp',
        selected: true,
        addIndex: 5,
    },
    {
        type: TILE_TYPE.CONSUMPTION_ALERT,
        title: 'Verbrauchs-Alarm',
        info: 'Alarmfunktion bei hohem Stromverbrauch',
        thumbnail: '/assets/img/thumbs/consumption-alert.webp',
        selected: true,
        addIndex: 6,
    },
    // {
    //     type: TILE_TYPE.PHASE_CHECKER,
    //     title: 'PhasenChecker',
    //     info: 'Auslastung der Phasen im Blick',
    //     thumbnail: '',
    //     selected: true,
    //     addIndex: 7,
    // },
];

const PowerCheckerTile: TileDef = {
    type: TILE_TYPE.POWER_CHECKER,
    title: 'Stecker',
    info: 'Schalten Ihres Smart Control Steckers',
    thumbnail: '/assets/img/thumbs/powerchecker.webp',
    selected: true,
    addIndex: 7,
};

const MVPTile: TileDef = {
    type: TILE_TYPE.MVP,
    title: 'Power Checker',
    info: 'Schalten Ihres E.ON Smart Control Steckers',
    thumbnail: '',
    selected: false,
    addIndex: 8,
};
