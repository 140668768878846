export const static_links = {
    settings: {
        'e.on': 'https://www.eon.de/de/meineon/start.html',
    },
    privacy: {
        'e.on': 'https://www.eon.de/content/dam/eon/eon-de-zwei/documents/Privatkunden/smart-control/eon-smart-control-datenschutzinformation.pdf',
        innogy: 'https://www.eon.de/content/dam/eon/eon-de-zwei/documents/Privatkunden/smart-control/eon-smart-control-datenschutzinformation.pdf',
    },
    finance: {
        'e.on': 'https://www.eon.de/de/meineon/start.html',
    }
};
