import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-battery-load',
    templateUrl: './battery-load.component.html',
    styleUrls: ['./battery-load.component.scss']
})
export class BatteryLoadComponent implements OnInit, OnChanges {

    @Input() currentBatteryState = 0;
    @Input() inSettings = true;
    @Input() isAvailable = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    getBatteryStateIconUrl(state: number): string {
        if (!this.isAvailable) {
            state = 1;
        }
        return `url(assets/img/graphics/or-battery-state/state-${!state ? 0 : state}.svg)`;
    }

    determineTextColor(): string {
        if (this.inSettings) {
            return '#1ea2b1';
        } else {
            return this.currentBatteryState === 1 ? '#b00402' : '#1ea2b1';
        }
    }
}
