import {static_links} from '../shared/constants/web-resources.constants';
import {imprintBaseUrl, imprintUrls} from '../shared/constants/imprint.constants';

export const getProviderIcon = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }
    switch (provider.toLowerCase()) {
        default:
            return 'logo-eon';
    }
};

export const getProviderIconClass = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }
    switch (provider.toLowerCase()) {
        default:
            return 'eon-logo';
    }
};

export const getProviderSettingsLink = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }

    switch (provider.toLowerCase()) {
        case 'e.on':
            return static_links.settings['e.on'];
        default:
            return static_links.settings['e.on'];
    }
};

export const getProviderMenuName = (provider: string): string => {
    if (provider === null || provider === undefined) {
        return null;
    }

    switch (provider.toLowerCase()) {
        default:
            return 'Mein E.ON';
    }
};


export const getProviderImprintUrl = (provider: string): string => {
    switch (provider) {
        case 'enviam':
            return imprintBaseUrl + imprintUrls.enviam;
        case 'eprimo':
            return imprintBaseUrl + imprintUrls.eprimo;
        case 'energis':
            return imprintBaseUrl + imprintUrls.energis;
        case 'lew':
            return imprintBaseUrl + imprintUrls.lew;
        case 'innogy':
        case 'mme@wmsb':
        case 'e.on-legacy':
        case 'e.on legacy':
        case 'e.on-da':
        case 'e.on da':
        case 'opto':
        case 'e.on':
            return imprintBaseUrl + imprintUrls.esc;
        case 'avacon':
            return imprintBaseUrl + imprintUrls.avacon;
        case 'schleswig-holstein netz':
            return imprintBaseUrl + imprintUrls.sh;
        case 'bayernwerk netz':
            return imprintBaseUrl + imprintUrls.bh;
        case 'edis':
        case 'e.dis':
            return imprintBaseUrl + imprintUrls.edis;
        default:
            return imprintBaseUrl + imprintUrls.esc;
    }
};
