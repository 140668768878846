import {Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';
import {TILE_TYPE, TileDef, TileService} from '../../services/tile.service';

@Component({
    selector: 'app-add-tile',
    templateUrl: './add-tile.component.html',
    styleUrls: ['./add-tile.component.scss']
})
export class AddTileComponent implements OnInit {

    currentTiles: TileDef[] = [];

    constructor(public popoverRef: PopoverRef,
                private tilesService: TileService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
        this.initTileList();
    }

    ngOnInit() {
    }

    close(): void {
        this.popoverRef.close(null);
    }

    onTileToggle(tile: TileDef) {
        if (!tile.selected) {
            if (tile.type === TILE_TYPE.MVP) {
                this.tilesService.toggleMVPTile(tile.mvpConfig.id);
                return;
            }
            this.tilesService.toggleTile(tile);
            this.initTileList();
        }
    }

    private initTileList(): void {
        const tiles = this.tilesService.getCurrentTiles().slice();
        this.currentTiles = tiles.sort((a, b) => {
            if (a.addIndex < b.addIndex) {
                return -1;
            }
            if (a.addIndex > b.addIndex) {
                return 1;
            }
            return 0;
        });
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close();
        });
    }

}


