export enum MeterStatuses {
    INITIAL= 'INITIAL',
    READY_FOR_METER_INCLUSION = 'READY_FOR_METER_INCLUSION',
    CONTACT_WITH_METER = 'CONTACT_WITH_METER',
    CONNECTED_WITH_METER = 'CONNECTED_WITH_METER',
    COMMISSION_IN_PROGRESS = 'COMMISSION_IN_PROGRESS',
    VERIFYING_PIN = 'VERIFYING_PIN',
    PIN_FAILED = 'PIN_FAILED',
    CONTACT_NO_DATA_FROM_METER = 'CONTACT_NO_DATA_FROM_METER',
    CONTACT_WRONG_METER_SERIAL = 'CONTACT_WRONG_METER_SERIAL',
    UPDATE_INSTALLING = 'UPDATE_INSTALLING',
    RADIO_LINK_LOST = 'RADIO_LINK_LOST',
    // fake
    MANUAL_PIN_ENTRY_REQUIRED = 'MANUAL_PIN_ENTRY_REQUIRED'
}
