import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MockDataService} from '../../../services/mock-data.service';
import {MeterService} from '../../../services/meter.service';
import {InitializationService} from '../../../services/initialization.service';
import {ElectricityService} from '../../../services/electricity.service';
import {MONTHS} from '../../../lib/DateUtil';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import * as moment from 'moment';
import {MeterReadingService} from '../../../services/meter-reading.service';
import {UserService} from '../../../services/user.service';
import {Popover} from '../../../popovers/popover/popover.service';
import {
    EnterMeterValuePopover,
    MeterValueValidityCheckPopover
} from '../../../popovers/static.popover.config';
import {mergeMap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ACTION} from '../../../lib/Action';
import {padNumber} from '../../../shared/utility';

@Component({
    selector: 'app-meter-details',
    templateUrl: './meter-details.component.html',
    styleUrls: ['./meter-details.component.scss'],
})

export class MeterDetailsComponent extends BasePopover implements OnInit, OnDestroy {

    months = MONTHS;

    meterStats = {kwh: '000000', id: null};

    connection_quality = 0;
    meter_connected = false;
    meter_status = 'disconnected';

    foundConsumption = 0;
    selectedConsumption = '------';
    specificDate = false;
    today = moment().format('DD.MM.YYYY');
    searchDate = new Date();

    infoVisible = false;
    showMeterValueReportInfo = false;

    private dateFormat = 'DD.MM.YYYY';
    private dateFormatDebug = 'DD.MM.YYYY hh:mm:ss';

    private passedAction: ACTION = null;

    private initialChangeDone = false;
    private dateChangeObserver: MutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === 'attributes') {
                if (this.initialChangeDone) {
                    const selected_date = mutation.target['value'];
                    this.setDate(selected_date);
                    this.selectedConsumption = '------';
                    this.specificDate = true;
                } else {
                    this.initialChangeDone = true;
                }
            }
        });
    });

    @ViewChild('dateInput', {static: true}) dateInput: ElementRef;

    constructor(private _mockData: MockDataService,
                private meter: MeterService,
                private initialization: InitializationService,
                private electricity: ElectricityService,
                private application: ApplicationService,
                protected popoverRef: PopoverRef,
                public userService: UserService,
                private meterReadings: MeterReadingService,
                private popover: Popover,
                private notification: ToastrService) {
        super(popoverRef);
        this.passedAction = this.popoverRef.data;
    }

    ngOnInit() {
        this.dateChangeObserver.observe(this.dateInput.nativeElement, {attributeFilter: ['value']});

        this.initializeMeterStatusUpdate();
        this.getCurrentMeterValue();
        this.getMeterinfo();

        this.searchMeter();
        this.initializeMeterReadingFeature();
    }

    ngOnDestroy() {
    }

    /**
     * Meter-Nummer holen
     */
    getMeterinfo() {
        // this._apiService.getInitialization()
        const s = this.initialization.get().subscribe(
            (res: any) => {
                try {
                    const status = res.profile.meter_status_electricity;
                    if (status === 0) {
                        this.meter_status = 'connected';
                    } else if (status === 1) {
                        this.meter_status = 'disconnected';
                    } else {
                        this.meter_status = 'pending';
                    }
                    this.meter_connected = res.profile.meter_status_electricity === 0;
                    const meterId = res.profile.meter_serial_number;
                    this.meterStats.id = this.formatSerialNumber(meterId);
                } catch (error) {
                }
                s.unsubscribe();
            }
        );
    }

    onMeterValueEntry(): void {
        const cfg = EnterMeterValuePopover;
        cfg.data['value'] = this.meterStats.kwh;
        const s = this.triggerMeterValueEnterCycle(cfg).pipe(
            mergeMap((value) => this.meterReadings.sendNewMeterValue(value)),
        ).subscribe(
            (result) => {
                this.notification.info(
                    'Der Zählerstand wurde übernommen',
                    'Vielen Dank');
                this.initializeMeterReadingFeature();
            },
            error => {
                if (!error) {
                    return;
                }
                this.notification.error(
                    'Bitte versuchen sie es später erneut',
                    'Server nicht erreichbar');
            },
            () => s.unsubscribe()
        );
    }


    setDate(date: string) {
        this.searchDate = new Date(parseInt(date, 10));
        if (this.application.isDemoMode()) {
            this.searchMockMeter();
            return;
        }
        this.searchMeter();
    }

    searchMeter() {
        if (!this.searchDate) {
            return;
        }
        const date = moment(this.searchDate).format('YYYY-MM-DDT23:59:59');
        const s = this.electricity.getConsumptionForDate(date).pipe(
            mergeMap((res: any) => {
                try {
                    return of(res.current_summation);
                } catch (error) {
                    return throwError({msg: 'response invalid', error});
                }
            })).subscribe(
            (consumption: any) => {
                if (!this.specificDate) {
                    this.foundConsumption = Math.round(consumption / 1000);
                } else {
                    this.selectedConsumption = Math.round(consumption / 1000).toString();
                }
            },
            error => null,
            () => s.unsubscribe()
        );
    }

    loop(start: number, times: number) {
        const loop: number[] = [];
        for (let i = start; i < (start + times); i++) {
            loop.push(i);
        }
        return loop;
    }

    determineMeterStatusMessage(): string {
        switch (this.meter_status) {
            case 'connected':
                return 'verbunden';
            default:
                return 'nicht verbunden';
        }
    }


    private initializeMeterReadingFeature(): void {
        if (!this.userService.isMMEWMSBUser()) {
            return;
        }
        const s = this.meterReadings.getMeterReadings().pipe().subscribe(
            (res) => {
                if (!res) {
                    return;
                }
                this.showMeterValueReportInfo = res.length > 0;
                s.unsubscribe();
            },
            (error) => s.unsubscribe(),
            () => s.unsubscribe()
        );
    }

    private searchMockMeter(): void {
        this.foundConsumption = 0;
        if (!this.searchDate) {
            return;
        }
        const date = this.searchDate;
        const s = this._mockData.getConsumptionForDate(date).subscribe(
            (data: any) => {
                try {
                    this.foundConsumption = Math.round(data.data[0].current_summation / 1000);
                } catch (error) {
                }
            },
            error => null,
            () => s.unsubscribe()
        );
    }

    private triggerMeterValueEnterCycle(valueEntryPopover: any): Observable<any> {
        return this.popover.open(valueEntryPopover).afterClosed$.pipe(
            mergeMap((action) => {
                    console.log('action', action);
                    return !action.data ? throwError(null) : of(+action.data);
                }
            ),
            mergeMap(value => {
                const currentValue = parseInt(this.meterStats.kwh, 10);
                const plausible = value <= (currentValue * 2) && value >= (currentValue * 0.5);
                if (!plausible) {
                    return this.popover.open(MeterValueValidityCheckPopover).afterClosed$.pipe(
                        mergeMap(result => {
                            if (!result.data) {
                                return this.triggerMeterValueEnterCycle(valueEntryPopover);
                            }
                            return (of(value));
                        })
                    );
                }
                return of(value);
            })
        );
    }

    private initializeMeterStatusUpdate(): void {
        this.meter.startLiveUpdate();
        const meterS = this.meter.onMeterStatus.pipe(
            mergeMap(result => {
                try {
                    return of(result.meter_txrssi);
                } catch (error) {
                    return throwError({msg: 'invalid response', error});
                }
            })
        ).subscribe(
            (value) => this.connection_quality = value,
            error => null
        );
        this.addSub(meterS);
    }

    private getCurrentMeterValue(): void {
        this.meter.getCurrentMeterValue().subscribe((res) => {
            const value = Math.floor(res / 1000);
            this.meterStats.kwh = padNumber(value, 6);
        });
    }

    private formatSerialNumber(serialNumber: string) {
        let returnVal = '';
        if ((serialNumber === null || serialNumber === undefined) || serialNumber.length <= 1) {
            return serialNumber;
        }
        for (let i = 0; i < serialNumber.length; i++) {
            if (i == 1 || i == 4 || i == 6 || i == 10) {
                returnVal += ' ';
            }
            returnVal += serialNumber.charAt(i);
        }
        return returnVal;
    }

}
