import { Component, OnInit } from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss']
})
export class InfoPopoverComponent implements OnInit {

    title = '';
    text = '';

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
    }

    close(value = false): void {
        this.popoverRef.close(value);
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(false);
        });
        this.title = this.popoverRef.data.title;
        this.text = this.popoverRef.data.text;
    }

}
