import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';

@Component({
    selector: 'app-eon-ui-button-custom',
    templateUrl: './eon-ui-button-custom.component.html',
    styleUrls: ['./eon-ui-button-custom.component.scss']
})
export class EonUiButtonCustomComponent implements OnInit, AfterViewInit {

    @Input() colorScheme = 'default';
    @Input() icon = '';
    @Input() iconOnly = false;
    @Input() disabled = false;
    @Input() small = false;
    @Input() type = 'button';

    @Output() onClick = new EventEmitter();

    @ViewChild('buttonContent', {static: false}) buttonContent: ElementRef<HTMLDivElement>;
    @ViewChildren('edge') edges: QueryList<ElementRef<HTMLSpanElement>>;

    public selectedColorScheme: EonUIButtonColorScheme =
        eonButtonColorSchemes.find(el => el.name === 'default');
    private mouseOver = false;

    constructor(private renderer: Renderer2) {
    }

    ngOnInit(): void {
        if (typeof this.small === 'string') {
            this.small = this.small === 'true';
        }
        if (typeof this.disabled === 'string') {
            this.disabled = this.disabled === 'true';
        }
        if (this.colorScheme !== 'default') {
            const scheme = eonButtonColorSchemes.find(el => el.name === this.colorScheme);
            this.selectedColorScheme =
                scheme ? scheme : eonButtonColorSchemes.find(el => el.name === 'default');
        }
    }

    ngAfterViewInit(): void {
        this.resetBgColors();
    }

    onMouseEnter(): void {
        this.mouseOver = true;
        this.hoverColorChange();
    }

    onMouseLeave(): void {
        this.mouseOver = false;
        this.hoverColorChange();
    }

    getIconPath(): string {
        return `url("assets/img/icons/buttons/${this.icon}.svg")`;
    }

    private hoverColorChange(): void {
        if (this.mouseOver) {
            this.setHoverBgColors();
            return;
        }
        this.resetBgColors();
    }

    private setHoverBgColors(): void {
        if (this.buttonContent) {
            this.setElementBgColor(this.buttonContent, this.selectedColorScheme.bgHoverColor);
        }
        this.edges.forEach(el => this.setElementBgColor(el, this.selectedColorScheme.bgHoverColor));
    }

    private resetBgColors(): void {
        if (this.buttonContent) {
            this.setElementBgColor(this.buttonContent, this.selectedColorScheme.bgColor);
        }
        this.edges.forEach(el => this.setElementBgColor(el, this.selectedColorScheme.bgColor));
    }

    private setElementBgColor(element: ElementRef, color: string): void {
        this.renderer.setStyle(element.nativeElement, 'background-color', color);
    }

}

interface EonUIButtonColorScheme {
    name: string;
    textColor: string;
    bgColor: string;
    bgHoverColor: string;
}

const eonButtonColorSchemes: EonUIButtonColorScheme[] = [
    {
        name: 'default',
        textColor: '#fff',
        bgColor: '#ea1b0a',
        bgHoverColor: '#d51607'
    },
    {
        name: 'turquoise',
        textColor: '#fff',
        bgColor: '#1ea2b1',
        bgHoverColor: '#2b91a3'
    }
];
