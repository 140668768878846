import {PopoverRef} from '../popovers/popover/popover-ref';
import {OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

export class BasePopover implements OnDestroy {
    protected subscriptions: Subscription[] = [];

    constructor(protected popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnDestroy(): void {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    addSub(s: Subscription): void {
        this.subscriptions.push(s);
    }


    close(value = false): void {
        this.popoverRef.close(value);
    }

    private initializePopoverData(): void {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(false);
        });
    }
}
