export const imprintBaseUrl = 'https://app-content.n2g-iona.net/imprint/';

export const imprintUrls = {
    avacon: 'imprint_avacon.html',
    bh: 'imprint_bh.html',
    edis: 'imprint_edis.html',
    energis: 'imprint_energis.html',
    enviam: 'imprint_enviam.html',
    esc: 'imprint_eon_esc.html',
    iona: 'imprint_eon_iona.html',
    eprimo: 'imprint_eprimo.html',
    innogy: 'imprint_innogy.html',
    lew: 'imprint_lew.html',
    sh: 'imprint_sh.html'
};
