import {Component, Input, OnInit} from '@angular/core';
import {ApplicationService} from '../../services/application.service';
import {UserService} from '../../services/user.service';
import {getProviderIconClass, getProviderMenuName} from '../../lib/ProviderUtil';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {Changelog} from '../../popovers/changelog/changelog.model';
import {Popover} from '../../popovers/popover/popover.service';
import {ChangelogComponent} from '../../popovers/changelog/changelog.component';
import {map} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {LogoutPopoverConfig} from '../../popovers/static.popover.config';
import {static_links} from '../../shared/constants/web-resources.constants';
import {VisibilityService} from '../../services/visibility.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends BaseComponent implements OnInit {

    private changelog: Changelog = null;

    @Input() activeElement = '';

    version: '0.0.0';
    private providername = 'e.on';
    providerMenuName = '';
    providerclass = '';
    privacyLink = '';

    constructor(public application: ApplicationService,
                private _userService: UserService,
                private _versionService: VersionService,
                private _popover: Popover,
                private _apiService: ApiService,
                private visibility: VisibilityService
    ) {
        super();
    }

    ngOnInit() {
        this.providername = this._userService.getActiveUserProvider();
        this.providerclass = getProviderIconClass(this.providername);
        this.privacyLink = static_links.privacy[this.providername];
        this.privacyLink = 'https://www.eon.de/content/dam/eon/eon-de-zwei/documents/Privatkunden/smart-control/eon-smart-control-datenschutzinformation.pdf';
        this.providerMenuName = getProviderMenuName(this.providername);
        this.addSub(this._versionService.getChangelog().subscribe((res) => {
            this.version = res[0].version;
            this.changelog = res;
        }));
    }

    showChangelog() {
        const s = this._popover.open({
            content: ChangelogComponent,
            data: this.changelog,
            placement: 'center center',
            hasBackdrop: true,
        }).afterClosed$.subscribe(() => {
            s.unsubscribe();
        });
    }

    onLogout(): void {
        const sub = this._popover.open(LogoutPopoverConfig).afterClosed$.pipe(
            map(res => res.data)
        ).subscribe(
            (res) => {
                if (res) {
                    this._apiService.logoutUser();
                    this.visibility.kill();
                }
            },
            (err) => {
                console.log('Error: ', err);
            },
            () => {
                sub.unsubscribe();
            }
        );
    }
}
