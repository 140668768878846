import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';
import * as moment from 'moment';
import {BaseResponse} from '../shared/responses/base.response';
import {AccountRewriteService} from '../account-rewrite.service';

@Injectable({
    providedIn: 'root'
})
export class ElectricityService extends BaseService {
    onConsumptionHoursUpdate = new Subject<any>();
    onConsumptionDaysUpdate = new Subject<any>();
    onConsumption24hUpdate = new Subject<any>();
    onConsumptionFilteredUpdate = new Subject<any>();

    private updateRate = 10000;

    private hoursTimerSub: Subscription = null;
    private daysTimerSub: Subscription = null;
    private last24hTimerSub: Subscription = null;
    private filteredTimerSub: Subscription = null;

    private requestDateFormat = 'YYYY-MM-DD';
    private requestDateFormatMonths = 'YYYY-MM';

    private currentFilter = {
        offset: 24 * 60,
        limit: 0,
        interval: 24 * 60 * 60,
        level: 1
    };

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService,
                private accountRewrite: AccountRewriteService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.hoursTimerSub) {
            this.hoursTimerSub.unsubscribe();
            delete this.hoursTimerSub;
        }
        if (this.daysTimerSub) {
            this.daysTimerSub.unsubscribe();
            delete this.daysTimerSub;
        }
        if (this.last24hTimerSub) {
            this.last24hTimerSub.unsubscribe();
            delete this.last24hTimerSub;
        }
        if (this.filteredTimerSub) {
            this.filteredTimerSub.unsubscribe();
            delete this.filteredTimerSub;
        }
    }

    setCurrentFilter(offset: number, limit: number, interval: number, level: number): void {
        this.currentFilter.offset = offset;
        this.currentFilter.limit = limit;
        this.currentFilter.interval = interval;
        this.currentFilter.level = level;

        const temp = this.getFilteredConsumption().subscribe((res) => {
                if (res) {
                    this.onConsumptionFilteredUpdate.next(res);
                    temp.unsubscribe();
                }
            }
        );

    }

    /**
     * Start a live continuous update
     */
    startLiveHoursUpdate(): void {
        if (this.hoursTimerSub) {
            return;
        }
        this.hoursTimerSub = timer(0, this.updateRate).pipe(
            flatMap((cycle) => this.getConsumptionForDay(0))
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onConsumptionHoursUpdate.next(res);
                }
            }
        );
    }

    /**
     * Start a live continuous update
     */
    startLiveDaysUpdate(): void {
        if (this.daysTimerSub) {
            return;
        }
        this.daysTimerSub = timer(0, this.updateRate).pipe(
            flatMap((cycle) => this.getConsumptionForOffset(0))
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onConsumptionDaysUpdate.next(res);
                }
            }
        );
    }

    /**
     * Start an update on the consumption values for the last 24 hours
     */
    startLast24hTimerUpdate(): void {
        if (this.last24hTimerSub) {
            return;
        }
        this.last24hTimerSub = timer(0, this.updateRate).pipe(
            flatMap((cycle) => this.getConsumptionFor24Hours())
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onConsumption24hUpdate.next(res);
                }
            }
        );
    }

    /**
     * Start an update on the consumption values for the last 24 hours
     */
    startFilteredConsumptionUpdate(): void {
        if (this.filteredTimerSub) {
            return;
        }
        this.filteredTimerSub = timer(0, this.updateRate).pipe(
            flatMap((cycle) => this.getFilteredConsumption())
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onConsumptionFilteredUpdate.next(res);
                }
            }
        );
    }


    /**
     * Get Consumption filtered by
     */
    getFilteredConsumption(): Observable<any> {
        const timeframe = this.currentFilter.interval > 24 * 60 ? 'days' : 'hours';

        const from: any = new Date();
        from.setMinutes(from.getMinutes() - this.currentFilter.offset);

        const to: any = new Date();
        to.setMinutes(to.getMinutes() - this.currentFilter.limit);

        const from_s = moment(from).format(this.requestDateFormat);
        const to_s = moment(to).format(this.requestDateFormat);

        const suffix = `${timeframe}/${from_s}/${to_s}`;
        return this.getConsumption(suffix);
    }


    /**
     * Returns the current bill prediction.
     */
    getBillPrediction(): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.electricity.bill.prediction;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.billPrediction}.json`;
        }

        return this.http.get(
            url.toString(),
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }

    /**
     * Returns the consumption for a specific date... *jimface*
     * @param date
     */
    getConsumptionForDate(date: string): Observable<any> {
        return this.getConsumption(date, this.accountRewrite.accountRewriteEnabled());
    }

    /**
     * Returns the consumption for a specific day
     * @param dayOffset
     */
    getConsumptionForDay(dayOffset): Observable<any> {
        if (this.application.isDemoMode()) {
            let dataset = constants.demo.files.consumptionHours;
            if (dayOffset !== 0) {
                dataset = constants.demo.files.consumptionHoursLastWeek;
            }
            const url = `assets/data/demo/${dataset}.json`;
            return this.http.get(url).pipe(
                map((data: BaseResponse) => data.data)
            );
        }
        const date = new Date();
        date.setDate(date.getDate() - dayOffset);
        const method = moment(date).format(this.requestDateFormat);
        return this.getConsumption(`hours/${method}/${method}`);
    }

    /**
     * Request the consumption for the last 24 hours, mostly consisting of 2 days
     */
    getConsumptionFor24Hours(): Observable<any> {
        const today = moment();
        const yesterday = moment().subtract(1, 'day');
        const yesterdayStr = yesterday.format(this.requestDateFormat);
        const todayStr = today.format(this.requestDateFormat);
        const today_suffix = `hours/${yesterdayStr}/${todayStr}`;
        return this.getConsumption(today_suffix);
    }

    /**
     * Returns the consumption for a specific offset
     * @param offset
     */
    getConsumptionForOffset(offset): Observable<any> {
        const date: any = new Date();
        date.setDate(date.getDate() - offset);
        const method = moment(date).format(this.requestDateFormat);
        return this.getConsumption(`days/${method}/${method}`);
    }

    /**
     * This more or less feels like its the same than the call below.
     * *jimface*
     * @param type
     * @param date1
     * @param date2
     */
    getConsumptionCustom(type: 'hours' | 'days', date1: Date, date2: Date): Observable<any> {
        const date1Str = moment(date1).format(this.requestDateFormat);
        const date2Str = moment(date2).format(this.requestDateFormat);
        return this.getConsumption(`${type}/${date1Str}/${date2Str}`);
    }

    /**
     * Returns the consumption for a specific timeframe
     * @param from
     * @param to
     * @param timeframe
     */
    getConsumptionForTimeframe(from, to, timeframe): Observable<any> {
        let method = null;
        switch (timeframe) {
            case 'months': {
                const dFromStr = moment(from).format(this.requestDateFormatMonths);
                const dToStr = moment(to).format(this.requestDateFormatMonths);
                method = `${dFromStr}/${dToStr}`;
                break;
            }
            default: {
                const dFromStr = moment(from).format(this.requestDateFormat);
                const dToStr = moment(to).format(this.requestDateFormat);
                method = `${dFromStr}/${dToStr}`;
                break;
            }
        }
        return this.getConsumption(`${timeframe}/${method}`);
    }

    getFeedinForTimeframe(from, to, timeframe): Observable<any> {
        let method = null;
        switch (timeframe) {
            case 'months': {
                const dFromStr = moment(from).format(this.requestDateFormatMonths);
                const dToStr = moment(to).format(this.requestDateFormatMonths);
                method = `${dFromStr}/${dToStr}`;
                break;
            }
            default: {
                const dFromStr = moment(from).format(this.requestDateFormat);
                const dToStr = moment(to).format(this.requestDateFormat);
                method = `${dFromStr}/${dToStr}`;
                break;
            }
        }
        return this.getFeedin(`${timeframe}/${method}`);
    }

    /**
     * Main Function to request consumption
     * @param method
     */
    private getConsumption(method: string, useRewriteUrl = false): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.electricity.consumption.this;
        if (useRewriteUrl) {
            url = this.ACCOUNT_REWRITE_BASE_URL + constants.api.routes.electricity.consumption.this;
        }
        url += '/' + method;
        return this.http.get(
            url, {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }

    /**
     * Secondary function to retrieve fed energy values
     * @param method
     */
    private getFeedin(method: string): Observable<any> {
        const url = `${this.API_BASE_URL}${constants.api.routes.feedin.electricity}/${method}`;
        return this.http.get(
            url, {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }
}
