/**
 *
 * @param num
 * @param size
 */
export const padNumber = (num: number, size: number): string => {
    let num_str = num.toString();
    while (num_str.length < size) {
        num_str = '0' + num_str;
    }
    return num_str;
};
