import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {Observable, of, Subscription, throwError, timer} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ApiService} from './api.service';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {UserService} from './user.service';
import * as moment from 'moment';
import {User} from '../classes/user';
import {InitializationService} from './initialization.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {constants} from '../shared/constants/constants';
import {RegistrationService} from './registration.service';
import {MfaService} from './mfa.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService {

    private updateRate = (3600 - 30) * 1000;
    private timerSub: Subscription = null;
    private loginErrorCount = 0;
    private readonly validProvidernames = [
        'innogy', 'e.on', 'mme@wmsb', 'opto', 'e.on-legacy', 'e.on legacy', 'e.on-da'
    ];

    fromLogin = false;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private initialization: InitializationService,
                private router: Router,
                private toast: ToastrService,
                private apiService: ApiService,
                private registration: RegistrationService,
                private mfaService: MfaService) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }
    }


    requestLogin(username: string, password: string): Observable<LoginRequestResponse> {
        const headers = this.generateLoginHeaders();
        const body = {method: 'login', username, password};
        return this.http.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers}).pipe(
            mergeMap((authResponse: RegularLoginResponse | MfaLoginResponse | any) => {
                if ('access_token' in authResponse) {
                    return of({loginType: 'regular', response: authResponse});
                } else if ('software_challenge_name' in authResponse) {
                    this.mfaService.mfaUserId = authResponse.user_id;
                    this.mfaService.mfaSession = authResponse.session;
                    return of({loginType: 'mfa', response: authResponse});
                } else {
                    return throwError({error: LoginErrorReasons.INVALID_REQUEST});
                }
            }),
            catchError((errorResponse: HttpErrorResponse | any) => {
                return this.handleLoginError(errorResponse);
            })
        );
    }


    loginSuccessfulPipeline(username, loginResponse, isFirstCall = false): Observable<any> {
        return of(loginResponse).pipe(
            mergeMap((res: any) => {
                try {
                    const token_expires = moment().add(res.expires_in, 'seconds');
                    this.updateRate = (res.expires_in - 30) * 1000;
                    const u: User = {
                        email: username,
                        provider: null,
                        access_token: res.access_token,
                        refresh_token: res.refresh_token,
                        token_expires: token_expires.toDate(),
                        tiles: null,
                        nilm_status: null,
                        storage: null,
                        device: null,
                        edg_user: false
                    };
                    this.user.setCurrentUser(u);
                    return of({initialRes: res, isFirstCall});
                } catch (error) {
                    return throwError({error: LoginErrorReasons.PAYLOAD_ERROR, detail: error});
                }
            }),
            mergeMap((res: { initialRes: any, isFirstCall: boolean }) => {
                if (res.isFirstCall) {
                    return of(res);
                }
                return this.getUserInitialization();
            }),
            mergeMap((initialization: any) => {
                if (initialization) {
                    return this.registration.getOptInStatus().pipe(
                        mergeMap(res =>
                            res ? of(res) : throwError({
                                error: LoginErrorReasons.OPT_IN_REQUIRED,
                                detail: res
                            })
                        )
                    );
                }
                return of(initialization);
            }),
        );
    }


    login(username: string, password: string, isFirstCall = false): Observable<any> {
        // return this.oldLoginWrapper(username, password, first_call);
        const headers = this.generateLoginHeaders();
        // const params = this.generateLoginParams(username, password);
        const body = {method: 'login', username, password};
        return this.http.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers}).pipe(
            mergeMap((res: any) => {
                try {
                    const token_expires = moment().add(res.expires_in, 'seconds');
                    this.updateRate = (res.expires_in - 30) * 1000;
                    const u: User = {
                        email: username,
                        provider: null,
                        access_token: res.access_token,
                        refresh_token: res.refresh_token,
                        token_expires: token_expires.toDate(),
                        tiles: null,
                        nilm_status: null,
                        storage: null,
                        device: null,
                        edg_user: false
                    };
                    this.user.setCurrentUser(u);
                    return of({initialRes: res, isFirstCall});
                } catch (error) {
                    return throwError({error: LoginErrorReasons.PAYLOAD_ERROR, detail: error});
                }
            }),
            mergeMap((res: { initialRes: any, isFirstCall: boolean }) => {
                if (res.isFirstCall) {
                    return of(res);
                }
                return this.getUserInitialization();
            }),
            mergeMap((initialization: any) => {
                if (initialization) {
                    return this.registration.getOptInStatus().pipe(
                        tap(status => console.log('status', status)),
                        mergeMap(res =>
                            res ? of(res) : throwError({
                                error: LoginErrorReasons.OPT_IN_REQUIRED,
                                detail: res
                            })
                        )
                    );
                }
                return of(initialization);
            }),
            catchError((errorResponse: HttpErrorResponse | any) => {
                return this.handleLoginError(errorResponse);
            })
        );
    }


    /**
     * Performs the 'initialization' call
     * Extracts the users provider and determines authorization validity based on it
     */
    private getUserInitialization(): Observable<boolean | Error> {
        return this.initialization.get().pipe(
            mergeMap(initRes => {
                try {
                    const providername = initRes.profile.labelpartner.toLowerCase();
                    const included = this.validProvidernames.findIndex(
                        el => el === providername);
                    if (included < 0) {
                        return throwError({
                            error: LoginErrorReasons.INVALID_PROVIDER,
                            detail: providername
                        });
                    }
                    this.user.setActiveUserProvider(providername);
                    return of(true);
                } catch (error) {
                    return throwError({error: LoginErrorReasons.PAYLOAD_ERROR, detail: error});
                }
            }),
            mergeMap(success => {
                if (!success) {
                    return throwError({
                        error: LoginErrorReasons.INITIALIZATION_ERROR,
                        detail: null
                    });
                }
                return this.getRegistrationModel();
            })
        );
    }


    /**
     * Fetches the users device identifier and assigns it to the current user
     */
    private getRegistrationModel(): Observable<any> {
        return this.registration.getModel().pipe(
            mergeMap(res => {
                try {
                    this.user.updateUserDevice(res.model_identifier);
                    const isEDGUser =
                        res.model_identifier === constants.application.devices.plug_optical ||
                        res.model_identifier === constants.application.devices.plug;
                    this.user.setEDGUser(isEDGUser);
                } catch (error) {
                    return throwError({
                        error: LoginErrorReasons.REGISTRATION_ERROR,
                        detail: error
                    });
                }
                return of(true);
            }),
        );
    }


    private handleLoginError(errorResponse: HttpErrorResponse | any): Observable<any> {
        if (errorResponse instanceof HttpErrorResponse) {
            const error = errorResponse.error;
            const status = errorResponse.status;
            this.loginErrorCount++;

            if (this.loginErrorCount >= 3) {
                this.toast.error('Möchten Sie Ihr Passwort zurücksetzen? Sie erhalten zunächst eine Email mit einer Anleitung zum weiteren Vorgehen. <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                    disableTimeOut: true,
                    enableHtml: true
                });
            }

            switch (error.error) {
                case 'invalid_request': {
                    this.toast.error('E-Mail-Adresse oder Passwort fehlt!');
                    return throwError({
                        error: LoginErrorReasons.INVALID_REQUEST,
                        detail: null
                    });
                }
                case 'invalid_grant': {
                    this.toast.error('E-Mail-Adresse oder Passwort fehlerhaft!');
                    return throwError({
                        error: LoginErrorReasons.UNAUTHORIZED,
                        detail: null
                    });
                }
                case 'Maximum number of failed attempts reached. The account is now blocked.': {
                    this.toast.error(
                        'Ihr Account wurde nach der 10-maligen Eingabe eines falschen Passworts gesperrt. Möchten Sie Ihr Passwort zurücksetzen? <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                            disableTimeOut: true,
                            enableHtml: true
                        });
                    return throwError({
                        error: LoginErrorReasons.UNAUTHORIZED,
                        detail: null
                    });
                }
                default: {
                    this.toast.error('Es ist ein Problem aufgetreten!');
                }
            }

            if (status === 125) {
                console.log('User appears to not be onboarded yet');
            }
        }

        return throwError(errorResponse);
    }


    startContinuousTokenRefresh(): void {
        if (this.timerSub) {
            return;
        }
        const expiryDateStored = this.user.getActiveUserTokenExpire();
        let refreshDue = 0;
        if (expiryDateStored) {
            const parsedDate = moment(expiryDateStored);
            refreshDue = Math.abs(moment().diff(parsedDate)) - 10000;
        }
        this.timerSub = timer(refreshDue, this.updateRate).pipe(
            mergeMap((c) => this.refreshToken())
        ).subscribe((res) => {
                if (res) {
                    const expiryDate = moment().add(res.expires_in, 'seconds');
                    this.user.updateActiveUserAccessToken(res.access_token);
                    this.user.updateActiveUserRefreshToken(res.refresh_token);
                    this.user.updateActiveUserTokenExpire(expiryDate.toDate());
                    return true;
                }
            },
            error => {
                this.auth.logoutUser();
            }
        );
    }


    refreshToken(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });
        const method = 'refresh';
        const refresh_token = this.user.getActiveUserRefreshToken();
        const body = {method, refresh_token};
        return this.http.post(this.AUTH_BASE_URL, JSON.stringify(body), {headers}).pipe(
            catchError((error) => this.handleTokenRefreshError(error))
        );
    }


    private handleTokenRefreshError(error: HttpErrorResponse | any): Observable<Error> {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
                return throwError({error: 'UNAUTHORIZED'});
            }
        }
        return throwError(error);
    }


    private generateLoginHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Content-Security-Policy': 'connect-src \'self\' \'unsafe-inline\' https://api.n2g-iona.net'
        });
    }


    private notifyBasedOnError(error): void {
        // console.log('error', error);
        switch (error.error) {
            case 'invalid_request': {
                this.toast.error('E-Mail-Adresse oder Passwort fehlt!');
                break;
            }
            case 'invalid_grant': {
                this.toast.error('E-Mail-Adresse oder Passwort fehlerhaft!');
                break;
            }
            case 'Maximum number of failed attempts reached. The account is now blocked.': {
                this.toast.error(
                    'Ihr Account wurde nach der 10-maligen Eingabe eines falschen Passworts gesperrt. Möchten Sie Ihr Passwort zurücksetzen? <a class="btn" href="#/passwort-vergessen" title="Zurücksetzen">Zurücksetzen</a>', '', {
                        disableTimeOut: true,
                        enableHtml: true
                    });
                break;
            }
            default: {
                this.toast.error('Es ist ein Problem aufgetreten!');
            }
        }
    }

}

export const enum LoginErrorReasons {
    UNAUTHORIZED = 'UNAUTHORIZED',
    INVALID_PROVIDER = 'INVALID_PROVIDER',
    INITIALIZATION_ERROR = 'INITIALIZATION_ERROR',
    REGISTRATION_ERROR = 'REGISTRATION_ERROR',
    INVALID_REQUEST = 'INVALID_REQUEST',
    PAYLOAD_ERROR = 'PAYLOAD_ERROR',
    OPT_IN_REQUIRED = 'OPT_IN_REQUIRED'
}


export interface LoginRequestResponse {
    loginType: 'mfa' | 'regular';
    response: RegularLoginResponse | MfaLoginResponse | any;
}

export interface RegularLoginResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    id_token?: string;
    scope: any;
    token_type: string;
}

export interface MfaLoginResponse {
    session: string;
    software_challenge_name: string;
    user_id: string;
}

export interface LoginError {
    error: LoginErrorReasons;
    detail: Error | HttpErrorResponse | any;
}
