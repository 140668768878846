import {Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[heightControl]'
})
export class HeightControlDirective implements OnInit, OnChanges {

    @Input() heightControl: number;

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit(): void {
        this.renderer.setStyle(this.el.nativeElement, 'height', `${this.heightControl}%`);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.renderer.setStyle(this.el.nativeElement, 'height', `${this.heightControl}%`);
    }
}
