import {Injectable} from '@angular/core';
import {constants} from '../shared/constants/constants';
import {StorageAttributes} from '../shared/constants/storage-attributes.constants';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    setApplicationState(state: string): void {
        localStorage.setItem(StorageAttributes.APP_MODE, state);
    }

    enableDemoMode(current_time: Date): void {
        localStorage.setItem(StorageAttributes.APP_MODE, constants.application.states.demo);
        localStorage.setItem(StorageAttributes.APP_EXPIRY, current_time.toString());
    }

    getAppMode(): string {
        return localStorage.getItem(StorageAttributes.APP_MODE);
    }

    isLiveMode(): boolean {
        return localStorage.getItem(StorageAttributes.APP_MODE) === constants.application.states.live;
    }

    isDemoMode(): boolean {
        return localStorage.getItem(StorageAttributes.APP_MODE) === constants.application.states.demo;
    }

    isNoneMode(): boolean {
        return localStorage.getItem(StorageAttributes.APP_MODE) === constants.application.states.none;
    }
}
