import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';

@Component({
    selector: 'app-eon-square-button',
    templateUrl: './eon-square-button.component.html',
    styleUrls: ['./eon-square-button.component.scss']
})
export class EonSquareButtonComponent implements OnInit {
    @Input() label = '';
    @Input() icon: EON_ICON = null;
    @Input() disabled = true;

    @Output() onButtonPressed = new EventEmitter();

    @ViewChild('bg', {static: true}) backgroundContainer: ElementRef;
    @ViewChild('icon', {static: true}) iconContainer: ElementRef;

    private _enabledBackground = '';
    private _disabledBackground = '';

    constructor(private _renderer: Renderer2) {
    }

    ngOnInit() {
        if (this.icon) {
            this._renderer.setStyle(this.iconContainer.nativeElement,
                'background-image',
                `url("/assets/img/icons/${this.icon}")`);
        }
        this._renderer.setStyle(this.backgroundContainer.nativeElement,
            'background-image',
            `url("/assets/img/icons/${this.disabled ? this._enabledBackground : this._disabledBackground}")`);
    }

}

export enum EON_ICON {
    EDIT = 'edit/icon-edit@2x.png',
    ADJUST = 'adjust/icon-adjust@2x.png',
    PLUS = 'plus/icon-plus@2x.png'
}
