import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

    faqUrl = environment.urls.faq;

    @ViewChild('frame', {static: true}) iframeElement: ElementRef<HTMLIFrameElement>;

    constructor() {
    }

    ngOnInit() {
        this.initialize();
    }

    onFrameLoaded(): void {
    }

    private initialize(): void {
        const frame = this.iframeElement.nativeElement;
        frame.src = this.faqUrl;
    }


}
