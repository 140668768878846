import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {OverlayService} from '../../overlay/overlay.service';
import {UserService} from '../../services/user.service';
import {of, Subscription, throwError} from 'rxjs';
import {NilmOverlayResult} from '../../overlay/overlay.component';
import {MockDataService} from '../../services/mock-data.service';
import {constants} from '../../shared/constants/constants';
import {MvpConfig, MvpService} from '../../services/mvp.service';
import {ApplicationService} from '../../services/application.service';
import {MeterService} from '../../services/meter.service';
import {flatMap, mergeMap, switchMap} from 'rxjs/operators';
import {ProfileService} from '../../services/profile.service';
import {NilmService} from '../../services/nilm.service';
import {RegistrationService} from '../../services/registration.service';
import {VersionService} from '../../services/version.service';
import {BaseComponent} from '../../classes/base-component';
import {UserGroupService} from '../../services/user-group.service';
import {Popover} from '../../popovers/popover/popover.service';
import {AddTileComponent} from '../../popovers/add-tile/add-tile.component';
import {TILE_TYPE, TileDef, TileService} from '../../services/tile.service';
import {SortTilesComponent} from '../../popovers/sort-tiles/sort-tiles.component';
import {
    FirmwareUpdateAvailablePopover,
    FirmwareUpdatePopover,
    ManualPinEntryInfoPopoverConfig,
    ManualPinEntryPopoverConfig,
    PinEntryPopoverConfig,
    PinFailedPopoverConfig,
    RadioLinkLostPopover
} from '../../popovers/static.popover.config';
import {VisibilityService} from '../../services/visibility.service';
import {MeterReaderStatus, OpticalReaderService} from '../../services/optical-reader.service';
import {BannerComponent} from '../../components/banner/banner.component';
import * as moment from 'moment';
import {FirmwareUpdateService} from '../../services/firmware-update.service';
import {MeterStatuses} from '../../shared/constants/meter-statuses.constants';
import {Observable} from 'rxjs/Observable';
import {MeterReadingService} from '../../services/meter-reading.service';
import {Banners} from '../../shared/constants/banners.constants';
import {ActivatedRoute, Router} from '@angular/router';
import {ACTION} from '../../lib/Action';
import {StorageAttributes} from '../../shared/constants/storage-attributes.constants';
import {OptInService} from '../../services/opt-in.service';
import {GTMWrapperService} from '../../services/gtmwrapper.service';
import {AuthService} from '../../services/auth.service';

@Component({
    selector: 'iona-app',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    TILE_TYPE = TILE_TYPE;

    private refresh = null;
    private nilmRefresh = null;

    userHasPlug = false;

    mvpTileAvailable = false;
    dashboardTiles: TileDef[] = [];
    tilesAvailable = false;

    currentORBatteryState = 0;

    private lastPopupShown: Date = null;

    private visibilitySub = null;
    private popoversOpen = false;
    private preventPinErrorPopover = false;
    private meterReadingSub: Subscription = null;
    private meterPin = null;

    @ViewChild('banner', {static: true}) banner: BannerComponent;

    constructor(public application: ApplicationService,
                public tileService: TileService,
                public userService: UserService,
                private title: Title,
                private overlayService: OverlayService,
                private mockData: MockDataService,
                private renderer: Renderer2,
                private mvpService: MvpService,
                private meter: MeterService,
                private profile: ProfileService,
                private nilm: NilmService,
                private registration: RegistrationService,
                private versionService: VersionService,
                private userGroupService: UserGroupService,
                private popover: Popover,
                private visibility: VisibilityService,
                private opticalReader: OpticalReaderService,
                private updateService: FirmwareUpdateService,
                private meterReading: MeterReadingService,
                private route: ActivatedRoute,
                private router: Router,
                private optInService: OptInService,
                private gtm: GTMWrapperService,
                private auth: AuthService) {
        super();
    }

    ngOnInit() {
        this.title.setTitle('Übersicht | E.ON Smart Control');
        this.optInService.checkStatus(true);

        // initialize the overlay to use the correct configuration
        this.setupOverlay();

        this.initializeDashboardTiles();

        // further inits
        // this.setupVisibilityCallback();
        // this.checkNilmStatus();
        this.initPlug();
        this.initERNA();
        this.nilm.startNilmStatusUpdateForCurrentProfile();

        this.initPhaseChecker();
        this.initializeMVPTiles();

        this.initializeMeterReading();
        this.handleQueryParams();

        // this.nilmRefresh = setInterval(() => {
        //     this.checkNilmStatus();
        // }, 300000);
    }

    ngOnDestroy() {
        if (this.refresh) {
            clearInterval(this.refresh);
        }

        if (this.nilmRefresh) {
            clearInterval(this.nilmRefresh);
        }

        if (this.visibilitySub) {
            this.visibilitySub.unsubscribe();
            this.visibilitySub = null;
        }

        if (this.meterReadingSub) {
            this.meterReadingSub.unsubscribe();
            this.meterReadingSub = null;
        }

        delete this.tileService;
    }

    ngAfterViewInit() {
        if (this.auth.fromLogin) {
            const labelpartner = this.userService.getActiveUserProvider();
            const meterType = this.userService.getUserDevice();
            this.auth.fromLogin = false;
            this.trackAfterLoginEvent(labelpartner, meterType);
        }
    }


    private initializeDashboardTiles(): void {
        this.dashboardTiles = this.tileService.getCurrentTiles();
        this.tilesAvailable = this.tileService.tilesAvailable();
        this.tileService.init();
        this.addSub(this.tileService.selectionChanged.subscribe((res: any) => {
            if (this.tileService) {
                this.tilesAvailable = this.tileService.tilesAvailable();
                this.dashboardTiles = res;
            }
        }));

    }

    private handleQueryParams(): void {
        if (this.application.isDemoMode()) {
            return;
        }

        this.route.queryParams.subscribe((params) => {
            if (!('detail' in params)) {
                return;
            }
            switch (params.detail) {
                case 'meter':
                    this.tileService.openDetailView(TILE_TYPE.METER, ACTION.TRIGGER_METER_READING);
                    this.router.navigate(['/']);
                    break;
            }
        });
    }

    private initializeMeterReading(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        if (!this.userService.isMMEWMSBUser()) {
            return;
        }
        let counter = 0;
        const s = this.meterReading.getMeterReadings().subscribe(
            (res) => {
                ++counter;
                if (!res) {
                    return;
                }
                if (res.length > 0) {
                    this.banner.show(Banners.meterReading);
                }
                if (counter >= 30) {
                    s.unsubscribe();
                }
                s.unsubscribe();
            },
            (error) => {
                ++counter;
                if (counter >= 30) {
                    s.unsubscribe();
                }
            },
            () => s.unsubscribe()
        );
        this.addSub(s);
    }

    /**
     * Wrapper for extracting MVP tile configs an minor response restructuring
     * @param response
     */
    private extractMvpTileDefinitions(response): Observable<MvpConfig[]> {
        try {
            const mapped = response.groups.map(group => {
                const cfg = group.parameters as MvpConfig;
                cfg.group = group.group_id;
                return cfg;
            }) as MvpConfig[];
            return of(mapped);
        } catch (error) {
            return throwError(error);
        }
    }

    /**
     * MPV Tile initialization pipeline
     */
    private initializeMVPTiles(): void {
        if (this.application.isDemoMode()) {
            return;
        }

        this.tileService.removeLegacyMVPTiles();
        const sub = this.userGroupService.getUserGroup().pipe(
            mergeMap(response => this.extractMvpTileDefinitions(response)),
            mergeMap(tileDefs => of({
                    currentConfig: this.userService.getLastMvpTileConfigNew(),
                    newConfig: tileDefs
                })
            ),
        ).subscribe(
            (configs: { currentConfig: MvpConfig[], newConfig: MvpConfig[] }) => {
                const currentConfig = configs.currentConfig;
                const newConfigs = configs.newConfig;

                if (currentConfig) {
                    for (const config of currentConfig) {
                        const newConfig = newConfigs.find(el => el.id === config.id);
                        if (!newConfig) {
                            this.tileService.removeMVPTile(config);
                            this.userService.setMvpTileConfigNew(newConfigs);
                        }
                    }

                    for (const config of newConfigs) {
                        const oldConfig = currentConfig.find(el => el.id === config.id);
                        if (oldConfig) {
                            const tileThere = this.tileService.mvpTileIsCurrentlyActive(config.id);
                            if (!tileThere) {
                                if (config.dashboardConfiguration.version >
                                    oldConfig.dashboardConfiguration.version) {
                                    if (config.dashboardConfiguration.forceAdd) {
                                        this.tileService.updateMVPTileConfig(config);
                                        this.tileService.toggleMVPTile(config.id);
                                        this.tileService.updateMVPTilePosition(config);

                                        // save mvp tile configs
                                        this.userService.setMvpTileConfigNew(newConfigs);
                                    }
                                }
                            }
                        } else {
                            this.tileService.addMVPTile(config);
                        }
                    }
                } else {
                    for (const config of newConfigs) {
                        this.tileService.addMVPTile(config);
                    }
                    this.userService.setMvpTileConfigNew(newConfigs);
                }
                this.mvpTileAvailable = true;
            },
            error => console.log('Error initializing MVP tile feature:', error),
            () => sub.unsubscribe()
        );
    }

    private initPhaseChecker(): void {
        const o = of(this.userService.hasPhaseChecker()).pipe(
            flatMap((res) => {
                if (!res) {
                    return this.meter.onMeterStatus;
                }
                return of(null);
            })
        );
        let sub = null;
        sub = o.subscribe(
            (res) => {
                if (res) {
                    if ('lora_mode' in res) {
                        if (res.lora_mode === 0 || res.lora_mode === 7 || res.lora_mode === 8) {
                            this.tileService.enableTileType(TILE_TYPE.PHASE_CHECKER);
                            this.tileService.setSelected(true, TILE_TYPE.PHASE_CHECKER);
                            // this.addTile('phase-checker', false);
                        }
                        this.userService.setPhaseCheckerAvailability(true);
                    }
                }
                if (sub) {
                    sub.unsubscribe();
                }
            },
        );
    }

    /**
     * Check the NILM status and display an overlay if something changed
     */
    // private checkNilmStatus(): void {
    //     const profile_sub = this.profile.getAttributes();
    //     const nilm_sub = this.nilm.getStatus();
    //     forkJoin([profile_sub, nilm_sub]).subscribe(
    //         (responses) => {
    //             const profile_data = responses[0];
    //             const nilm_data = responses[1];
    //             this.determineNewlyAddedAppliances(nilm_data, profile_data);
    //         },
    //         (error) => {
    //             console.warn(error);
    //         }
    //     );
    // }
    //

    /**
     * Check the user has a plug or a box
     */
    private initPlug(): void {
        this.registration.getModel().subscribe(
            (res) => {
                if ('model_identifier' in res) {
                    this.handlePlugResponse(res);
                }
            }
        );
    }

    /**
     * If the user has a plug, handle the response and act accordingly
     * @param response
     */
    private handlePlugResponse(response: any): void {
        const model = response.model_identifier;
        switch (model) {
            case constants.application.devices.plug:
            case constants.application.devices.plug_optical:
                this.userService.updateUserDevice(constants.application.devices.plug);
                this.userHasPlug = true;
                break;
            case constants.application.devices.box:
                this.userService.updateUserDevice(constants.application.devices.box);
                break;
            default:
                this.userService.updateUserDevice(constants.application.devices.box);
                this.userHasPlug = false;
        }


        if (this.userHasPlug) {
            if (!this.tileService.tileAvailable(TILE_TYPE.POWER_CHECKER)) {
                this.tileService.enableTileType(TILE_TYPE.POWER_CHECKER);
                this.tileService.setSelected(true, TILE_TYPE.POWER_CHECKER);
            }
        }
    }

    private initERNA(): void {
        if (this.userService.isEDGUser()) {
            this.tileService.disableTileType(TILE_TYPE.PHASE_CHECKER);

            this.opticalReader.startLiveUpdate();
            this.enableMeterStatusUpdates();
        }
    }

    private shouldTriggerTimeBasedOverlay(item: string, timeframe: any, time: number): boolean {
        const lastTriggered = localStorage.getItem(item);
        if (!lastTriggered) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(time, timeframe).toDate()) {
            localStorage.setItem(item, moment().toDate().toString());
            return true;
        }
        return false;
    }

    private shouldTriggerPinOverlay(): boolean {
        if (this.preventPinErrorPopover) {
            return false;
        }

        const lastTriggered = localStorage.getItem(StorageAttributes.LAST_PIN_INFO);
        if (!lastTriggered) {
            localStorage.setItem(StorageAttributes.LAST_PIN_INFO, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(1, 'hour').toDate()) {
            localStorage.setItem(StorageAttributes.LAST_PIN_INFO, moment().toDate().toString());
            return true;
        }
        return false;
    }

    private shouldTriggerAvailableUpdateOverlay(): boolean {
        const lastTriggered = localStorage.getItem(StorageAttributes.LAST_UPDATE_INFO);
        if (!lastTriggered) {
            localStorage.setItem(StorageAttributes.LAST_UPDATE_INFO, moment().toDate().toString());
            return true;
        }

        const date = new Date(lastTriggered);
        if (date <= moment().subtract(24, 'hour').toDate()) {
            localStorage.setItem(StorageAttributes.LAST_UPDATE_INFO, moment().toDate().toString());
            return true;
        }
        return false;
    }

    handleStateIndependentValues(res: MeterReaderStatus): Observable<any> {
        this.currentORBatteryState = res.battery_status;
        if (res.mode === 'RT_INACTIVE') {
            if (res.battery_status > 0) {
                // this.banner.show(Banners.energySaver);
            } else {
                // this.banner.hide();
            }
        }
        return of(res);
    }

    /**
     * Trigger the popover cycle necessary when the pin entry has failed
     *    furthermore determine whether the overlays should be shown
     */
    private triggerPinFailedPopoverCycle(): Observable<any> {
        return of(this.shouldTriggerPinOverlay()).pipe(
            mergeMap((trigger) => {
                if (trigger) {
                    return this.popover.open(PinFailedPopoverConfig).afterClosed$.pipe(
                        switchMap((dialogData) => {
                            if (dialogData.data === null) {
                                this.popoversOpen = false;
                                return of(null);
                            }
                            let overlayDef: any = ManualPinEntryPopoverConfig;
                            if (dialogData.data) {
                                overlayDef = PinEntryPopoverConfig;
                            }
                            if (this.meterPin) {
                                overlayDef.data.text +=
                                    ` Ihre aktuelle Zähler-Pin lautet: ${this.meterPin}`;
                            }
                            return this.popover.open(overlayDef).afterClosed$;
                        })
                    );
                } else {
                    return of(null).pipe(switchMap(res => of(res)));
                }
            })
        );
    }

    private enableMeterStatusUpdates(): void {
        let previousStatus = '';
        this.opticalReader.onMeterReaderStatus.pipe(
            mergeMap((res: MeterReaderStatus) => {
                return this.handleStateIndependentValues(res);
            }),
            mergeMap((result: MeterReaderStatus) => {
                // if the update state has changed killit
                if (previousStatus === MeterStatuses.UPDATE_INSTALLING &&
                    result.status !== MeterStatuses.UPDATE_INSTALLING) {
                    this.updateService.onUpdateStateReceived.next(null);
                }
                previousStatus = result.status;

                this.meterPin = result.pincode;
                const pincodeThere = result.pincode
                    ? result.pincode !== '' || result.pincode !== null
                    : false;
                const pinEntryModeValid = result.pin_entry_mode === 'optical' ||
                    result.pin_entry_mode === 'unknown';
                const pinInputRequired = result.pin_entry_mode === 'manual_push_button' ||
                    result.pin_entry_mode === 'manual_torch';

                const meterUnlocked = result.meter_unlocked;

                let toReturn = of(null);

                // status independent mechanisms
                if (this.shouldTriggerTimeBasedOverlay(
                    StorageAttributes.MANUAL_PIN_ENTRY_MODE, 'hours', 24)) {
                    if (!meterUnlocked && pinInputRequired) {
                        const overlayDef = ManualPinEntryInfoPopoverConfig;
                        if (this.meterPin) {
                            overlayDef.data.text +=
                                ` Ihre aktuelle Zähler-Pin lautet: ${this.meterPin}.`;
                        }
                        return this.popover.open(overlayDef)
                            .afterClosed$.pipe(
                                mergeMap(res =>
                                    of({type: MeterStatuses.MANUAL_PIN_ENTRY_REQUIRED, res})
                                ));
                    }
                }

                switch (result.status) {
                    case MeterStatuses.PIN_FAILED:
                        if (!this.popoversOpen) {
                            toReturn = this.triggerPinFailedPopoverCycle().pipe(
                                mergeMap(res => of({type: MeterStatuses.PIN_FAILED, res}))
                            );
                        }
                        break;
                    case MeterStatuses.UPDATE_INSTALLING:
                        this.updateService.onUpdateStateReceived.next(result.update_progress);
                        if (!this.popoversOpen) {
                            if (result.update_progress < 100) {
                                toReturn = this.popover.open(FirmwareUpdatePopover).afterClosed$
                                    .pipe(
                                        mergeMap(res => of({
                                            type: MeterStatuses.UPDATE_INSTALLING, res
                                        }))
                                    );
                                this.popoversOpen = true;
                            }
                        }
                        break;
                    case MeterStatuses.CONNECTED_WITH_METER:
                        if (!meterUnlocked) {
                            if (result.key_retries === 0
                                && !pincodeThere
                                && pinEntryModeValid) {
                                toReturn = this.triggerPinFailedPopoverCycle().pipe(
                                    mergeMap(res =>
                                        of({type: MeterStatuses.PIN_FAILED, res}))
                                );
                                break;
                            }
                            this.banner.show(Banners.meterConnecting);
                        }

                        if (result.firmware_status === 'UPDATE_AVAILABLE') {
                            if (!this.shouldTriggerAvailableUpdateOverlay()) {
                                break;
                            }
                            if (this.popoversOpen) {
                                break;
                            }
                            toReturn = this.popover.open(FirmwareUpdateAvailablePopover)
                                .afterClosed$.pipe(
                                    mergeMap(res => of({
                                        type: MeterStatuses.CONNECTED_WITH_METER, res
                                    }))
                                );
                            this.popoversOpen = true;
                        }


                        break;
                    case MeterStatuses.RADIO_LINK_LOST:
                        if (!this.shouldTriggerTimeBasedOverlay(
                            StorageAttributes.RADIO_LINK_LOST_INFO, 'hours', 24)) {
                            break;
                        }
                        if (this.popoversOpen) {
                            break;
                        }
                        toReturn = this.popover.open(RadioLinkLostPopover).afterClosed$.pipe(
                            mergeMap(res => of(
                                {type: MeterStatuses.RADIO_LINK_LOST, res}
                            ))
                        );
                        this.popoversOpen = true;
                        break;
                    case MeterStatuses.READY_FOR_METER_INCLUSION:
                        // if (!pincodeThere && !this.popoversOpen) {
                        //     toReturn = this.triggerPinFailedPopoverCycle().pipe(
                        //         mergeMap(res => of({type: MeterStatuses.PIN_FAILED, res}))
                        //     );
                        // }
                        this.banner.show(Banners.meterConnectingTutorial);
                        break;
                    default:
                        break;
                }
                return toReturn;
            }),
        ).subscribe((result) => {
            try {
                if (result.type === MeterStatuses.PIN_FAILED) {
                    if (typeof result.res.data === 'string') {
                        const pin = parseInt(result.res.data, 10);
                        this.preventPinErrorPopover = true;
                        this.meter.startContinuousPinEntry(pin);
                        this.meter.onPinEntrySuccess.subscribe((res) => {
                            if (res) {
                                this.preventPinErrorPopover = false;
                            }
                        });
                    }
                    this.popoversOpen = false;
                } else if (result.type === MeterStatuses.UPDATE_INSTALLING) {

                } else if (result.type === MeterStatuses.CONNECTED_WITH_METER) {
                    if (result.res.data) {
                        this.updateService.startUpdate().subscribe(() => {
                        });
                    }
                } else if (result.type === MeterStatuses.RADIO_LINK_LOST) {

                }
                this.popoversOpen = false;
            } catch (error) {
            }
        });
    }

    /**
     * Setup visibility change funcitonality
     */
    // private setupVisibilityCallback(): void {
    //     if (!this.visibilitySub) {
    //         this.visibilitySub = this.visibility.onVisible.subscribe(() => {
    //             this.checkNilmStatus();
    //         });
    //     }
    // }

    /**
     * Setup the legacy appearing overlay gargles
     */
    private setupOverlay(): void {
        this.overlayService.onConfirm.subscribe(
            (result: NilmOverlayResult) => {
                this.userService.updateActiveUserNilmStatusForAppliance(result.tag, result.amount);
                const change = {Appliance: {}};
                change.Appliance[result.id] = result.amount;
                if (!this.application.isDemoMode()) {
                    this.profile.setAttributes(change).subscribe(
                        (res) => {
                        }
                    );
                    // this._apiService.setProfile(change);
                }
            }
        );
    }

    /**
     * Filter NILM Device model count ~ whatever that means
     * @param data
     * @param device_map
     */
    private filterNilmDeviceModelCount(data: any, device_map) {
        const result = {
            timeBasedAppliances: {
                dishWasher: null,
                dryer: null,
                oven: null,
                washingMachine: null
            }
        };
        for (const category of device_map) {
            const response_category = data[category.key];
            if (response_category === null || response_category === undefined) {
                continue;
            }
            for (const dev of category.elements) {
                const value = response_category[dev].models;
                if (value === null || value === undefined) {
                    continue;
                }
                result[category.key][dev] = value;
            }
        }
        return result;
    }

    /**
     * Determines which appliances were added since the last time
     */
    private determineNewlyAddedAppliances(response, profile_data): void {
        const old_nilm = this.userService.getActiveUserNilmStatus();
        if (old_nilm === null || old_nilm === undefined) {
            this.userService.updateActiveUserNilmStatus(response);
            return;
        }

        // setup nilm_devices filter
        const nilm_devices = [{
            key: 'timeBasedAppliances',
            elements: ['dishWasher', 'washingMachine', 'dryer', 'oven']
        }];

        const new_amounts = this.filterNilmDeviceModelCount(response, nilm_devices);
        const old_amounts = this.filterNilmDeviceModelCount(old_nilm, nilm_devices);

        for (const key in new_amounts) {
            if (!new_amounts.hasOwnProperty(key)) {
                continue;
            }

            const parent_old = old_amounts[key];
            const parent_new = new_amounts[key];

            for (const inner_key in parent_new) {
                if (parent_old[inner_key] === 0) {
                    if (parent_new[inner_key] !== 0 &&
                        response['timeBasedAppliances'][inner_key].profileComplete === false) {
                        const config = {
                            title: 'E.ON Smart Control hat ein neues Gerät erkannt',
                            info: 'Wie viele # gibt es in Ihrem Haushalt?',
                            icon: null,
                            amount: -1,
                            tag: inner_key,
                            appliance: null
                        };
                        switch (inner_key) {
                            case 'washingMachine':
                                config.icon = 'A.11';
                                config.appliance = 'Waschmaschinen';
                                config.amount = profile_data.Appliances['A.11'];
                                break;
                            case 'dishWasher':
                                config.icon = 'A.10';
                                config.appliance = 'Spülmaschinen';
                                config.amount = profile_data.Appliances['A.10'];
                                break;
                            case 'dryer':
                                config.icon = 'A.12';
                                config.appliance = 'Trockner';
                                config.amount = profile_data.Appliances['A.12'];
                                break;
                            case 'oven':
                                config.icon = 'A.04';
                                config.appliance = 'Öfen';
                                config.amount = profile_data.Appliances['A.04'];
                                break;
                        }

                        if (document.visibilityState === 'visible') {
                            this.overlayService.initialize(config);
                            this.overlayService.showOverlay(true);
                            this.userService.updateActiveUserNilmStatusForAppliance(
                                inner_key, new_amounts.timeBasedAppliances[inner_key]
                            );
                        } else {
                            this.lastPopupShown = new Date();
                        }

                        return;
                    }
                }
            }
        }
    }

    /**
     * On Sort Dashboard tiles button clicked.
     * Opens an overlay on the rhs containing the list
     */
    onSortButtonClick() {
        const s = this.popover.open({
            content: SortTilesComponent,
            data: null,
            hasBackdrop: true,
            position: 'absolute',
            placement: 'end center'
        }).afterClosed$.subscribe(
            (res) => s.unsubscribe());
    }

    /**
     * On Add Dashboard tiles button clicked.
     * Opens an overlay containing an overview of all available tiles
     */
    onAddTileButtonClicked() {
        const s = this.popover.open({
            content: AddTileComponent,
            data: null,
            hasBackdrop: true,
            placement: 'center center'
        }).afterClosed$.subscribe(
            (res) => s.unsubscribe());
    }


    /// ============================================================================================
    /// GTM STUFF
    /// ============================================================================================
    private trackAfterLoginEvent(labelpartner: string, meterType): void {
        this.gtm.trackEvent({
            event: 'login',
            eventCategory: 'conversion',
            eventAction: 'login',
            journeyId: 'smart control login',
            toolId: 'smart control login',
            elementId: 'smart control login',
            stepId: 'smart control login',
            lablePartner: labelpartner,
            tariffMeterType: meterType
        });
    }
}
