import {Component, OnInit, ViewChild} from '@angular/core';
import {NilmService} from '../../../services/nilm.service';
import {DisaggregationService} from '../../../services/disaggregation.service';
import {MockDataService} from '../../../services/mock-data.service';
import {ApplicationService} from '../../../services/application.service';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';
import {translateAppliance} from '../appliances.utils';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {ApplianceCategories} from '../../../shared/constants/appliances.constants';
import {ConsumptionService} from '../../../services/consumption.service';
import {catchError, map, mergeMap, tap, toArray} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {ApplianceChartComponent} from '../../../charts/appliance-chart/appliance-chart.component';
import {MONTHS} from '../../../lib/DateUtil';
import {ProfileUpdateService} from '../../../services/profile-update.service';


@Component({
    selector: 'app-appliances-details',
    templateUrl: './appliances-detail.component.html',
    styleUrls: ['appliances-detail.component.scss'],
    viewProviders: []
})

export class AppliancesDetailsComponent extends BasePopover implements OnInit {
    @ViewChild('applianceChart', {static: true}) private applianceChart: ApplianceChartComponent;

    private readonly colors = ['#1ea2b1', '#56b9c5', '#616161', '#747475'];
    private months = MONTHS;
    private showAppliances = true;

    translateAppliance = translateAppliance;
    ComponentModes = AppliancesDetailMode;
    defaultApplianceCategories = ApplianceCategories;

    mode: AppliancesDetailMode = AppliancesDetailMode.MONTH;

    currentTimeframe = '';
    offsetMonths = 0;

    applianceCategories: AppliancesDetailListElement[] = [];

    interactionDisabled = true;
    infoVisible = false;
    showProfileUpdateReminder = false;
    profileUpdateReminderVisible = true;


    constructor(protected popoverRef: PopoverRef,
                private analytics: TrackAnalyticsService,
                private mockDataService: MockDataService,
                private disaggregation: DisaggregationService,
                private nilm: NilmService,
                private application: ApplicationService,
                private consumptionService: ConsumptionService,
                private router: Router,
                private profileUpdate: ProfileUpdateService) {
        super(popoverRef);
    }


    ngOnInit() {
        this.currentTimeframe = this.months[new Date().getMonth()] + ' ' + new Date().getFullYear();
        this.getElectricalAppliances();
        this.profileUpdate.onShowProfileIndicator.subscribe(show => {
            this.showProfileUpdateReminder = show;
        });
        this.profileUpdate.checkProfileUpdateIndicatorDisplayDue(true);
    }


    /**
     * Switch mode between yearly consumption and monthly
     * @param mode
     */
    setMode(mode: AppliancesDetailMode) {
        if (mode === this.mode) {
            return;
        }
        this.mode = mode;
        this.offsetMonths = 0;
        this.resetChart();
        this.triggerModeTrackingEvent();
    }

    /**
     * Step forward
     */
    positionForward() {
        if (this.interactionDisabled) {
            return;
        }
        if (this.offsetMonths > 0) {
            --this.offsetMonths;
            this.resetChart();
        }
    }

    /**
     * Step back
     */
    positionBack() {
        if (this.interactionDisabled) {
            return;
        }
        this.offsetMonths++;
        this.resetChart();
    }


    /**
     * Diagramm zurücksetzen und Daten neu holen
     */
    resetChart() {
        this.updateDate();
        this.applianceChart.clearChart();
        this.interactionDisabled = true;
        this.applianceCategories = [];

        if (this.mode === AppliancesDetailMode.YEAR) {
            this.getElectricalAppliancesYear();
        } else {
            this.getElectricalAppliances();
        }
    }


    /**
     * Hide profile update reminder
     */
    hideReminder(): void {
        this.profileUpdateReminderVisible = false;
        this.profileUpdate.removeProfileUpdateIndicatorFlag();
    }


    /**
     * Route to 'profile' component
     */
    routeToProfile(): void {
        this.close();
        this.router.navigate(['mein-haushalt']);
        this.profileUpdate.removeProfileUpdateIndicatorFlag();
    }

    /**
     * Datum zurücksetzen
     */
    updateDate() {
        switch (this.mode) {
            case AppliancesDetailMode.MONTH: {
                const date = new Date();
                date.setMonth(date.getMonth() - this.offsetMonths, 1);
                this.currentTimeframe = this.months[date.getMonth()] + ' ' + date.getFullYear();
                break;
            }
            case AppliancesDetailMode.YEAR : {
                const date = new Date();
                date.setFullYear(date.getFullYear() - this.offsetMonths);
                this.currentTimeframe = date.getFullYear().toString();
                break;
            }
        }
    }


    /**
     * Toggle an accordion element
     */
    toggleAccordion(element): void {
        element.accordionOpen = !element.accordionOpen;
    }


    /**
     * Determine the icon path for an appliance category
     * @param iconName
     */
    determineApplianceCategoryItemPath(iconName): string {
        return `url(/assets/img/graphics/appliances/${iconName}.svg)`;
    }


    /**
     * Request all appliance data
     */
    private getElectricalAppliances() {
        this.showAppliances = true;
        const s = of(this.application.isDemoMode()).pipe(
            mergeMap(demo => {
                return demo ?
                    this.mockDataService.getElectricalAppliances(this.offsetMonths) :
                    this.disaggregation.getDisaggregationHistoryByOffset(this.offsetMonths);
            }),
            mergeMap((data: any) => {
                return this.alignApplianceData([data]);
            }),
            mergeMap(aligned => {
                const date = moment().subtract(this.offsetMonths, 'months').toDate();
                return this.consumptionService.getConsumptionForMonths(date, date).pipe(
                    map(res => {
                        return {appliances: aligned, consumptions: res};
                    }),
                    catchError(err => {
                        return of({appliances: aligned, consumptions: null});
                    })
                );
            }),
            // tap(data => console.log('data before generating diagram series', data)),
            map(data => {
                return this.generateDiagramSeries(data.appliances, data.consumptions);
            }),
        ).subscribe(
            {
                next: (finalSeries: any) => {
                    this.drawDiagram(finalSeries.series);
                },
                error: () => {
                    this.applianceChart.showErrorState();
                    this.showAppliances = false;
                    this.interactionDisabled = false;
                },
                complete: () => {
                    s.unsubscribe();
                }
            });
    }

    /**
     * Request appliance & category data for an entire year
     */
    private getElectricalAppliancesYear() {
        this.showAppliances = true;
        const months = Array.from(Array(12).keys()).map(el => el += 1);
        const s = of(months).pipe(
            mergeMap(month => month),
            mergeMap(month => {
                if (!this.application.isDemoMode()) {
                    return this.disaggregation.getDisaggregationHistoryForMonth(
                        this.offsetMonths, month
                    ).pipe(
                        catchError(err => of(null))
                    );
                }
                return this.mockDataService.getElectricalAppliancesForMonth(
                    month, this.offsetMonths
                );
            }),
            toArray(),
            mergeMap(rawDisaggregationData =>
                this.alignApplianceData(rawDisaggregationData)),
            mergeMap(disaggregationData =>
                this.requestAppliancesCategoryData(disaggregationData)),
            map(data => {
                return this.generateDiagramSeries(data.appliances, data.consumptions);
            })
        ).subscribe({
            next: (finalSeries) => {
                this.drawDiagram(finalSeries.series);
            },
            error: (error) => {
                this.applianceChart.showErrorState();
                this.showAppliances = false;
                this.interactionDisabled = false;
            },
            complete: () => {
                s.unsubscribe();
            }
        });
    }


    /**
     * Align appliances disaggregation data
     * @param rawData
     */
    private alignApplianceData(rawData: any[]): Observable<any> {
        return new Observable<any>((observer) => {
            try {
                const unifiedData = {};
                for (const monthlyData of rawData) {
                    if (!monthlyData) {
                        continue;
                    }
                    const electricity = this.application.isDemoMode() ?
                        monthlyData.data.electricity :
                        monthlyData.electricity;
                    for (const appliance of Object.keys(electricity.used_budget.categories)) {
                        const usage = electricity.used_budget.categories[appliance].usage ?
                            electricity.used_budget.categories[appliance].usage :
                            0;
                        const cost = electricity.used_budget.categories[appliance].cost ?
                            electricity.used_budget.categories[appliance].cost :
                            0;

                        if (!unifiedData[appliance]) {
                            unifiedData[appliance] = {usage, cost};
                            continue;
                        }
                        unifiedData[appliance].usage += usage;
                        unifiedData[appliance].cost += cost;
                    }
                }
                const mapped = Object.keys(unifiedData).map(element => {
                    return {
                        name: element,
                        cost: unifiedData[element].cost,
                        usage: unifiedData[element].usage
                    };
                });
                mapped.sort((a, b) => b.usage - a.usage);
                observer.next(mapped);
            } catch (error) {
                console.log('Error aligning data:', error);
                observer.error(error);
            }
        });
    }


    /**
     * Generates a diagram series from temporary data
     * @param applianceData
     */
    private generateDiagramSeries(applianceData: any[], categoryData: any = null): any {
        const series = [];
        try {
            for (const applianceCategory of applianceData) {

                const applianceCategoryElement =
                    this.generateInitialApplianceListElement(applianceCategory);

                const currentCategory = this.defaultApplianceCategories[applianceCategory.name];
                let text = 'default';
                if (currentCategory) {
                    text = this.defaultApplianceCategories[applianceCategory.name].text;
                    if (categoryData) {
                        const cat = categoryData.appliances[0].energy_per_appliance;
                        for (const category of currentCategory.appliances) {
                            const found = cat.find(
                                el => el.appliance_instance_id === category.name);
                            if (found) {
                                applianceCategoryElement.appliances.push(found);
                                if (!this.application.isDemoMode()) {
                                    found.energy_ws = Math.round(found.energy_ws / 3600000);
                                    found.cost = found.cost.toFixed(2);
                                } else {
                                    found.energy_ws = Math.round(applianceCategory.usage / 3);
                                    found.cost = (applianceCategory.cost / 3).toFixed(2);
                                }
                                found.icon = category.icon;
                            }
                        }
                    }
                }
                applianceCategoryElement.description = text;

                // only show items with usage value > 0
                if (applianceCategory.usage > 0) {
                    this.applianceCategories.push(applianceCategoryElement);
                    series.push({
                        name: applianceCategory.name,
                        y: Math.round(applianceCategory.usage),
                        x: applianceCategory.cost.toLocaleString(),
                        color: null
                    });
                }
            }

            // assign colors
            let colorCounter = 0;
            for (let i = 0; i < series.length; ++i) {
                let color = this.colors[colorCounter];
                if (!color) {
                    color = this.colors.last();
                }
                series[i].color = color;
                this.applianceCategories[i].color = color;
                ++colorCounter;
            }
        } catch (e) {
            console.log('Error:', e);
        }
        return {series, nilm: []};
    }


    /**
     * Wrapper for creating a base appliance list element
     * @param applianceCategory - raw appliance data from API
     */
    private generateInitialApplianceListElement(applianceCategory: any):
        AppliancesDetailListElement {
        return {
            name: applianceCategory.name,
            kwh: Math.round(applianceCategory.usage).toLocaleString('de-DE'),
            cost: applianceCategory.cost.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR'
            }),
            appliances: [],
            description: 'default',
            accordionOpen: false,
            color: null,
            categoryProfileComplete: this.nilm.nilmCategoryIsComplete(applianceCategory.name)
        };
    }


    // /**
    //  * Determine whether to show an info banner based on the last profile update reminder shown
    //  */
    // private determineLastProfileUpdateReminderNecessary(): void {
    //     const showReminder = localStorage.getItem(
    //         StorageAttributes.SHOW_PROFILE_UPDATE_REMINDER
    //     );
    //     const parsed = showReminder === '1';
    //     if (parsed) {
    //         this.showProfileUpdateReminder = true;
    //         localStorage.removeItem(StorageAttributes.SHOW_PROFILE_UPDATE_REMINDER);
    //     }
    // }


    /**
     * Draw diagram with new data
     * @param series
     */
    private drawDiagram(series: any): void {
        this.interactionDisabled = false;
        this.showAppliances = this.applianceCategories.length !== 0;
        this.applianceChart.addSeries({
            name: 'Series',
            data: series,
            type: 'pie'
        });
    }


    /**
     * Request category data for available appliances
     * @param disaggregationData
     */
    private requestAppliancesCategoryData(disaggregationData): Observable<any> {
        const t = moment().subtract(this.offsetMonths, 'year');
        const dateStart = moment(t).month(0).toDate();
        const dateEnd = moment(t).month(11).toDate();
        return this.consumptionService.getConsumptionForMonths(dateStart, dateEnd).pipe(
            mergeMap(response => {
                const aligned = {};
                for (const monthlyDataset of response.appliances) {
                    const currentElement = monthlyDataset.energy_per_appliance;
                    for (const appliance of currentElement) {
                        const id = appliance.appliance_instance_id;
                        const cost = appliance.cost;
                        const energy_ws = appliance.energy_ws;
                        if (!aligned[id]) {
                            aligned[id] = {cost, energy_ws};
                            continue;
                        }
                        aligned[appliance.appliance_instance_id].cost += cost;
                        aligned[appliance.appliance_instance_id].energy_ws += energy_ws;
                    }
                }
                const mapped = Object.keys(aligned).map(el => {
                        return {
                            appliance_instance_id: el,
                            energy_ws: aligned[el].energy_ws,
                            cost: aligned[el].cost
                        };
                    }
                );
                return of({appliances: [{energy_per_appliance: mapped}]});
            }),
            map(mappedData => {
                return {appliances: disaggregationData, consumptions: mappedData};
            })
        );

    }

    /**
     * Initialize the chart
     */
    // private initializeChart(): void {
    //     const self = this;
    //     this.chart = new Chart({
    //         chart: {
    //             type: 'pie',
    //             backgroundColor: 'rgba(255, 255, 255, 0)',
    //             margin: [0, 0, 25, 0],
    //             events: {
    //                 render(event) {
    //                     if ('series' in event.target) {
    //                         if (event.target['series']['length'] > 0) {
    //                             const points = event.target['series'][0]['points'] as any[];
    //                             const sorted = points.sort((a, b) => {
    //                                 return b.percentage - a.percentage;
    //                             });
    //                             if (!sorted[0]) {
    //                                 return;
    //                             }
    //                             const name = translateAppliance(sorted[0].name);
    //                             const value = Math.floor(sorted[0].percentage).toString();
    //                             self.defaultCallout.color = sorted[0].color;
    //                             self.defaultCallout.image = `url(/assets/img/graphics/appliances/${sorted[0].name.toLowerCase()}.svg)`;
    //                             self.defaultCallout.label = `${name}: ${value}%`;
    //                             self.currentCallout = self.defaultCallout;
    //                         }
    //                     }
    //                 }
    //             }
    //         },
    //         title: {text: null},
    //         tooltip: {
    //             hideDelay: 0,
    //             shadow: false,
    //             positioner(boxWidth: number, boxHeight: number) {
    //                 return {
    //                     x: (this.chart.plotWidth / 2) - (boxWidth / 2),
    //                     y: (this.chart.plotHeight / 2) - (boxHeight / 2)
    //                 };
    //             },
    //             useHTML: true,
    //             formatter() {
    //                 self.currentCallout = self.determineCalloutData(this);
    //                 return '';
    //             },
    //             backgroundColor: 'rgba(255, 255, 255, 0)',
    //             borderWidth: 0
    //         },
    //         plotOptions: {
    //             pie: {
    //                 dataLabels: {
    //                     useHTML: true,
    //                     formatter() {
    //                         return `<div class="label"> ${Math.round(this.percentage)} %</div>`;
    //                     },
    //                     distance: 20,
    //                     padding: 0,
    //                     connectorWidth: 0,
    //                     connectorColor: 'white',
    //                     softConnector: false,
    //                     style: {
    //                         fontSize: '20px',
    //                         fontFamily: 'EONBrixSans, sans-serif',
    //                         color: '#39393a'
    //                     }
    //                 },
    //                 startAngle: -180,
    //                 states: {hover: {brightness: 0,}},
    //                 point: {
    //                     events: {
    //                         mouseOut() {
    //                             self.currentCallout = self.defaultCallout;
    //                         },
    //                     }
    //                 }
    //             }
    //         },
    //         series: [],
    //         credits: {
    //             enabled: false
    //         }
    //     });
    //
    // }

    /*
    * TRACKING
    * =============================================================================================
    */
    private triggerModeTrackingEvent(): void {
        this.analytics.trackEvent({
            action: 'screen_view',
            properties: {
                category: 'Screens',
                label: 'screen: Meine Geräte - ' +
                    (this.mode === AppliancesDetailMode.YEAR ? 'Jahresübersicht' : 'Monatsübersicht') +
                    '; previous_screen: Meine Geräte - ' +
                    (this.mode === AppliancesDetailMode.YEAR ? 'Monatsübersicht' : 'Jahresübersicht')
            }
        });
    }
}

enum AppliancesDetailMode {
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

interface AppliancesDetailListElement {
    name: string;
    kwh: string;
    cost: string;
    appliances: ApplianceDetailListItem[];
    description: string;
    accordionOpen: boolean;
    color: string | null;
    categoryProfileComplete: boolean;
}

interface ApplianceDetailListItem {
    energy_ws: number;
    appliance_instance_id: string;
    cost: string;
    icon: string;
}
