import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {Url} from '../lib/Url';

@Injectable({
    providedIn: 'root'
})
export class UserGroupService extends BaseService {

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
    }

    getUserGroup(): Observable<any> {
        const url = new Url(null);
        url.push(this.API_BASE_URL);
        url.push(constants.api.routes.iona.usergroups.this);

        return this.http.get(
            url.toString(),
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((e) => this.handleError(e))
        );
    }
}

