import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'app-power-switch',
    templateUrl: './power-switch.component.html',
    styleUrls: ['./power-switch.component.scss']
})
export class PowerSwitchComponent implements OnInit, OnChanges {

    @Input() state: boolean = false;
    @Input() style: PowerSwitchStyle = null;
    @Output() stateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('knob', {static: true}) private knob: ElementRef;
    @ViewChild('knobContainer', {static: true}) private knobContainer: ElementRef;
    @ViewChild('indicator', {static: true}) private indicator: ElementRef;

    private mouseDown = false;
    private touchDown = false;
    private elementSize: number = null;
    private elementOrigin: number = null;
    private containerWidth: number = null;
    private containerStart: number = null;
    private containerEnd: number = null;

    private mouseDownStart: number = null;
    private touchDownStart: number = null;

    constructor(private renderer: Renderer2) {
    }

    ngOnInit() {
        this.initialize();
        this.initializeMouseHandlers();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.renderer.removeStyle(this.knob.nativeElement, 'right');
        if (this.state) {
            this.renderer.setStyle(this.knob.nativeElement, 'left', `${this.containerWidth - this.elementSize}px`);
        } else {
            this.renderer.setStyle(this.knob.nativeElement, 'left', '0');
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.initialize();
    }

    /**
     * Initialize
     */
    private initialize(): void {
        if (this.style !== null) {
            this.renderer.setStyle(this.knobContainer.nativeElement, 'width', `${this.style.containerWidth}px`);
            this.renderer.setStyle(this.knobContainer.nativeElement, 'height', `${this.style.elementSize}px`);

            this.renderer.setStyle(this.knob.nativeElement, 'width', `${this.style.elementSize}px`);
            this.renderer.setStyle(this.knob.nativeElement, 'height', `${this.style.elementSize}px`);
            this.renderer.setStyle(this.knob.nativeElement, 'border-radius', `${this.style.elementSize / 2}px`);

            if (this.style.darkTheme) {
                this.renderer.addClass(this.knob.nativeElement, 'dark');
                this.renderer.addClass(this.indicator.nativeElement, 'dark');
            } else {
                this.renderer.addClass(this.knob.nativeElement, 'light');
                this.renderer.addClass(this.indicator.nativeElement, 'light');
            }

        }

        this.elementOrigin = this.knob.nativeElement.getBoundingClientRect().x;
        this.elementSize = this.knob.nativeElement.offsetWidth;

        this.containerWidth = this.knobContainer.nativeElement.offsetWidth;
        this.containerStart = this.elementOrigin + (this.elementSize / 2);
        this.containerEnd = this.elementOrigin + this.containerWidth - (this.elementSize / 2);

        this.renderer.setStyle(this.indicator.nativeElement, 'height', `${0.18 * this.elementSize}px`);
        this.renderer.setStyle(this.indicator.nativeElement, 'top', `calc(50% - ( ${0.18 * this.elementSize}px / 2))`);
        this.renderer.setStyle(this.indicator.nativeElement, 'border-radius', `${(0.18 * this.elementSize) / 2}px`);

        this.renderer.removeStyle(this.knob.nativeElement, 'right');
        if (this.state) {
            this.renderer.setStyle(this.knob.nativeElement, 'left', `${this.containerWidth - this.elementSize}px`);
        } else {
            this.renderer.setStyle(this.knob.nativeElement, 'left', '0');
        }
    }

    /**
     * Initialize Mouse callbacks
     */
    private initializeMouseHandlers(): void {
        /* mousedown */
        this.renderer.listen(this.knob.nativeElement, 'mousedown',
            (event) => {
                this.mouseDown = true;
                this.mouseDownStart = Date.now();
            }
        );

        this.renderer.listen(this.knob.nativeElement, 'touchstart',
            (event) => {
                this.touchDown = true;
                this.touchDownStart = Date.now();
            }
        );

        /* mousemove */
        this.renderer.listen(this.knob.nativeElement, 'mousemove',
            (event) => {
                if (this.mouseDown) {
                    const new_x = this.calcNewPosition(event.x, event.y);
                    this.renderer.removeStyle(this.knob.nativeElement, 'right');
                    this.renderer.setStyle(this.knob.nativeElement, 'left', `${new_x}px`);
                    if (new_x < (this.containerWidth - this.elementSize) / 2) {
                        if (this.state) {
                            this.state = false;
                        }
                    } else {
                        if (!this.state) {
                            this.state = true;
                        }
                    }
                }
            }
        );

        this.renderer.listen(this.knob.nativeElement, 'touchmove',
            (event) => {
                if (this.touchDown) {
                    const new_x = this.calcNewPosition(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
                    this.renderer.removeStyle(this.knob.nativeElement, 'right');
                    this.renderer.setStyle(this.knob.nativeElement, 'left', `${new_x}px`);
                    if (new_x < (this.containerWidth - this.elementSize) / 2) {
                        if (this.state) {
                            this.state = false;
                        }
                    } else {
                        if (!this.state) {
                            this.state = true;
                        }
                    }
                }
            }
        );


        /* mouseup */
        this.renderer.listen(this.knob.nativeElement, 'mouseup',
            (event) => {
                const mouse_down_end = Date.now();

                // click
                if (mouse_down_end - this.mouseDownStart < 200) {
                    this.state = !this.state;
                    this.renderer.removeStyle(this.knob.nativeElement, 'left');
                    if (this.state) {
                        this.renderer.setStyle(this.knob.nativeElement, 'right', '0px');
                    } else {
                        this.renderer.setStyle(this.knob.nativeElement, 'right', `${this.containerWidth - this.elementSize}px`);
                    }
                    this.mouseDown = false;
                    this.stateChange.emit(this.state);
                    return;
                }

                // drag
                const new_x = this.calcNewPosition(event.x, event.y);
                this.renderer.removeStyle(this.knob.nativeElement, 'right');
                if (new_x < (this.containerWidth - this.elementSize) / 2) {
                    this.renderer.setStyle(this.knob.nativeElement, 'left', '0px');
                    this.state = false;
                } else {
                    this.renderer.setStyle(this.knob.nativeElement, 'left', `${this.containerWidth - this.elementSize}px`);
                    this.state = true;
                }
                this.stateChange.emit(this.state);

                this.mouseDown = false;
            }
        );

        this.renderer.listen(this.knob.nativeElement, 'touchend',
            (event) => {
                if (this.touchDown) {
                    const touch_down_end = Date.now();

                    // click
                    if (touch_down_end - this.touchDownStart < 200) {
                        this.state = !this.state;
                        this.renderer.removeStyle(this.knob.nativeElement, 'left');
                        if (this.state) {
                            this.renderer.setStyle(this.knob.nativeElement, 'right', '0px');
                        } else {
                            this.renderer.setStyle(this.knob.nativeElement, 'right', `${this.containerWidth - this.elementSize}px`);
                        }
                        this.touchDown = false;
                        this.stateChange.emit(this.state);
                        return;
                    }

                    // drag
                    const new_x = this.calcNewPosition(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
                    this.renderer.removeStyle(this.knob.nativeElement, 'right');
                    if (new_x < (this.containerWidth - this.elementSize) / 2) {
                        this.renderer.setStyle(this.knob.nativeElement, 'left', '0px');
                        this.state = false;
                    } else {
                        this.renderer.setStyle(this.knob.nativeElement, 'left', `${this.containerWidth - this.elementSize}px`);
                        this.state = true;
                    }
                    this.stateChange.emit(this.state);

                    this.touchDown = false;
                }
            }
        );

        /* mouseout */
        this.renderer.listen(this.knob.nativeElement, 'mouseout',
            (event) => {
                if (this.mouseDown) {
                    const new_x = this.calcNewPosition(event.x, event.y);
                    if (new_x < (this.containerWidth - this.elementSize) / 2) {
                        this.renderer.setStyle(this.knob.nativeElement, 'left', `0`);
                        this.state = false;
                    } else {
                        this.renderer.setStyle(this.knob.nativeElement, 'left', `${this.containerWidth - this.elementSize}px`);
                        this.state = true;
                    }
                    this.stateChange.emit(this.state);
                    this.mouseDown = false;
                }
            }
        );

        this.renderer.listen(this.knob.nativeElement, 'touchcancel',
            (event) => {
                if (this.touchDown) {
                    const new_x = this.calcNewPosition(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
                    if (new_x < (this.containerWidth - this.elementSize) / 2) {
                        this.renderer.setStyle(this.knob.nativeElement, 'left', `0`);
                        this.state = false;
                    } else {
                        this.renderer.setStyle(this.knob.nativeElement, 'left', `${this.containerWidth - this.elementSize}px`);
                        this.state = true;
                    }
                    this.stateChange.emit(this.state);
                    this.touchDown = false;
                }
            }
        );

    }


    /**
     * Calculates the position after the element was dragged.
     * @param x
     * @param y
     */
    private calcNewPosition(x: number, y: number): number {
        const current_local_x = x - this.elementOrigin;
        if (x < this.containerStart) {
            return 0;
        }
        if (x > this.containerEnd) {
            return this.containerWidth - this.elementSize;
        }
        return current_local_x - (this.elementSize / 2);
    }

}

export interface PowerSwitchStyle {
    containerWidth: number;
    elementSize: number;
    darkTheme: boolean;
}
