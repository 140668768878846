import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {Globals} from '../../../services/globals.service';
import {ApiService} from '../../../services/api.service';
import {UserService} from '../../../services/user.service';
import {LiveDataService} from '../../../services/live-data.service';
import {MockDataService} from '../../../services/mock-data.service';
import {ApplicationService} from '../../../services/application.service';
import {BaseComponent} from '../../../classes/base-component';
import {LiveChartComponent} from '../../../charts/live-chart/live-chart.component';
import {ElectricityService} from '../../../services/electricity.service';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {HomestateService} from '../../../services/homestate.service';
import {OpticalReaderService} from '../../../services/optical-reader.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {Subscription, timer} from 'rxjs';
import {PowerValue} from '../../../shared/interfaces/power-value.interface';

@Component({
    selector: 'app-live-tile',
    templateUrl: './live-tile.component.html',
    styleUrls: ['./live-tile.component.scss'],
    providers: [Globals]
})

export class LiveTileComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private readonly type: TILE_TYPE = TILE_TYPE.LIVE;

    private mockDataIntervalSub: Subscription = null;
    private currentDataset: Array<PowerValue> = [];

    currentConsumption = 0;
    valueUnit = 'Watt';
    status = {trend: 0, noZone: true};

    isNotRealtime = false;

    @ViewChild('liveChart', {static: true}) liveChart: LiveChartComponent;

    constructor(private _apiService: ApiService,
                private _globals: Globals,
                private _mockService: MockDataService,
                private _userService: UserService,
                private liveData: LiveDataService,
                private application: ApplicationService,
                private analytics: TrackAnalyticsService,
                private electricity: ElectricityService,
                private tiles: TileService,
                private homeState: HomestateService,
                private opticalReader: OpticalReaderService) {
        super();
    }

    ngOnInit() {
        this.valueUnit = 'Watt';
        this.liveChart.setUnit(this.valueUnit);
        this.initializeComponent();
    }

    ngAfterViewInit() {
        this.liveChart.reset();
        // this.liveChart.updateZoomLevel(60 * 60 * 2 * 1000, '%H:%M');
        this.liveChart.updateZoomLevel(60 * 1000, '%H:%M');
    }

    ngOnDestroy() {
        if (this.mockDataIntervalSub) {
            this.mockDataIntervalSub.unsubscribe();
            this.mockDataIntervalSub = null;
        }

        super.ngOnDestroy();
    }


    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }


    detailEntered() {
        if (!(this._globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this._globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


    currentConsumptionFormatted(): string {
        return Math.abs(this.currentConsumption).toLocaleString('de-DE');
    }


    private initializeComponent(): void {
        // initialize chart for demo mode
        if (this.application.isDemoMode()) {
            this.initializeMockData();
            return;
        }

        this.initPowerApiConnection();
        this.initHomeStateStatus();
    }


    private initPowerApiConnection(): void {
        this.liveData.startCurrentConsumptionUpdate(true);
        const liveS = this.liveData.onLiveConsumptionReceived.subscribe(
            (res) => {
                try {
                    if (!res) {
                        return;
                    }
                    const data = res.results as Array<PowerValue>;
                    if (data.length === 0) {
                        this.liveChart.showLoadingState(true);
                        return;
                    }

                    // filter data by last 5 minutes
                    const filtered = this.filterValuesForTimeframe(data);

                    // update chart
                    this.updateChartWithData(filtered);

                    // find last value
                    const fiveMinCopy = filtered.slice().reverse();
                    let latest = fiveMinCopy.find((el) => 'power' in el);
                    if (latest) {
                        this.currentConsumption = latest.power;
                    } else {
                        const fullHourCopy = data.slice().reverse();
                        latest = fullHourCopy.find((el) => 'power' in el);
                        this.currentConsumption = latest.power;
                    }
                    const currentConsumptionFixed = this.currentConsumption.toFixed(0);
                    this.currentConsumption = parseInt(currentConsumptionFixed, 10);

                } catch (e) {
                    console.log('Error: Initializing power based API connection.', e);
                }
            }
        );
        this.addSub(liveS);
    }


    private initHomeStateStatus(): void {
        this.homeState.startLiveUpdate();
        const homeS = this.homeState.onHomestateInfo.subscribe(
            (res) => {
                if (res) {
                    if (res.status.current_zone === 4) {
                        this.status.noZone = true;
                        return;
                    }
                    this.status.noZone = false;
                    this.status.trend = res.status.current_zone;
                }
            });
        this.addSub(homeS);
    }


    private updateChartWithData(dataset: Array<PowerValue>): void {
        const fedEnergy = [];
        for (const el of dataset) {
            const element = {
                power: el.power < 0 ? Math.abs(el.power) : null,
                timestamp: el.timestamp
            };
            fedEnergy.push(element);
        }

        const consumedEnergy = [];
        for (const el of dataset) {
            const element = {
                power: el.power >= 0 ? el.power : null,
                timestamp: el.timestamp
            };
            consumedEnergy.push(element);
        }

        this.currentDataset = dataset;
        this.liveChart.showLoadingState(false);
        this.liveChart.addNewSeries(
            consumedEnergy,
            'power',
            2,
            {zindex: 1, isTileChart: true, color: '#1ea2b1'}
        );
        this.liveChart.addNewSeries(
            fedEnergy,
            'power',
            0,
            {zindex: 2, isTileChart: true, color: '#39393a'}
        );
    }


    private filterValuesForTimeframe(values: Array<PowerValue>): any {
        const start = moment().subtract(5, 'minutes');
        return values.filter((el) => {
            const ts = moment(el.timestamp);
            return ts >= start && ts <= moment();
        });
    }


    private initializeMockData(): void {
        this.mockDataIntervalSub = timer(0, 10000)
            .subscribe(() => this.getMockConsumption());
    }


    private getMockConsumption(): void {
        const offset = 5;
        const limit = 0;
        const interval = 1;
        const level = 1;

        const s = this._mockService.getLiveData(offset, level, limit, interval)
            .subscribe((response) => {
                try {
                    this.updateChartWithData(response);
                    // determine current consumption value
                    const currentConsumption = response.last().power;
                    const diff = currentConsumption - Math.floor(currentConsumption);
                    if (diff < 0.5) {
                        this.currentConsumption = Math.floor(currentConsumption);
                    } else {
                        this.currentConsumption = Math.ceil(currentConsumption);
                    }
                } catch (e) {
                }
            });
        this.addSub(s);
    }


    /*
     * TRACKING
     * =============================================================================================
     */
    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Live'
            }
        });
    }

    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: Live-Details'
            }
        });
    }
}
