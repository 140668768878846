import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PhaseCheckerService {

    public thresholdChange = new Subject<number>();

    constructor() {
    }
}
