import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Chart} from 'angular-highcharts';

@Component({
    selector: 'app-phase-live-chart',
    templateUrl: './phase-live-chart.component.html',
    styleUrls: ['./phase-live-chart.component.scss']
})
export class PhaseLiveChartComponent implements OnInit, OnChanges {

    @Input() data: any[] = null;
    @Input() advancedColors = false;
    @Input() powerThreshold = 7000;

    chart = null;

    private new_colors = ['#eb4b0a', '#b9280a', '#666666'];
    private currentAlignedData = null;

    constructor() {
    }

    ngOnInit() {
        this.initChart();
        if (this.data) {
            if (this.datasetValid(this.data)) {
                this.updateChartData(this.data);
            } else {
                this.insertNullData();
            }
        } else {
            this.insertNullData();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.data) {
            if (this.chart) {
                if (changes.hasOwnProperty('powerThreshold') && this.chart.ref) {
                    this.chart.ref.redraw();
                    return;
                }
            }

            if (this.datasetValid(this.data)) {
                this.updateChartData(this.data);
            } else {
                this.insertNullData();
            }
        } else {
            this.insertNullData();
        }
    }

    private updateChartData(res: any): any {
        if (this.chart) {
            if (this.chart.ref) {
                while (this.chart.ref.series.length) {
                    this.chart.removeSeries(0);
                }
            }
        }

        const phase1 = res.L1;
        const phase2 = res.L2;
        const phase3 = res.L3;

        const phases = [phase1, phase2, phase3];
        const max_sum = phase1.power_max + phase2.power_max + phase3.power_max;

        this.currentAlignedData = phases.map((value, index) => {
            return {
                value: value.power_max,
                idx: index,
                pct: Math.floor(value.power_max / max_sum * 100)
            };
        });

        const colors = this.determineBoxColors(this.currentAlignedData);

        const series = [
            {name: 'Max', color: colors[0], data: [phase1.power_max - phase1.power_min, 0, 0]},
            {name: 'Min', color: colors[0], data: [phase1.power_min, 0, 0]},
            {name: 'Max', color: colors[1], data: [0, phase2.power_max - phase2.power_min, 0]},
            {name: 'Min', color: colors[1], data: [0, phase2.power_min, 0]},
            {name: 'Max', color: colors[2], data: [0, 0, phase3.power_max - phase3.power_min]},
            {name: 'Min', color: colors[2], data: [0, 0, phase3.power_min]},
        ];

        if (!this.chart) {
            return;
        }

        for (const serie of series) {
            this.chart.addSeries(serie);
        }

        if (!this.chart.ref) {
            return;
        }

        const current_max = this.chart.ref.yAxis[0].max;
        const new_max = current_max + (current_max * 0.1);
        this.chart.ref.update({yAxis: {max: new_max}});
    }

    /**
     *
     * @param phase
     * @param values
     */
    private determineBoxColors(values: Array<{ value: number, idx: number, pct: number }>): any {
        return ['first', 'second', 'third'].map((phase, phase_idx) => {
            const mapped_sorted = values.sort((a, b) => a.pct > b.pct ? -1 : 1);

            const first = mapped_sorted[0];
            const second = mapped_sorted[1];
            const third = mapped_sorted[2];

            const current = mapped_sorted.find((el) => el.idx === phase_idx);

            if (first.pct === third.pct && first.pct === second.pct) {
                if (current === second || current === third) {
                    return this.new_colors[0];
                }
            }

            if (first.pct === second.pct) {
                if (current === first || current === second) {
                    return this.new_colors[0];
                }
            }

            if (second.pct === third.pct) {
                if (current === second || current === third) {
                    return this.new_colors[1];
                }
            }

            return this.new_colors[mapped_sorted.findIndex(el => el.idx === phase_idx)];
        });
    }

    private insertNullData(): void {
        const config = {
            yAxis: {
                tickAmount: 0,
                labels: {
                    enabled: false
                },
                gridLineWidth: 0
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        verticalAlign: 'middle',
                        align: 'center',
                        y: 0,
                        style: {
                            fontFamily: 'Innogy medium',
                            color: '#868686',
                            fontSize: '28px'
                        }
                    }
                }
            }
        };

        if (!this.chart) {
            return;
        }
        if (!this.chart.ref) {
            return;
        }

        this.chart.ref.update(config);
        const fake = [2000, 2200, 2100];
        const series = {
            name: '?',
            color: '#f2f2f2',
            data: fake
        };
        this.chart.addSeries(series);
    }

    /**
     * Configures the charts content displayal and overall style.
     */
    private initChart(): void {
        const self = this;
        let margin_bottom = 50;
        if (this.advancedColors) {
            margin_bottom = 120;
        }
        this.chart = new Chart({
            chart: {
                type: 'column',
                marginRight: 2,
                marginBottom: margin_bottom,
                height: '60%',
                reflow: true,
            },
            title: {
                text: null
            },
            subtitle: {
                text: null
            },
            xAxis: {
                categories: [
                    'Phase 1',
                    'Phase 2',
                    'Phase 3',
                ],
                labels: {
                    useHTML: true,
                    formatter() {
                        const position = this['pos'];
                        if (self.currentAlignedData) {
                            const data_element = self.currentAlignedData.find((el) => el.idx === position);
                            if (self.advancedColors) {
                                const element_over_threshold = data_element.value > self.powerThreshold;
                                const font_class = element_over_threshold ? 'fnt-medium' : 'fnt-regular';
                                const color = self.determineBoxColors(self.currentAlignedData)[position];
                                const inner = `<p style="margin-bottom: 10px">${this.value}</p> ${data_element.pct}% <br> ${data_element.value} W`;
                                return `<div class="${font_class}" style="color: ${element_over_threshold ? color : 'black'}; text-align: center">${inner} <br> </div>`;
                            } else {
                                const max = data_element.pct >= 70;
                                const font_class = max ? 'fnt-medium' : 'fnt-regular';
                                return `<span class="${font_class}" style="color: ${max ? self.new_colors[0] : 'black'}">${this.value}</span>`;
                            }
                        } else {
                            return `<span class="fnt-regular">${this.value}</span>`;
                        }

                    },
                    style: {
                        fontSize: '22px',
                        color: '#333333'
                    }
                },
                tickInterval: 0,
                tickAmount: 0,
                tickColor: '#ffffff',
                lineColor: '#e5e5e5',
                tickWidth: 0,
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    formatter() {
                        return this.value !== 0 ? `${this.value.toLocaleString('de')} W` : '';
                    },
                    style: {
                        fontSize: '16px',
                        fontFamily: 'Innogy regular',
                        color: '#333333',
                        textOutline: null
                    },
                    y: 16,
                    padding: 0,
                    align: 'left'
                },
                tickAmount: 4,
                tickWidth: 1,
                tickColor: '#e5e5e5',
                gridLineColor: '#e5e5e5',
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                column: {
                    dataLabels: {
                        enabled: true,
                        align: 'center',
                        useHTML: true,
                        verticalAlign: 'top',
                        y: -30,
                        allowOverlap: false,
                        formatter() {
                            if (this.y !== 0) {
                                const label = this.series.userOptions.name as string;
                                if (label.toLowerCase() === 'min') {
                                    return `<span style="color: white; z-index: 10"> ${label} </span>`;
                                }
                                return `<span style="z-index: 1000"> ${label} </span>`;
                            }
                        },
                        style: {
                            fontSize: '18px',
                            fontFamily: 'Innogy light',
                            color: '#333333',
                            textOutline: null
                        },
                    },
                    borderRadius: 6,
                },
            },
            series: [
                // {
                //     name: null,
                //     data: []
                // }
            ],
            tooltip: {
                useHTML: true,
                formatter() {
                    let value = this.total.toLocaleString('de');
                    if (this.series.name.toLowerCase() === 'min') {
                        value = this.y.toLocaleString('de');
                    }
                    return `<div style="z-index: 10000;"><div><strong>${this.x}</strong></div> <div>${value} W</div></div>`;
                },
                shadow: false,
                borderRadius: 10,
                style: {
                    fontFamily: 'Innogy regular',
                    fontSize: '16px'
                }
            },
            credits: {
                enabled: false
            },
        });
    }

    private datasetValid(res: any[]): boolean {
        try {
            let sum = 0;
            for (const k of Object.keys(res)) {
                sum += res[k].power_max;
                sum += res[k].power_min;
                sum += res[k].power_avg;
            }
            return sum !== 0;
        } catch (e) {
            console.log('Error:', e);
            return false;
        }
    }
}
