import {Component, OnInit} from '@angular/core';
import {TILE_TYPE, TileDef, TileService} from '../../services/tile.service';
import {PopoverRef} from '../popover/popover-ref';
import {TrackAnalyticsService} from '../../services/track-analytics.service';
import {Options} from 'sortablejs';

@Component({
    selector: 'app-sort-tiles',
    templateUrl: './sort-tiles.component.html',
    styleUrls: ['./sort-tiles.component.scss']
})
export class SortTilesComponent implements OnInit {

    sortableTiles: TileDef[] = [];
    sortOptions: Options = {
        onUpdate: (event: any) => {
            this.updateTileList();
            const tile = this.sortableTiles
                .filter((item: any) => item.id === this.sortableTiles[event.newIndex].id)[0];
            this.trackTileSorting(tile, event);
        }
    };

    constructor(public popoverRef: PopoverRef,
                private tilesService: TileService,
                private analytics: TrackAnalyticsService) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit() {
        this.initTileList();
    }

    close(): void {
        this.popoverRef.close(null);
    }

    onRemoveTileClick(tile: TileDef): void {
        if (tile.type === TILE_TYPE.MVP) {
            this.tilesService.disableMVPTile(tile.mvpConfig.id);
            return;
        }
        this.tilesService.setSelected(false, tile.type);
        this.initTileList();
    }

    private initTileList(): void {
        this.sortableTiles = this.tilesService.getCurrentTiles()
            .filter((it) => it.selected);
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close();
        });
    }

    private updateTileList(): void {
        const missing = [];
        for (const tile of this.tilesService.getCurrentTiles()) {
            const idx = this.sortableTiles.findIndex((it) => it.type === tile.type);
            if (idx === -1) {
                missing.push(tile);
            }
        }
        this.tilesService.updateTileList(this.sortableTiles.concat(missing));
    }

    private trackTileSorting(tile: TileDef, event): void {
        this.analytics.trackEvent({
            action: 'dashboard_layout_change',
            properties: {
                category: 'Dashboard',
                label: 'type: move; card: ' + tile.title + '; position: ' + (event.newIndex + 1) + '; previous_position: ' + (event.oldIndex + 1)
            }
        });
    }
}
