import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {of, Subscription, timer} from 'rxjs';
import {catchError, flatMap} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {VisibilityService} from './visibility.service';
import {constants} from '../shared/constants/constants';

@Injectable({
    providedIn: 'root'
})
export class HeartbeatService extends BaseService {

    private updateRate = 150 * 1000;
    // private updateRate = 150;
    private timerSub: Subscription = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private visibility: VisibilityService,
    ) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
            delete this.timerSub;
        }
    }

    startLiveUpdate(): void {
        if (this.timerSub) {
            return;
        }
        this.timerSub = timer(0, this.updateRate)
            .pipe(
                flatMap((cycle) => {
                    if (this.visibility.isVisible()) {
                        if (this.user.isEDGUser()) {
                            return this.beatHeart();
                        } else {
                            return of(null);
                        }
                    }
                    return of(null);
                }),
            ).subscribe(() => {
                }
            );
    }

    private beatHeart(): Observable<any> {
        // return of(null);
        return this.http.put(
            this.API_BASE_URL + constants.api.routes.smartbridge.reportMode.realTime,
            {},
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            catchError((error) => {
                return of(null);
            })
        );
    }
}
