import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {constants} from '../shared/constants/constants';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';

@Injectable({
    providedIn: 'root'
})
export class DisaggregationService extends BaseService {

    private updateRate = 10000;

    private timerSub: Subscription = null;

    private disaggregationData: any = null;

    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }

    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }

        delete this.timerSub;
    }


    getDisaggregationDataForAppliancesTile(): Observable<any> {
        let data: Observable<any> = null;
        if (this.disaggregationData) {
            data = of(this.disaggregationData);
        } else {
            data = this.getDisaggregationHistoryByOffsetNew(0);
        }
        return data.pipe(
            tap(receivedData => this.disaggregationData = receivedData),
            mergeMap(receivedData => this.mapForAppliancesTile(receivedData))
        );
    }

    getDisaggregationHistoryByOffset(offset: number): Observable<any> {
        const date = new Date();
        date.setMonth(date.getMonth() - offset, 1);

        const method: string = date.getFullYear() + '/' + (date.getMonth() + 1);
        return this.getDisaggregationHistory(method);
    }

    getDisaggregationHistoryForMonth(offset: number, month: number): Observable<any> {
        const date = new Date();
        date.setMonth(date.getMonth() - offset, 1);

        const method: string = date.getFullYear() + '/' + month;
        return this.getDisaggregationHistory(method);
    }

    private getDisaggregationHistoryByOffsetNew(offset: number): Observable<any> {
        return this.getDisaggregationHistoryNew(offset);
    }

    private getDisaggregationHistoryNew(offset: number, month = 0): Observable<any> {
        const date = new Date();
        date.setMonth(date.getMonth() - offset, 1);

        let method = date.getFullYear() + '/' + (date.getMonth() + 1);
        if (month > 0) {
            method = date.getFullYear() + '/' + month;
        }

        let url = this.API_BASE_URL + constants.api.routes.disaggregation.history + '/' + method;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.disaggregationHistory}.json`;
        }

        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            map((disaggregationData: any[]) => {
                if (this.application.isDemoMode()) {
                    if (month > 0) {
                        console.log('Not implemented yet');
                    } else {
                        return disaggregationData.last();
                    }
                } else {
                    return disaggregationData;
                }
            }),
            catchError((error: any) => this.handleError(error))
        );
    }

    private getDisaggregationHistory(method: string, mockData = -1): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.disaggregation.history + '/' + method;
        if (mockData === 0) {
            console.log('here');
            url = `assets/data/demo/${constants.demo.files.disaggregationHistory}.json`;
        }
        return this.http.get(
            url,
            {headers: this.getDefaultHeaders(this.auth.getToken())}
        ).pipe(
            map((res: { status: string, data: any }) => {
                return this.mapDefault(res);
            }),
            catchError((error: any) => this.handleError(error))
        );
    }

    private mapForAppliancesTile(response: any): Observable<any> {
        try {
            return of(response.electricity.used_budget.categories);
        } catch (e) {
            return throwError(e);
        }
    }
}
