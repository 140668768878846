export const ApplianceCategories = {
    Other: {
        text: 'Alle Geräte, die nicht Teil der anderen Kategorien sind, werden hier ' +
            'zusammengefasst. Für den Anteil dieser Kategorie spielen die eingegebenen Profil- ' +
            'und Geräteinformationen eine große Rolle, damit möglichst viele Verbraucher anderen ' +
            'Bereichen zugeordnet werden können. Der Algorithmus sollte in der Lage sein den ' +
            'Anteil auf 20 – 30% zu minimieren.',
        appliances: [],
    },
    AlwaysOn: {
        text: 'Die Grundlast entsteht durch Verbraucher, die sich im Standby-Betrieb befinden ' +
            'oder immer eingeschaltet sind. Auch wenn der Verbrauch einzelner Geräte ' +
            'vernachlässigbar erscheint, so kann die Summe durchaus 25 – 30% des täglichen ' +
            'Verbrauchs ausmachen, da diese jeden Tag 24h Strom benötigen.',
        appliances: [],
    },
    Refrigeration: {
        text: 'Unter “Kühlung” werden Kühlschränke, Gefriertruhen und Kombigeräte erfasst. Da ' +
            'diese Geräte den ganzen Tag laufen, können sie für einen nicht unerheblichen Anteil' +
            ' des Stromverbrauchs verantwortlich sein. Es ist durchaus üblich, mehrere dieser' +
            ' Geräte in einem Haushalt zu betreiben.',
        appliances: [],
    },
    Cooking: {
        text: 'Backöfen und Herde werden sehr häufig elektrisch betrieben. Neben diesen großen ' +
            'Verbrauchern werden in dieser Kategorie u.a. auch Toaster, Kaffeemaschinen und ' +
            'Mikrowellengeräte erfasst.',
        appliances: []
    },
    Entertainment: {
        text: 'Diese Kategorie fasst eine Vielzahl von meist nicht sehr energieintensiven ' +
            'Geräten zusammen, die zur Unterhaltung oder Bildung dienen. Hierzu zählen u.a. TVs, ' +
            'Spielekonsolen und Heimkinosysteme, aber auch Computer, Laptops, Tablets und ' +
            'Mobilgeräte.',
        appliances: []
    },
    Lighting: {
        text: 'Hierunter werden alle Verbraucher erfasst, die für die Beleuchtung verantwortlich ' +
            'sind. Der Verbrauch fällt hauptsächlich in den Abend- und Nachtstunden an und ' +
            'sollte in Privathaushalten einen eher geringen Anteil ausmachen.',
        appliances: []
    },
    Laundry: {
        text: 'In dieser Kategorie wird der Verbrauch von Waschmaschinen, Trocknern und ' +
            'Spülmaschinen erfasst. Der Verbrauch hängt davon ab, wie viele Personen in einem ' +
            'Haushalt leben und wie oft die einzelnen Geräte verwendet werden.',
        appliances: [
            {name: 'DishWasher', icon: 'dish-washer'},
            {name: 'WashingMachine', icon: 'washing-machine'},
            {name: 'Dryer', icon: 'dryer'},
        ]
    },
    WaterHeating: {
        text: 'Elektrische Warmwassererzeugung fällt hauptsächlich dann an, wenn keine Gasheizung' +
            ' verwendet wird. Hierzu zählen sowohl Durchlauferhitzer als auch Boiler. Diese ' +
            'können einen erheblichen Anteil am Gesamtverbrauch eines Haushaltes ausmachen.',
        appliances: []
    },
    SpaceHeating: {
        text: 'Das Heizen mit elektrischer Energie kann einer der größten Verbraucher innerhalb ' +
            'eines Haushalts sein. Da Wärmepumpen immer effizienter werden, nimmt der Anteil der' +
            ' Haushalte mit elektrischen Heizungen stetig zu.',
        appliances: []
    },
    ElectricVehicle: {
        text: 'Elektrofahrzeuge werden immer verbreiteter und können zu einem erheblichen Anteil' +
            ' des Stromverbrauchs eines Haushaltes führen: Die Kategorie kann 40 – 50% ausmachen.',
        appliances: []
    },
    PoolOrSauna: {
        text: 'Sollte eine Sauna oder ein Swimming Pool in einem Haushalt vorhanden sein, kann ' +
            'der Verbrauch dieser Kategorie durchaus 20 – 30% des Energieverbrauchs ausmachen.',
        appliances: []
    },
};

