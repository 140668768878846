import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Subject} from 'rxjs';
import {DOCUMENT} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class VisibilityService {
    public onVisible = new Subject();
    public onHidden = new Subject();

    private _renderer: Renderer2;
    private _onVisibilityCb: () => void = null;

    private _visible = false;
    private _initialized = false;

    constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document) {
        this._renderer = rendererFactory.createRenderer(null, null);
        this._visible = !document.hidden;
    }

    kill(): void {
        if (this._initialized) {
            this._onVisibilityCb();
            this._initialized = false;
        }
    }

    init(): void {
        if (!this._initialized) {
            this._onVisibilityCb = this._renderer.listen(
                'document',
                'visibilitychange',
                () => {
                    if (!document.hidden) {
                        this.onVisible.next();
                        this._visible = true;
                    } else {

                        this.onHidden.next();
                        this._visible = false;
                    }
                });
            this._initialized = true;
        }
    }

    isVisible(): boolean {
        return this._visible;
    }
}
