import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';

import {ApiService} from '../../../services/api.service';
import {Chart} from 'angular-highcharts';
import {Globals} from '../../../services/globals.service';
import {MockDataService} from '../../../services/mock-data.service';
import {UserService} from '../../../services/user.service';
import {getMonthName} from '../../../lib/DateUtil';
import {ElectricityService} from '../../../services/electricity.service';
import {ApplicationService} from '../../../services/application.service';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import * as moment from 'moment';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';

@Component({
    selector: 'app-comparison-tile',
    templateUrl: './comparison-tile.component.html',
    styleUrls: ['./comparison-tile.component.scss'],
    providers: [Globals]
})

export class CompareTileComponent implements OnInit, AfterViewInit {
    private readonly type: TILE_TYPE = TILE_TYPE.COMPARISON;
    chart: any = {};

    trend: any = {
        monthname: null,
        direction: 0,
        percent: 0
    };

    formerValues = {
        name: moment().subtract(1, 'month').format('01.MM. - DD.MM.YYYY'),
        y: 100,
        color: '#e8e8e8',
        empty: true
    };

    currentValues = {
        name: moment().locale('de-DE').format('MMMM'),
        y: 100,
        color: '#e8e8e8',
        empty: true
    };

    showDiagrams = true;
    diagramInitialized = false;
    originalChartContainerHeight = 0;

    @ViewChild('p', {static: true}) chartContainer: ElementRef;

    constructor(private _globals: Globals,
                private _mockData: MockDataService,
                private _userService: UserService,
                private electricityService: ElectricityService,
                private application: ApplicationService,
                private tiles: TileService,
                private renderer: Renderer2,
                private analytics: TrackAnalyticsService) {
    }

    ngOnInit() {
        this.renderer.listen('window', 'resize', (event) => {
            if (this.chart.ref) {
                this.chart.ref.setSize(null, this.chartContainer.nativeElement.clientHeight);
            }
        });
    }

    ngAfterViewInit() {
        this.originalChartContainerHeight = this.chartContainer.nativeElement.clientHeight;
        this.initializeChart();
    }

    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }

    onTileRemoveClicked(): void {
        this.tiles.setSelected(false, this.type, true);
    }

    onDiagramLoaded(chart): void {
        this.diagramInitialized = true;

        if (this.application.isDemoMode()) {
            this.getMockComparison();
            return;
        }
        this.getComparison();
    }


    /**
     * Chart Daten von API holen
     * *jimface*
     */
    getComparison() {
        const currentDate = new Date();

        const lastMonth = currentDate.getMonth() == 0 ? 11 : currentDate.getMonth() - 1;
        const lastYear = currentDate.getMonth() == 0 ? (currentDate.getFullYear() - 1) : currentDate.getFullYear();

        const daysThisMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        const daysLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();

        let consumptionCurrent = 0;
        let consumptionCompare = 0;

        if (currentDate.getDate() == 1) { // Wenn heute der erste Tag im Monat ist
            // Anfrage für vergangenen Monat
            let date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);

            let hour = currentDate.getHours();
            if (currentDate.getMinutes() >= 30 && currentDate.getHours() < 23) {
                hour = hour + 1;
            }

            // Wir müssen hier nur 2 Anfragen machen jewails Stundenweise für den gleichen Tag im vergangenen Monat und heute
            const s1 = this.electricityService.getConsumptionCustom('hours', date1, date1).subscribe(
                // this._apiService.getConsumptionCustom('hours', date1, date1).subscribe(
                (res: any) => {
                    if (res) {
                        for (const consumption of res) {
                            const date: any = new Date(consumption.timestamp);
                            if (date.getHours() <= hour) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                if ('measured' in consumption) {
                                    consumptionCompare += consumption.measured;
                                }
                            }
                        }
                    }

                    // Anfrage für jetzigen Monat
                    const date3 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
                    const s2 = this.electricityService.getConsumptionCustom('hours', date3, date3).subscribe(
                        // this._apiService.getConsumptionCustom('hours', date3, date3).subscribe(
                        (res: any) => {
                            if (res) {
                                for (const consumption of res) {
                                    const date = new Date(consumption.timestamp);
                                    if (date.getHours() <= currentDate.getHours()) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                        if ('measured' in consumption) {
                                            consumptionCurrent += consumption.measured;
                                        }
                                    }

                                }
                            }

                            this.calculateConsumption(consumptionCurrent, consumptionCompare);
                            s2.unsubscribe();
                        }
                    );
                    s1.unsubscribe();
                }
            );
        } else if (daysThisMonth > daysLastMonth && currentDate.getDate() > daysLastMonth) { //Aktueller Monat hat mehr Tage als vorheriger UND currentDate.day ist größer als Anzahl Tage im Vormonat
            // Anfrage für für vergangenen Monat
            const date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            const date2 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0, 23, 59, 59);

            // Wir müssen hier einmal Tageweise den kompletten letzten Monat anfragen, dann Tageweise bis gestern, und vom heutigen Tag noch Stundenweise
            const s1 = this.electricityService.getConsumptionCustom('days', date1, date2).subscribe(
                // this._apiService.getConsumptionCustom('days', date1, date2).subscribe(
                (res: any) => {
                    if (res) {
                        for (const consumption of res) {
                            if ('measured' in consumption) {
                                consumptionCompare += consumption.measured;
                            }
                        }
                    }

                    // Anfragen für heutigen Monat
                    const date3 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                    const date4 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 23, 59, 59);
                    const s2 = this.electricityService.getConsumptionCustom('days', date3, date4).subscribe(
                        // this._apiService.getConsumptionCustom('days', date3, date4).subscribe(
                        (res2: any) => {
                            if (res2) {
                                for (let consumption of res2) {
                                    if ('measured' in consumption) {
                                        consumptionCurrent += consumption.measured;
                                    }
                                }
                            }

                            // Anfragen für heutigen Tag stündlich
                            const date5 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
                            const s3 = this.electricityService.getConsumptionCustom('hours', date5, date5).subscribe(
                                // this._apiService.getConsumptionCustom('hours', date5, date5).subscribe(
                                (res3: any) => {
                                    if (res3) {
                                        for (const consumption of res3) {
                                            const date = new Date(consumption.timestamp);
                                            if (date.getHours() <= currentDate.getHours()) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                                if ('measured' in consumption) {
                                                    consumptionCurrent += consumption.measured;
                                                }
                                            }

                                        }
                                    }

                                    this.calculateConsumption(consumptionCurrent, consumptionCompare);
                                    s3.unsubscribe();
                                }
                            );
                            s2.unsubscribe();
                        }
                    );
                    s1.unsubscribe();
                }
            );
        } else {
            // Anfragen für vergangen Monat
            let date1 = new Date(lastYear, lastMonth, 1, 0, 0, 0);
            let date2 = new Date(lastYear, lastMonth, currentDate.getDate() - 1, 23, 59, 59);

            // Hier müssen wir den letzten Monat Tageweise anfragen bis gestern vor einem Monat, dann heute vor einem Monat Stundenweise und das gleiche nochmal für diesen Monat
            const s1 = this.electricityService.getConsumptionCustom('days', date1, date2).subscribe( // Anfrage letzter Monat Tageweise
                // this._apiService.getConsumptionCustom('days', date1, date2).subscribe( // Anfrage letzter Monat Tageweise
                (res: any) => {
                    if (res) {
                        for (const consumption of res) {
                            if ('measured' in consumption) {
                                consumptionCompare += consumption.measured;
                            }
                        }
                    }

                    let date3 = new Date(lastYear, lastMonth, currentDate.getDate(), 0, 0, 0);

                    let hour = currentDate.getHours();
                    if (currentDate.getMinutes() >= 30 && currentDate.getHours() < 23) {
                        hour = hour + 1;
                    }

                    const s2 = this.electricityService.getConsumptionCustom('hours', date3, date3).subscribe( // Anfrage gleicher Tag, letzter Monat, stundenweise
                        // this._apiService.getConsumptionCustom('hours', date3, date3).subscribe( // Anfrage gleicher Tag, letzter Monat, stundenweise
                        (res2: any) => {
                            if (res2) {
                                for (const consumption of res2) {
                                    const date = new Date(consumption.timestamp);
                                    if (date.getHours() <= hour) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                        if ('measured' in consumption) {
                                            consumptionCompare += consumption.measured;
                                        }
                                    }

                                }
                            }

                            // Anfragen für heutigen Monat
                            let date5 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                            let date6 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1, 23, 59, 59);

                            const s3 = this.electricityService.getConsumptionCustom('days', date5, date6).subscribe(
                                // this._apiService.getConsumptionCustom('days', date5, date6).subscribe(
                                (res3: any) => {
                                    if (res3) {
                                        for (const consumption of res3) {
                                            if (('measured' in consumption)) {
                                                consumptionCurrent += consumption.measured;
                                            }
                                        }
                                    }

                                    // Anfragen für heutigen Tag stündlich
                                    let date7 = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
                                    const s4 = this.electricityService.getConsumptionCustom('hours', date7, date7).subscribe(
                                        // this._apiService.getConsumptionCustom('hours', date7, date7).subscribe(
                                        (res4: any) => {
                                            if (res4) {
                                                for (const consumption of res4) {
                                                    const date = new Date(consumption.timestamp);
                                                    if (date.getHours() <= currentDate.getHours()) {  // Prüfen, ob Zeit der Verbrauchsangabe vor aktueller ist
                                                        if ('measured' in consumption) {
                                                            consumptionCurrent += consumption.measured;
                                                        }
                                                    }

                                                }
                                            }

                                            this.calculateConsumption(consumptionCurrent, consumptionCompare);
                                            s4.unsubscribe();
                                        }
                                    );

                                    s3.unsubscribe();
                                }
                            );
                            s2.unsubscribe();
                        }
                    );
                    s1.unsubscribe();
                }
            );
        }
    }

    getMockComparison(): void {
        const s = this._mockData.getMonthlyComparison().subscribe(
            (data) => {
                this.calculateConsumption(data.data[0].measured, data.data[1].measured);
                s.unsubscribe();
            }
        );
    }


    /**
     * Verbrauch berechnen
     *
     * @param consumptionCurrent
     * @param consumptionCompare
     */
    calculateConsumption(consumptionCurrent, consumptionCompare) {
        this.showDiagrams = consumptionCurrent > 0 || consumptionCompare > 0;

        const former = this.formerValues;
        former.y = Math.round(consumptionCompare / 1000);

        const current = this.currentValues;
        current.y = Math.round(consumptionCurrent / 1000);

        if (former.y === 0 && current.y > 0) {
            former.y = current.y;
            former.color = '#F1ED79';
            current.color = '#E2DC26';
            former.empty = true;
            current.empty = false;
        } else if (current.y === 0 && former.y > 0) {
            current.y = current.y;
            current.color = '#F1ED79';
            former.color = '#E2DC26';
            current.empty = true;
            former.empty = false;
        } else if (current.y > 0 && former.y > 0) {
            former.color = '#F1ED79';
            current.color = '#E2DC26';
            current.empty = false;
            former.empty = false;
        } else if (current.y === 0 && former.y === 0) {
            former.y = 100;
            current.y = 100;
        }

        this.formerValues = former;
        this.currentValues = current;
        this.chart.removeSeries(0);
        this.chart.removeSeries(1);
        this.chart.addSeries({
            name: 'current',
            data: [
                {
                    name: 'former',
                    x: 0,
                    y: this.formerValues.y,
                    color: this.formerValues.color
                },
                {
                    name: 'current',
                    x: 1,
                    y: this.currentValues.y,
                    color: this.currentValues.color
                }
            ]
        });

        if (consumptionCurrent > consumptionCompare) {
            this.trend = {
                monthname: getMonthName(1),
                direction: 1,
                percent: Math.round(((consumptionCurrent - consumptionCompare) / consumptionCompare) * 100)
            };
        } else if (consumptionCurrent < consumptionCompare) {
            this.trend = {
                monthname: getMonthName(1),
                direction: -1,
                percent: Math.round((1 - (consumptionCurrent / consumptionCompare)) * 100)
            };
        }
    }

    detailEntered() {
        if (!(this._globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this._globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Comparison'
            }
        });
    }

    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: MVP-Details'
            }
        });
    }

    /**
     * Init chart
     */
    private initializeChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 0, 0],
                height: '70%',
                events: {
                    load(evt) {
                        this.setSize(null, self.originalChartContainerHeight, false);
                        self.onDiagramLoaded(this);
                    },
                },

            },
            title: {
                text: null
            },
            xAxis: {
                title: {text: null},
                labels: {enabled: false},
                lineColor: 'transparent',
                tickColor: 'transparent'
            },
            yAxis: {
                title: {text: null},
                labels: {enabled: false},
                gridLineWidth: 0
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                column: {
                    borderRadius: 10,
                    dataLabels: {enabled: false,},
                    states: {hover: {enabled: false}},
                },
                series: {
                    showInLegend: false,
                }
            },
            series: [
                {
                    data: null,
                    type: 'column',
                    name: null
                }
            ],
            credits: {
                enabled: false
            }
        });
    }
}
