export {};
declare global {
    interface Array<T> {
        last(): T;

        first(): T;
    }
}

if (!Array.prototype.last) {
    Array.prototype.last = function <T>(): T {
        return this[this.length - 1];
    };
}

if (!Array.prototype.first) {
    Array.prototype.first = function <T>(): T {
        return this[0];
    };
}
