import * as moment from 'moment';
import {duration} from 'moment';

export const MONTHS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
export const DAYS = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

export const getDayName = (offset: number): string => {
    let date: any = new Date();
    date.setDate(date.getDate() - offset);

    return DAYS[date.getDay()];
};

export const getMonthName = (idx: number): string => {
    let date: any = new Date();
    date.setMonth(date.getMonth() - idx);
    return MONTHS[date.getMonth()];
};

export const getDate = (offset: number): string => {
    let date: any = new Date();
    date.setDate(date.getDate() - offset);

    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
};

export const getWeek = (date): number => {
    const now: any = new Date(date);
    now.setHours(0, 0, 0);
    now.setDate(now.getDate() + 4 - (now.getDay() || 7));

    const start: any = new Date(now.getFullYear(), 0, 1);

    return Math.ceil((((now.valueOf() - start.valueOf()) / 86400000) + 1) / 7);
};

export const dateDifference = (date: Date): string => {
    let diff: any = new Date().getTime() - date.getTime();

    const days: any = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff = diff % (1000 * 60 * 60 * 24);

    const hours: any = Math.floor(diff / (1000 * 60 * 60));
    diff = diff % (1000 * 60 * 60);

    const minutes: any = Math.floor(diff / (1000 * 60));
    diff = diff % (1000 * 60);

    const seconds: any = Math.floor(diff / 1000);

    if (days > 0) {
        if (days === 1) {
            return days + ' Tag';
        } else {
            return days + ' Tagen';
        }
    } else if (hours > 0) {
        if (hours === 1) {
            return hours + ' Stunde';
        } else {
            return hours + ' Stunden';
        }
    } else if (minutes > 0) {
        if (minutes === 1) {
            return minutes + ' Minute';
        } else {
            return minutes + ' Minuten';
        }
    } else if (seconds > 0) {
        if (seconds === 1) {
            return seconds + ' Sekunde';
        } else {
            return seconds + ' Sekunden';
        }
    } else {
        return 'jetzt';
    }
};

export const determineDurationPassed = (since: any): string => {
    const date = moment(new Date(since));
    const dur = duration(moment().diff(date));
    let s = '';
    const secs = dur.asSeconds();
    if (secs < 60) {
        s = `seit gerade eben`;
    } else if (secs >= 60 && secs < 3600) {
        s = `seit ${dur.minutes()} ${secs === 60 ? 'Minute' : 'Minuten'}`;
    } else if (secs >= 3600 && secs < 86400) {
        s = `seit ${dur.hours()} ${secs === 60 ? 'Stunde' : 'Stunden'}`;
    } else {
        s = `seit ${date.format('DD.MM.YYYY')}`;
    }
    return s;
};

