import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
    /**
     * Wird benutzt um heraus zu finden, welcher Detail-Screen nach Login als erstes geöffnet wird (Für Google Analytics)
     */
    private keyFirstDetails: string = 'isFirstDetailsView';

    /**
     * Hier wird hinterlegt, ob wir uns im Onboarding-Prozess befinden. Wenn ja, wird ein bestimmtes Googla Analytics Event getriggert
     */
    private keyIsOnboarding: string = 'isOnboardingProcess';

    /**
     * Wird beim Login auf "Passwort vergessen" geklickt, wird die eingetragene E-Mail zwischen gespeichert, um sie im Passwort-Vergessen-Screen direkt vor-auszufüllen
     */
    private keyForgotMail: string = 'pwForgotMail';

    /**
     * Zwischenspeichern der eingegebenen ionaMacID, damit diese, wenn der Registrierungsprozess nochmal durchlaufen werden muss, nicht nochmal eingegeben werden muss
     */
    private keyIonaMacId: string = 'ionaMacId';

    /**
     * Wird gesetzt, sobald das SmartMeter vollständig connected ist
     */
    private keyIsMeterConnected: string = 'isMeterConnected';

    constructor() {
        localStorage.setItem(this.keyFirstDetails, '0');
    }

    setFirstDetailsViews() {
        localStorage.setItem(this.keyFirstDetails, '1');
    }

    getFirstDetailsViewed() {
        return (localStorage.getItem(this.keyFirstDetails) == '1');
    }

    setIsOnboarding() {
        localStorage.setItem(this.keyIsOnboarding, '1');
    }

    resetIsOnboarding() {
        localStorage.removeItem(this.keyIsOnboarding);
    }

    getIsOnboarding() {
        return (localStorage.getItem(this.keyIsOnboarding) == '1');
    }

    setForgotMail(email: string) {
        localStorage.setItem(this.keyForgotMail, email);
    }

    resetForgotMail() {
        localStorage.removeItem(this.keyForgotMail);
    }

    getForgotMail() {
        return localStorage.getItem(this.keyForgotMail);
    }

    setIonaMacId(mac: string) {
        localStorage.setItem(this.keyIonaMacId, mac);
    }

    resetIonaMacId() {
        localStorage.removeItem(this.keyIonaMacId);
    }

    getIonaMacId() {
        return localStorage.getItem(this.keyIonaMacId);
    }

    setIsMeterConnected() {
        localStorage.setItem(this.keyIsMeterConnected, '1');
    }

    resetIsMeterConnected() {
        localStorage.removeItem(this.keyIsMeterConnected);
    }

    getIsMeterConnected() {
        return (localStorage.getItem(this.keyIsMeterConnected) == '1');
    }
}
