import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {PopoverRef} from '../popover/popover-ref';

@Component({
    selector: 'app-value-entry-popover',
    templateUrl: './value-entry-popover.component.html',
    styleUrls: ['./value-entry-popover.component.scss']
})
export class ValueEntryPopoverComponent implements OnInit, AfterViewChecked {
    title = '';
    text = '';
    type = 'text';
    placeholder = '';
    value = null;
    positive = '';
    negative = '';
    turquoise = false;
    hasSkip = false;

    valueValidation = false;
    currentValue = null;
    inputValid = true;

    lengthValidation = false;
    maxlength = null;

    //
    // @ViewChild('valueInput', {static: true})
    // private valueFieldElement: ElementRef<HTMLElement> | undefined;
    //
    // private valueChangeObserver = new MutationObserver((mutations) => {
    //     mutations.forEach((mutation) => {
    //         const value = mutation.target['value'];
    //         if (this.valueValidation) {
    //             if (mutation.type === 'attributes') {
    //                 this.validateValue(value);
    //             }
    //         } else if (this.lengthValidation) {
    //             if (mutation.type === 'attributes') {
    //                 this.validateValue(value);
    //             }
    //         }
    //
    //     });
    // });

    constructor(public popoverRef: PopoverRef) {
        if (this.popoverRef) {
            this.initializePopoverData();
        }
    }

    ngOnInit(): void {
    }

    ngAfterViewChecked(): void {
        // if (this.valueFieldElement) {
        //     this.valueChangeObserver.observe(this.valueFieldElement.nativeElement,
        //         {attributeFilter: ['value']});
        // }
    }

    close(value: boolean | any = false): void {
        this.popoverRef.close(value);
    }

    onInputChanges(event: KeyboardEvent): void {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        if (this.valueValidation) {
            this.validateValue(value);
        }
        if (this.lengthValidation) {
            this.validateLength(value);
        }
    }

    private validateValue(value: string): void {
        if (this.type === 'number') {
            if (value !== '') {
                this.inputValid = !isNaN(+value) && value !== '';
            } else {
                this.inputValid = false;
            }
        } else if (this.type === 'text') {
            this.inputValid = true;
        }
    }

    private validateLength(value: string): void {
        const currentValue = value;
        this.inputValid = currentValue.length >= 1;
    }

    private initializePopoverData() {
        this.popoverRef.overlay.backdropClick().subscribe(() => {
            this.close(null);
        });
        this.title = this.popoverRef.data.title;
        this.text = this.popoverRef.data.text;
        this.placeholder = this.popoverRef.data.placeholder;
        this.positive = this.popoverRef.data.positive;
        this.turquoise = this.popoverRef.data.turquoise;
        this.valueValidation = !!this.popoverRef.data.valueValidation;
        if (this.popoverRef.data.type) {
            this.type = this.popoverRef.data.type;
        }
        if (this.popoverRef.data.negative) {
            this.negative = this.popoverRef.data.negative;
        }
        this.value = this.popoverRef.data.value;
        if (this.popoverRef.data.hasSkip) {
            this.hasSkip = this.popoverRef.data.hasSkip;
        }
        if (this.popoverRef.data.maxlength) {
            this.maxlength = this.popoverRef.data.maxlength;
            this.lengthValidation = true;
            this.inputValid = false;
        }
    }

}
