import {Component, OnDestroy, OnInit} from '@angular/core';
import {MeterService} from '../../../services/meter.service';
import {ApiService} from '../../../services/api.service';
import {Globals} from '../../../services/globals.service';
import {BaseComponent} from '../../../classes/base-component';
import {InstantaneousService} from '../../../services/instantaneous.service';
import * as moment from 'moment';
import {ApplicationService} from '../../../services/application.service';
import {MockDataService} from '../../../services/mock-data.service';
import {UserService} from '../../../services/user.service';
import {PhaseCheckerService} from '../../../services/phase-checker.service';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';

@Component({
    selector: 'app-phase-checker-tile',
    templateUrl: './phase-checker-tile.component.html',
    styleUrls: ['./phase-checker-tile.component.scss'],
    providers: [Globals]
})
export class PhaseCheckerTileComponent extends BaseComponent implements OnInit, OnDestroy {

    connectionAlert = false;
    dataAvailable = false;
    dataError = false;
    phaseWarning = false;

    powerThreshold = 7000;
    currentDataset = null;

    constructor(private apiService: ApiService,
                private globals: Globals,
                private meterService: MeterService,
                private instantaneousService: InstantaneousService,
                private application: ApplicationService,
                private mockData: MockDataService,
                private userService: UserService,
                private phaseCheckerService: PhaseCheckerService,
                private anayltics: TrackAnalyticsService) {
        super();
    }

    ngOnInit() {
        if (!this.application.isDemoMode()) {
            const thresh = this.userService.getPhaseThreshold();
            if (thresh) {
                this.powerThreshold = thresh;
            }
        }

        const s = this.phaseCheckerService.thresholdChange.subscribe(
            (res) => {
                this.phaseWarning = false;
                this.powerThreshold = res;
                this.calculatePhaseStates(this.currentDataset);
            }
        );
        this.addSub(s);

        if (this.application.isDemoMode()) {
            this.initMockTileContent();
            return;
        }

        this.initTileContent();
    }

    ngOnDestroy(): void {
    }

    private initTileContent(): void {
        const meterSub = this.meterService.onMeterStatus.subscribe(
            (res) => {
                if ('lora_mode' in res) {
                    this.connectionAlert = !(res.lora_mode === 0 || res.lora_mode === 7 || res.lora_mode === 8);
                }
            }
        );
        this.addSub(meterSub);

        const now = moment();
        const end = moment(now).toDate();
        const start = moment(now).subtract(30, 'days').toDate();

        const iSub = this.instantaneousService.getPhase(start, end).subscribe(
            (res) => {
                if (res) {
                    this.currentDataset = res;
                    this.calculatePhaseStates(res);
                    this.dataAvailable = true;
                } else {
                    this.dataError = true;
                    this.dataAvailable = true;
                }
            },
            (error) => {
                this.dataError = true;
            }
        );
        this.addSub(iSub);
    }

    private initMockTileContent(): void {
        const mSub = this.mockData.getMeterStatus().subscribe(
            (res_big) => {
                const res = res_big.data;
                this.connectionAlert = !(res.lora_mode === 0 || res.lora_mode === 7 || res.lora_mode === 8);
            }
        );
        this.addSub(mSub);

        const iSub = this.mockData.getPhases().subscribe(
            (res) => {
                this.currentDataset = res;
                this.dataAvailable = true;
            }
        );
        this.addSub(iSub);
    }

    private calculatePhaseStates(res): void {
        const phase1 = res.L1;
        const phase2 = res.L2;
        const phase3 = res.L3;

        const phases = [phase1, phase2, phase3];
        const above_threshold = phases.filter((el) => el.power_max > this.powerThreshold);
        if (above_threshold.length > 0) {
            this.phaseWarning = true;
        }
        const max_sum = phase1.power_max + phase2.power_max + phase3.power_max;

        const mapped = phases
            .map((element) => Math.round(element.power_max / max_sum * 100))
            .sort((a, b) => a > b ? -1 : 1);

        if (mapped[0] >= 70) {
            this.phaseWarning = true;
        }
    }

    detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }

    private trackDetailsEntered(): void {
        this.anayltics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Phase-Checker',
            }
        });
    }

    private trackFirstDetailView(): void {
        // Erstes aufrufen eines Detail Screens
        this.anayltics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: PhasenChecker-Details'
            }
        });
    }

}
