import {Component, OnDestroy, OnInit} from '@angular/core';
import {FirmwareUpdateService} from '../../services/firmware-update.service';
import {PopoverRef} from '../popover/popover-ref';
import {Subscription} from 'rxjs';
import {ApiService} from '../../services/api.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-update-popover',
    templateUrl: './update-popover.component.html',
    styleUrls: ['./update-popover.component.scss']
})
export class UpdatePopoverComponent implements OnInit, OnDestroy {

    readonly env = environment;
    private updateSub: Subscription = null;

    currentProgress = 0;
    animationConfig = {
        path: 'assets/anim/esc.json',
        autoplay: true,
        name: 'ESC Ladeanimation',
        loop: true,
        renderer: 'canvas'
    };

    constructor(public popoverRef: PopoverRef,
                private updateService: FirmwareUpdateService,
                private apiService: ApiService) {
    }

    ngOnInit() {
        this.updateSub = this.updateService.onUpdateStateReceived.subscribe((value) => {
            this.currentProgress = value;
            if (this.currentProgress === null) {
                this.destroyUpdateSubscription();
                this.close();
            }
        });
    }

    ngOnDestroy() {
        this.destroyUpdateSubscription();
    }

    onLogoutButtonClick(): void {
        this.destroyUpdateSubscription();
        this.close();
        this.apiService.logoutUser();
    }

    private destroyUpdateSubscription(): void {
        if (this.updateSub) {
            this.updateSub.unsubscribe();
            this.updateSub = null;
        }
    }

    private close(): void {
        this.popoverRef.close(null);
    }
}
