import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-date-picker-button',
    templateUrl: './date-picker-button.component.html',
    styleUrls: ['./date-picker-button.component.scss']
})
export class DatePickerButtonComponent implements OnInit {

    private datePickerDateFormat = 'MM.DD.YYYY';
    private displayDateFormat = 'DD.MM.YYYY';

    @Input() displaySelectedDate = false;
    @Input() currentDate = new Date();

    @Output() dateChanged = new EventEmitter<Date>();

    @ViewChild('label', {static: true}) labelElement: ElementRef<HTMLLabelElement>;
    @ViewChild('dateSelect', {static: true}) inputElement: ElementRef<any>;

    maxDate: string = moment().format(this.datePickerDateFormat);

    constructor() {
    }

    ngOnInit() {

    }

    onDateChange(event): void {
        const d = new Date(event);
        this.dateChanged.emit(d);
    }

    onButtonClicked(): void {
        this.inputElement.nativeElement.showPicker();
    }

    getCurrentDateFormatted(): string {
        return moment(this.currentDate).format(this.displayDateFormat)
    }

    formatDateForInput(): string {
        return moment(this.currentDate).format(this.datePickerDateFormat);
    }
}
