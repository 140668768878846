import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {OverlayService} from './overlay.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit, OnDestroy {

    @Input('position') position: string;
    @Input('style') style: any;

    public config: NilmOverlayConfig = null;

    private show_subscription: Subscription = null;
    private init_subscription: Subscription = null;
    private hide_subscription: Subscription = null;

    public amount: number = 0;
    private visible: boolean = false;

    constructor(private element: ElementRef,
                private renderer: Renderer2,
                private service: OverlayService) {
    }

    /**
     * On init lifecycle hook
     */
    ngOnInit() {
        this.renderer.setStyle(this.element.nativeElement, 'position', 'fixed');
        this.renderer.setStyle(this.element.nativeElement, 'width', '100%');
        this.renderer.setStyle(this.element.nativeElement, 'height', '100%');
        this.renderer.setStyle(this.element.nativeElement, 'z-index', 10000);
        this.renderer.setStyle(this.element.nativeElement, 'top', 0);
        this.renderer.setStyle(this.element.nativeElement, 'left', 0);

        this.renderer.setStyle(this.element.nativeElement, 'visibility', 'hidden');

        this.init_subscription = this.service.onInit.subscribe(
            (config: NilmOverlayConfig) => {
                this.config = config;
            }
        );

        this.show_subscription = this.service.onShow.subscribe(
            (message: boolean) => {
                if (message) {
                    this.show();
                } else {
                    this.hide();
                }
            }
        );

        this.hide_subscription = this.service.closeOverlaySub.subscribe(
            () => {
                this.hide();
            }
        );
    }

    /**
     * On destroy lifecycle hook
     */
    ngOnDestroy(): void {
        if (this.show_subscription !== null && this.show_subscription !== undefined) {
            this.show_subscription.unsubscribe();
        }
    }

    @HostListener('document:keydown.escape', ['$event'])
    public onKeydownHandler(event: KeyboardEvent) {
        if (this.visible) {
            this.hide();
        }
    }

    public show(): void {
        this.renderer.setStyle(this.element.nativeElement, 'visibility', 'visible');
        this.renderer.setStyle(document.body, 'overflow', 'hidden');
        this.visible = true;
    }

    public hide(): void {
        this.renderer.setStyle(this.element.nativeElement, 'visibility', 'hidden');
        this.renderer.setStyle(document.body, 'overflow', 'scroll');
        this.visible = false;
    }

    public confirm(): void {
        const result: NilmOverlayResult = {
            amount: this.config.amount,
            tag: this.config.tag,
            id: this.config.icon
        };
        this.service.onConfirm.next(result);
        this.hide();
    }

    public raiseAmount(): void {
        this.config.amount++;
    }

    public lowerAmount(): void {
        if (this.config.amount > 0) {
            this.config.amount--;
        }
    }

    public outterClickHandler(event): void {
        if (event.target.classList.contains('container')) {
            this.hide();
        }
    }

}

export interface NilmOverlayConfig {
    title: string;
    tag: string;
    info: string;
    icon: string;
    amount: number;
    appliance: string;
}

export interface NilmOverlayResult {
    tag: string;
    id: string;
    amount: number;

}

